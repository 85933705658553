<template>
  <div class="w-full">
    <!-- Filter Component -->

    <base-card v-if="showFilterPane">
      <AllTasksFilters
        @change-all-tasks-filters="setAllTasksFilter"
      ></AllTasksFilters>
    </base-card>

    <!-- Header Component -->

    <base-card>
      <base-pagination-header
        @load-data="loadAllTasks(fetchFilter.value, true)"
        @get-next="getNext"
        @go-to-first="goToFirst"
        @get-prev="getPrev"
        @go-to-last="goToLast"
        @jump-to-page="jumpToPage"
        @set-page-size-filter="setPageSizeFilter"
        :page_filter="allTasksFilters"
        :page="page"
        :numPages="num_pages"
        :isLoading="isLoading"
      >
        <template v-slot:message>
          <div class="pl-3 pt-1">
            {{ viewingFromNbr }}-{{ viewingToNbr }} | {{ count }} Total Tasks
          </div>
        </template>
      </base-pagination-header>

      <!-- Table Component -->

      <div class="h-full overflow-y-auto border">
        <base-table-full class="table-auto">
          <template #table-header>
            <base-table-header
              :key="header.id"
              :header="header"
              class="pt-3 pb-3"
              v-for="header in tableHeadersList"
            >
              {{ header }}
            </base-table-header>
          </template>

          <template #table-body>
            <AllTasksRow
              v-for="task in all_tasks"
              :key="task.id"
              :task="task"
            ></AllTasksRow>
          </template>
        </base-table-full>
      </div>
    </base-card>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { getAllTasks } from "../services/AllTasks";

import AllTasksRow from "./all_tasks_page/table/AllTasksRow.vue";
import AllTasksFilters from "./all_tasks_page/AllTasksFilters.vue";

const store = useStore();

const isLoading = ref(false);
const next = ref();
const prev = ref();
const count = ref();
const page = ref(1);
const num_pages = ref();
const page_size = ref();
const all_tasks = ref([]);
const allTasksFilters = reactive({
  taskTitleFilter: [],
  statusFilter: [],
  taskTypeFilter: [],
  sourceFilter: [],
  pageSizeFilter: 50,
});

const tableHeadersList = ref([
  "ID",
  "Task Type",
  "Task Title",
  "Company",
  "Source",
  "Assigned User",
  "Status",
  "Completed At/By",
  "Cancelled At",
]);

// computed values
const showFilterPane = computed(() => store.getters['research/showFilterPane'])

const fetchFilter = computed(function () {
  let query = new URLSearchParams({});

  if (allTasksFilters.taskTitleFilter) {
    query.append("task_title", allTasksFilters.taskTitleFilter);
  }
  if (allTasksFilters.statusFilter) {
    query.append("status", allTasksFilters.statusFilter);
  }
  if (allTasksFilters.taskTypeFilter) {
    query.append("task_type", allTasksFilters.taskTypeFilter);
  }
  if (allTasksFilters.sourceFilter) {
    query.append("source", allTasksFilters.sourceFilter);
  }
  if (allTasksFilters.pageSizeFilter) {
    query.append("page_size", allTasksFilters.pageSizeFilter); // To append more data
  }

  let url;
  let queriesCount = 0;
  for (let value of query.values()) {
    queriesCount = queriesCount + 1;
  }
  if (queriesCount > 0) {
    url = "?" + query.toString();
  } else {
    url = "?";
  }
  return url;
});

const viewingFromNbr = computed(function () {
  return (
    (page.value * allTasksFilters.pageSizeFilter + 1) - allTasksFilters.pageSizeFilter
  );
});

const viewingToNbr = computed(function () {
  if (page.value === num_pages.value) {
    return count.value;
  } else {
    return page.value * allTasksFilters.pageSizeFilter;
  }
});

// async functions

async function loadAllTasks(endpoint = fetchFilter.value, retainSpot) {
  isLoading.value = true;
  let payload = {
    token: store.state.auth.token,
    endpoint: retainSpot ? endpoint + `&page=${page.value}` : endpoint,
  };
  const response = await getAllTasks(payload);
  next.value = response.next ? `&page=${response.page + 1}` : null;
  prev.value = response.prev ? `&page=${response.page - 1}` : null;
  count.value = response.count;
  page.value = response.page;
  num_pages.value = response.num_pages;
  all_tasks.value = response.all_tasks;
  page_size.value = response.page_size;
  isLoading.value = false;
}

async function setAllTasksFilter(updatedFilters) {
  allTasksFilters.taskTitleFilter = updatedFilters.value.taskTitleFilter;
  allTasksFilters.statusFilter = updatedFilters.value.statusFilter;
  allTasksFilters.taskTypeFilter = updatedFilters.value.taskTypeFilter;
  allTasksFilters.sourceFilter = updatedFilters.value.sourceFilter;
  await loadAllTasks(fetchFilter.value);
}

async function setPageSizeFilter(target) {
  target == "clear"
    ? (allTasksFilters.pageSizeFilter = 50)
    : (allTasksFilters.pageSizeFilter = target);
  await loadAllTasks(fetchFilter.value);
}

// functions

function getNext() {
  if (next.value) {
    loadAllTasks(fetchFilter.value + next.value);
  } else {
    alert("No next page");
  }
}

function getPrev() {
  if (prev.value) {
    loadAllTasks(fetchFilter.value + prev.value);
  } else {
    alert("No previous page");
  }
}

function goToFirst() {
  if (page.value != 1) {
    loadAllTasks(fetchFilter.value + `&page=${1}`);
  } else {
    alert("At first page");
  }
}

function goToLast() {
  if (num_pages.value != page.value) {
    loadAllTasks(fetchFilter.value + `&page=${num_pages.value}`);
  } else {
    alert("At last page");
  }
}

function jumpToPage(targetPage) {
  loadAllTasks(fetchFilter.value + `&page=${targetPage}`);
}

// life-cycle

onMounted(() => {
  loadAllTasks();
});
</script>
