
import axios from 'axios';
import { BASE_URL } from './api';



export async function getCampaigns(token) {
    // console.log('getCampaigns')
    return axios({
        method: 'get',
        url: `${BASE_URL}/campaigns/`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        throw error
    });

}

export async function getActiveCampaigns(token) {
    // console.log('getActiveCampaigns')
    return axios({
        method: 'get',
        url: `${BASE_URL}/campaigns/?active_status=Active`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        throw error
    });

}