<template>
    <div class="print:hidden mx-1">
        <transition-group
        tag="div"
        enter-active-class="duration-400 ease-out"
        enter-from-class="transform opacity-0 -translate-y-10"
        enter-to-class="opacity-100"
        leave-active-class="duration-400 ease-out absolute"
        leave-from-class="opacity-100"
        leave-to-class="transform opacity-0 -translate-y-10"
        move="transition-all duration-400 ease"
        >
            <div class="transition-all duration-300 ease-out" key="company-info">
                <div
                class="flex flex-row space-x-2 items-center pb-0.5 border-b mx-1.5 border-b-gray-400"
                >
                <!-- <h3 class="text-sm">Client Info:</h3> -->
                <base-button @click="setDueSoonest">Due Soonest</base-button>
                <base-button-affirmative @click="resetFilters" class="text-xs -py-2"
                    >Reset</base-button-affirmative
                >
                </div>
                <div
                class="pt-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8"
                id="company-info"
                >
                <base-filter @click="setClientOptions" :active="invoiceFilters.client.value.length > 0">
                    <template v-slot:label> Client </template>
                    <template v-slot:widget>
                    <BaseComboBox
                        class="w-full rounded-b-md text-black"
                        v-model="invoiceFilters.client.value"
                        :options="invoiceFilters.client.options"
                        @update:modelValue="setClientFilter"
                        :multiple="true"
                    />
                    </template>
                </base-filter>
                <base-filter :active="!!invoiceFilters.status.value">
                    <template v-slot:label> Status </template>
                    <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md text-black"
                        v-model="invoiceFilters.status.value"
                        :options="invoiceFilters.status.options"
                        :multiple="false"
                    />
                    </template>
                </base-filter>
                <base-filter :active="invoiceFilters.dueDate.value.length > 0">
                    <template v-slot:label> Due Date </template>
                    <template v-slot:widget>
                    <base-list-box
                        class="w-full rounded-b-md text-black"
                        v-model="invoiceFilters.dueDate.value"
                        :options="invoiceFilters.dueDate.options"
                        @update:modelValue="setDueDateFilter"
                        :multiple="true"
                    />
                    </template>
                </base-filter>
                </div>
            </div>
        </transition-group>
        <div>
        <div class="flex flex-wrap items-center space-x-2 mx-1.5">
          <div class="group flex justify-start items-center space-x-2">
            <h3 class="text-sm">Active Filters:</h3>
          </div>
          <base-pill class="bg-amber-300 flex items-center justify-between space-x-2 p-2 mb-0.5 " v-for="filter in activeFilters" :key="filter.name">
          <div> {{ filter.name }}: {{ filter.value }} </div><button @click="clearFilter(filter.filter)">✕</button> 
          </base-pill>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'invoice-filters'
}
</script>

<script setup>
import { ref, watch, reactive, defineEmits, computed } from 'vue';
import {useStore} from 'vuex'
import { getClients } from '@/services/Client';
import BaseComboBox from '../BaseComboBox.vue'

const store = useStore()

const emit = defineEmits([
  "change-invoice-filters",
  "change-ordering"
]);

const invoiceFilters = reactive({
    client: {
        name: "Client",
        value: [],
        defaultValue: [],
        options: [
      {value: "clear", label: "------------"}
    ]
    },
    status: {
        name: "Status",
        value: "unpaid",
        defaultValue: "unpaid",
        options: [
      {value: null, label: "------------"},
      {value: "paid", label: "paid"},
      {value: "unpaid", label: "unpaid"},
      {value: "write_off", label: "write off"},
    ]
    },
    dueDate: {
        name: "Due Date",
        value: [],
        defaultValue: [],
        options: [
      {value: "clear", label: "------------"},
      {value: "overdue", label: "Overdue"},
      {value: "three_days", label: "Three Days"},
      {value: "five_days", label: "Five Days"},
      {value: "ten_days", label: "Ten Days"},
    ]
    }
})

const filterValues = computed(() => {
    let values = []
    for(const filter in invoiceFilters){
        values.push(invoiceFilters[filter].value)
    }
    return values
})

const clientOptions = ref([
      {value: "clear", label: "------------"}
    ])
const statusOptions = ref([
      {value: null, label: "------------"},
      {value: "paid", label: "paid"},
      {value: "unpaid", label: "unpaid"},
    ])
const dueDateOptions = ref([
      {value: "clear", label: "------------"},
      {value: "overdue", label: "Overdue"},
      {value: "three_days", label: "Three Days"},
      {value: "five_days", label: "Five Days"},
      {value: "ten_days", label: "Ten Days"},
    ])

async function setClientOptions() {
      const token = store.state.auth.token
      const result = await getClients({token: token})
    //   clientOptions.value = [{value: "clear", label: "------------"}, ...result.clients.map((client) => {
    //     return {value: client.id, label: client.client_name}
    //   })]
      invoiceFilters.client.options = [{value: "clear", label: "------------"}, ...result.clients.map((client) => {
        return {value: client.id, label: client.client_name}
      })]
    }

function setClientFilter(target) {
      if(target.includes("clear")){
        invoiceFilters.client.value = []
      }
    }
function setDueDateFilter(target) {
      if(target.includes("clear")){
        invoiceFilters.dueDate.value = []
      }
    }

watch(filterValues, () => {
    emitFiltersChanged()
}, {deep: true})

function emitFiltersChanged(){
    emit("change-invoice-filters", invoiceFilters)
}

function resetFilters() {
    for(const filter in invoiceFilters){
        invoiceFilters[filter].value = invoiceFilters[filter].defaultValue
    }
}

function clearFilter(filterName) {
    if(Array.isArray(invoiceFilters[filterName].value)){
        invoiceFilters[filterName].value = []
    } else {
        invoiceFilters[filterName].value = null
    }
}

const activeFilters = computed(() => {
    let activeFilterArr = []
    for(const filter in invoiceFilters) {
        if(Array.isArray(invoiceFilters[filter].value) && invoiceFilters[filter].value.length > 0){
            console.log('found array')
            activeFilterArr.push({filter: filter, name: invoiceFilters[filter].name, value: invoiceFilters[filter].options.filter(option => invoiceFilters[filter].value.includes(option.value)).map(option => option.label)})
        } else if (!Array.isArray(invoiceFilters[filter].value) && invoiceFilters[filter].value) {
            activeFilterArr.push({filter: filter, name: invoiceFilters[filter].name, value: invoiceFilters[filter].value})
        }
    }
    return activeFilterArr
})

setClientOptions()

function setDueSoonest() {
    invoiceFilters.status.value = 'unpaid'
    emit('change-ordering', ['due_date'])

}

</script>