<template>
    <svg 
    version="1.1" 
    id="Layer_1"
     xmlns="http://www.w3.org/2000/svg" 
     xmlns:xlink="http://www.w3.org/1999/xlink" 
     x="0px" 
     y="0px" 
     viewBox="0 0 1200 1000" 
     xml:space="preserve"
     :fill="fill"
     >
        <g>
            <g>
                <path d="M332.8,603.2c-23.7,0-35.9-16.7-36.2-36c-0.1-19.7,12.2-36.7,36.2-36.7c9.3,0,18.6,3.4,25.6,10.5l4.3-3.8
                    c-8.2-8.2-19.2-12.3-30-12.3c-28.2,0-42.5,20.6-42.3,42.6c0.1,22.2,14.6,41.6,42.3,41.6c10.8,0,21.8-4.1,30-12.3l-4.2-4
                    C351.5,599.6,342,603.2,332.8,603.2z"/>
                <path d="M538.5,525.8h-36.1v82.3h6.3v-28.4h29.7C574.2,579.7,574.2,525.8,538.5,525.8z M538.5,573.9h-29.7v-42.2h29.7
                    C565.9,531.7,565.9,573.9,538.5,573.9z"/>
                <path d="M602.9,525.8v82.3h6.2v-82.3H602.9z"/>
                <path d="M648.1,526v5.6h29.7v76.5h6.4v-76.5h29.7V526H648.1z"/>
                <path d="M784.8,525.8h-6.9l-36.9,82.3h6.8l33.5-76.1l33.6,76.1h6.8L784.8,525.8z"/>
                <path d="M432.7,525.8h-6.9l-36.9,82.3h6.8l33.5-76.1l33.6,76.1h6.8L432.7,525.8z"/>
                <path d="M863.2,602.1v-76.3h-6.3v82.3h52.6v-6H863.2z"/>
            </g>
            <g>
                <path d="M337.5,434.8c-12.5-1.5-23.1-4-23.1-13c0-7.7,7-13.8,21.5-13.8c11.3,0,21,5.6,24.6,11.6l15.6-8
                    c-7.9-16-24.6-20.6-40.8-20.6c-19.1,0.1-40.2,8.9-40.2,30.2c0,23.3,19.6,28.9,40.8,31.4c13.8,1.5,24,5.5,24,15.3
                    c0,11.3-11.5,15.5-23.9,15.5c-12.6,0-24.6-5-29.2-16.5l-16.3,8.5c7.7,19,24,25.5,45.2,25.5c23.1,0,43.6-9.9,43.6-33
                    C379.3,443.1,359.1,437.5,337.5,434.8z"/>
                <path d="M494.8,394.6v43.7h-51v-43.7h-19.6v103.8h19.6v-42h51v42h19.4V394.6H494.8z"/>
                <g>
                    <g>
                        <rect x="561.9" y="437.4" width="75.6" height="18.2"/>
                        <rect x="561.9" y="479" width="77.7" height="19.3"/>
                    </g>
                    <rect x="561.9" y="394.5" width="77.7" height="19"/>
                </g>
                <path d="M723.8,394.5H683v103.8h40.8c36.3,0,52.3-26.5,51.9-52.8C775.2,419.9,759.4,394.5,723.8,394.5z M723.8,479.5h-21.3v-66.4
                    h21.3c22.5,0,32,16.1,32.5,32.3C756.7,462.3,747.3,479.5,723.8,479.5z"/>
                <path d="M857.7,394.5h-40.8v103.8h40.8c36.3,0,52.3-26.5,51.9-52.8C909.1,419.9,893.2,394.5,857.7,394.5z M857.7,479.5h-21.4
                    v-66.4h21.4c22.5,0,32,16.1,32.5,32.3C890.6,462.3,881.2,479.5,857.7,479.5z"/>
            </g>
        </g>
    </svg>
</template>

<script setup>
import { ref, defineProps } from 'vue';

const props = defineProps(['fillColor'])

const fill = ref(props.fillColor ? props.fillColor : 'black')
</script>