<template>
    <div class=" w-full border-b border-neutral-2 "
        @click="selectOption(option)"
        @mouseover="showDetails(option)"
        @mouseout="hideDetails"
        ref="detailsBoundary"
        :style="optionStyle"
    >
        <div v-if="includeDetails && visibleDetails"
            class="text-primary-extra-dark  border-b rounded-t-md border-gray-300">
            <div v-if="option.value" class=" px-2 py-1">
                {{ option.label }}
            </div>
            <div v-else
                @click="handleNullOption"
                class="flex space-x-2 items-center px-2"
            >
                <slot name="nullIcon"></slot>
                {{ option.label }}
           
            </div>
        </div>
        <div v-else
            class=" hover:bg-indigo-100"
        >
            <div class="px-2 py-1">
                {{  option.label }}
            </div>
            <!-- <div v-if="option.value" class="px-2 py-1">
                {{ option.label }}
            </div>
            <div v-else
                class="flex space-x-2 items-center px-2"
            >
                <slot name="nullIcon"></slot>
                {{ option.label }}

            </div> -->
        </div>
        <PopoverHover
            v-if="includeDetails && visibleDetails && option.value"
            :xPosition="xPosition"
            :yPosition="yPosition"
            :width="optionsWidth"
        >
            <template #content>
                <div class="flex w-full space-x-2 p-2 items-center  text-primary-extra-dark rounded-b-md  border-r border-b border-l  border-neutral-3">
                    <slot name="details"></slot>
                </div>
            </template>
        </PopoverHover>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, watch } from 'vue'
import PopoverHover from '@/components/base-components/PopoverHover.vue';
const props = defineProps(['option', 'includeDetails', 'optionsWidth', 'selectedOptions', 'selectedOption'])
const emit = defineEmits(['select-option', 'show-details', 'handle-null-option'])

const visibleDetails = ref(false)
const xPosition = ref(null)
const yPosition = ref(null)

const detailsBoundary = ref(null)

function selectOption(option) {
    emit('select-option', option)
}

function showDetails(option) {
    emit('show-details', option)
    // console.log('show Details')
    xPosition.value = detailsBoundary.value.getBoundingClientRect().left - 1
    yPosition.value = detailsBoundary.value.getBoundingClientRect().bottom
    visibleDetails.value = true
}

function hideDetails() {
    visibleDetails.value = false
}

function handleNullOption() {
    emit('handle-null-option')
}

const isSelected = computed(() => {
    if(props.selectedOptions) {
        return props.selectedOptions.includes(props.option.value)
    }
    else if(props.selectedOption) {
        return props.selectedOption == props.option.value
    }
    return false
})

const optionStyle = computed(() => {
    if(isSelected.value) {
        return 'background-color: #EEF2FF; color: #1F2937; border-bottom-width: 1px; border-color: #D1D5DB;'
    }
    return ''
})

</script>