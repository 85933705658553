<template>
    <div class= "justify-center items-center mx-1">
      <div class="flex flex-row justify-between items-center pb-0.5 border-b mx-1.5 border-b-gray-400">
        <h3 class="text-sm">Bulk Operations:</h3>
      </div>
      <div class="grid grid-cols-2 pt-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 2xl:flex 2xl:flex-row 2xl:flex-wrap justify-center 2xl:justify-start items-center">
        <!-- <base-pill-button
          class="m-1 2xl:my-1 text-xs" 
          v-if="userPermissions.isDev ||
            userPermissions.isAccountManager ||
            userPermissions.isManagement ||
            userPermissions.isSuperuser ||
            userPermissions.isAccountStaff
          " 
          @click="setContactBulkAction('Assign To Collection', 'bulk_assign_collection_tag');loadCollectionTags()">
            Assign To Collection
        </base-pill-button>
        <base-pill-button
          class="m-1 2xl:my-1 text-xs" 
          v-if="userPermissions.isDev ||
            userPermissions.isAccountManager ||
            userPermissions.isManagement ||
            userPermissions.isSuperuser ||
            userPermissions.isAccountStaff
          " 
          @click="setContactBulkAction('Remove From Collection', 'bulk_remove_collection_tag');loadCollectionTags()">
            Remove From Collection
        </base-pill-button>
        <base-pill-button
          class="m-1 2xl:my-1 text-xs" 
          v-if="userPermissions.isDev ||
            userPermissions.isManagement ||
            userPermissions.isSuperuser
          " 
          @click="setContactBulkAction('Assign Source Owner', 'bulk_assign_source_owner');loadSourceOwners()">
            Assign Source Owner
        </base-pill-button>
        <base-pill-button
          class="m-1 2xl:my-1 text-xs"
          v-if="userPermissions.isDev ||
            userPermissions.isAccountManager ||
            userPermissions.isManagement ||
            userPermissions.isSuperuser
          "
          @click="setContactBulkAction('Mark Tier Removed', 'bulk_mark_tier', null)">
            Mark Tier Removed
        </base-pill-button>
        <base-pill-button
          class="m-1 2xl:my-1 text-xs"
          v-if="userPermissions.isDev ||
            userPermissions.isAccountManager ||
            userPermissions.isManagement ||
            userPermissions.isSuperuser
          "
          @click="setContactBulkAction('Assign To Campaign', 'bulk_assign_to_campaign'); loadCampaigns()">
            Assign To Campaign
        </base-pill-button>
        <base-pill-button
          class="m-1 2xl:my-1 text-xs"
          v-if="userPermissions.isDev ||
            userPermissions.isAccountManager ||
            userPermissions.isManagement ||
            userPermissions.isSuperuser ||
            userPermissions.isAccountStaff
          "
          @click="setContactBulkAction('Bulk Send Email', 'bulk_send_email_template'); loadEmailTemplates()">
            Bulk Send Email
        </base-pill-button> -->
      </div>
      <base-dialog-box v-if="isContactModalVisible" @close="closeContactModal">
          <template #header>
            <h2>Bulk Action: {{ bulkActionTitle }}</h2>
          </template>
          <template #body>
            <!-- <ContactBulkActions
              :title="bulkActionTitle"
              :endpoint="bulkActionEndpoint"
              :bulkActionData="bulkActionData"
              :sourceOwners="sourceOwners"
              :collectionTags="collectionTags"
              :campaigns="campaigns"
              :crmUsers="crmUsers"
              :crmStatuses="crmStatuses"
              :selectedContacts="selectedContacts"
              :emailTemplates="emailTemplates"
              @reset-select-all="resetSelectAll"
              @close-modal="closeContactModal"
              @refetch-contacts="fetchNewContacts"
              @clear-selected-contacts="clearSelectedContacts"
            /> -->
          </template>
      </base-dialog-box>
    </div>
  </template>
  
  <script setup>
    import { ref, computed, shallowReactive } from 'vue';
    import { getEmailTemplates } from '@/services/EmailTemplates';
    import {useStore} from 'vuex';
    import { defineProps, defineEmits } from 'vue';
  
    const props = defineProps({
      selectedContacts: {
        type: Array,
        required: false
      },
      userPermissions: {
        type: Object,
        required: false
      },
      filters: {
        type: String,
        required: false
      }
    })
    const emit = defineEmits(['refetch-contacts', 'clear-selected-contacts', 'reset-select-all'])
  
    const isContactModalVisible = ref(false);
    const emailTemplates = ref([]);
    const campaigns = ref([]);
    const bulkActionTitle = ref(null);
    const bulkActionMessage = ref(null);
    const bulkActionEndpoint = ref(null);
    const bulkActionData = ref(null);
    const store = useStore();
    const showBulk = ref(true);
    const showBulkText = ref('Hide Bulk Ops');
    const userPermissions = shallowReactive({
    isManagement: null,
    isHourly: null,
    isStudent: null,
    isResearch: null,
    isAdvancedResearch: null,
    isSuperuser: null,
    isAccountManager: null,
    isAccountStaff: null,
    isDev: null,
    isResearchLead: null,
    isAllContacts: null,
    isGenerateTimecard: null,
  });
  
  
    async function loadEmailTemplates(){
      const token = store.getters['auth/showToken']
      const result = await getEmailTemplates(token)
      // console.log('email template results', result)
      emailTemplates.value = result.map((template) => {
          return {value: template.id, label: `${template.email_template_name} | Email to: ${template.email_to.email_address}`}
      })
    }
  
    function showContactModal() {
      isContactModalVisible.value = true;
    }
  
    function closeContactModal() {
      isContactModalVisible.value = false;
    }
    
    function setContactBulkAction(title, endpoint, data=null) {
      if(props.selectedContacts.length > 0) {
        bulkActionTitle.value = title
        bulkActionEndpoint.value = endpoint
        bulkActionData.value = data
      } else {
        bulkActionTitle.value = 'Alert'
        bulkActionEndpoint.value = null
        bulkActionData.value = null
      }
      showContactModal()
    }
  
  
    function fetchNewContacts() {
      // console.log('bulk ops refetch contacts ran')
      emit('refetch-contacts')
    }
  
    function clearSelectedContacts() {
      // console.log('bulk ops - clear selected contacts ran')
      emit('clear-selected-contacts')
    }
  
    function resetSelectAll() {
      // console.log('bulk ops reset select all ran')
      emit('reset-select-all')
    }
    const setUserPermissions = function () {
    let userPermissionsStore = store.getters['auth/showUserPermissions']
    userPermissions.isManagement = userPermissionsStore.isManagement
    userPermissions.isHourly = userPermissionsStore.isHourly
    userPermissions.isStudent =  userPermissionsStore.isStudent
    userPermissions.isResearch =  userPermissionsStore.isResearch
    userPermissions.isAdvancedResearch =  userPermissionsStore.isAdvancedResearch
    userPermissions.isSuperuser =  userPermissionsStore.isSuperuser
    userPermissions.isAccountManager =  userPermissionsStore.isAccountManager
    userPermissions.isAccountStaff =  userPermissionsStore.isAccountStaff
    userPermissions.isDev =  userPermissionsStore.isDev
    userPermissions.isResearchLead =  userPermissionsStore.isResearchLead
    userPermissions.isAllContacts =  userPermissionsStore.isAllContacts
    userPermissions.isGenerateTimecard =  userPermissionsStore.isGenerateTimecard
  }
    const RenderBulkOperations = computed(function () {
    if(userPermissions.isAccountManager){
      return true
    }
    if(userPermissions.isAccountStaff){
      return true
    }
    if(userPermissions.isDev){
      return true
    }
    if(userPermissions.isManagement){
      return true
    }
    if(userPermissions.isResearchLead){
      return true
    }
    if(userPermissions.isSuperuser){
      return true
    }
    return false
  })
  
    setUserPermissions()
  </script>