<template>
    <div class=" max-w-2xl rounded-md pt-1 text-sm">
        <form @submit.prevent="createNewAction" action="">
        <div class="flex">
            <div class="w-full p-1">
                <div class="rounded-t-md w-full bg-gray-300 p-1">
                    New Detail:
                </div>
                <div class="w-full">
                        <div class="grid grid-cols-4">
                            <!-- <div class="flex items-center justify-between bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <div class="flex items-center space-x-1 justify-between">
                                     <input id="actionRadio" type="radio" v-model="newActionType" name="actionOrEvent" value="to-do"> <label for="actionRadio">To-do</label>
                                </div>
                                <div class="flex items-center space-x-1 justify-between">
                                     <input id="eventRadio" type="radio" v-model="newActionType" name="actionOrEvent" required value="event"> <label for="eventRadio">Event</label>
                                </div>
                            </div> -->
                            <select required v-model="newActionData.action_id" class="bg-gray-50 col-span-2 text-center p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" name="" id="">
                                <!-- <option value="">------</option> -->
                                <option v-for="event in eventTypes" :key=event.id :value="event.id">{{ event.action_name }}</option>
                            </select>
                            <!-- <select required v-if="newActionType == 'to-do'" v-model="newActionData.status" class="bg-gray-50 text-center p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" name="" id="">
                                <option value="">---Status---</option>
                                <option value="Open">Open</option>
                                <option value="Done">Done</option>
                            </select> -->
                            <!-- <select required disabled v-else-if="newActionType == 'event'" v-model="newActionData.status" class="bg-gray-50 text-center p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" name="" id="">
                                <option value="">---Status---</option>
                                <option value="Open">Open</option>
                                <option value="Done">Done</option>
                            </select> -->
                            <div class="grid grid-cols-3 items-center col-span-2 bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <div class="w-1/4">
                                    <label>Due:</label>
                                </div>
                                <input v-model="newActionData.due_date" id="crmNewActionDueDate" type="date"
                                    class="w-full block col-span-2"
                                >
                            </div>
                            <div class="grid grid-cols-3 items-center col-span-2 bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <div class="w-1/4">
                                    <label>Resolved:</label>
                                </div>
                                <input v-model="newActionData.resolved_date" id="crmNewActionResolvedDate" type="date"
                                    class="w-full block col-span-2"
                                >
                            </div>
                            <select v-model="newActionData.user_id" id="crmNewActionUser"
                                class="bg-gray-50 col-span-2 p-1 border text-center w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                >
                                <option value="">---User---</option>
                                <option value=3>Scott Shedd</option>
                                <option v-for="crmUser in eventUsers" :key="crmUser.id" :value="crmUser.id">{{ crmUser.first_name}} {{ crmUser.last_name }}</option>
                            </select>
                            <textarea v-model="newActionData.action_notes" id="notesInput" type="text" rows="3" placeholder="Notes"
                                class="bg-gray-50 col-span-4 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            ></textarea>
                        </div>
                </div>
            </div>

            <div class="p-1 flex flex-col space-y-1">
                <div class="rounded-t-md bg-gray-300 p-1 mb-1">
                    Priority:
                </div>
                <div class="grid grid-cols-2 gap-y-1 w-full place-items-center">

                    <div @click="setHighlightColor('dark_green')" class="hover:cursor-pointer w-6 h-6 rounded bg-green-600 border border-gray-300">
                        
                    </div>
                    <div @click="setHighlightColor('light_green')" class="hover:cursor-pointer w-6 h-6 rounded bg-green-300 border border-gray-300">
                        
                    </div>
                    <div @click="setHighlightColor('yellow')" class="hover:cursor-pointer w-6 h-6 rounded bg-yellow-400 border border-gray-300">

                    </div>
                    <div @click="setHighlightColor(null)" class="hover:cursor-pointer w-6 h-6 rounded  border border-gray-300 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-4 w-4">
                            <path fill-rule="evenodd" d="m6.72 5.66 11.62 11.62A8.25 8.25 0 0 0 6.72 5.66Zm10.56 12.68L5.66 6.72a8.25 8.25 0 0 0 11.62 11.62ZM5.105 5.106c3.807-3.808 9.98-3.808 13.788 0 3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788Z" clip-rule="evenodd" />
                        </svg>

                    </div>
                </div>

                <div class="py-4 h-full">
                    <div class="p-1 rounded-md flex h-full justify-center items-center bg-accent-primary text-white">
                        <button class="w-full">
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="newActions && newActions.length > 0" class="flex flex-wrap w-full mt-2">
            <div v-for="newAction in newActions" :key="newAction.id">
            + {{ newAction.resolved_date ? newAction.action.resolved_label : newAction.action.unresolved_label }} - {{ newAction.action_notes ? truncateNotes(newAction.action_notes) : '' }} {{ newAction.resolved_date ? "Resolved: " : "Due:" }} {{ newAction.resolved_date ? formatDate(newAction.resolved_date) : formatDate(newAction.due_date) }}&nbsp;
            </div>
        </div>
    </form>
    </div>
</template>

<script setup>
import { defineProps, ref, defineEmits, reactive, computed, watch, onMounted } from 'vue';
import { markHighlightColor } from '@/services/CrmAction';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';
import { addNewCrmAction } from '@/services/Company';
import { formatDate } from '@/composables/formatDate';

const store = useStore()
const $toast = useToast()
const props = defineProps(['company', 'event', 'eventTypes', 'eventUsers', 'newActions'])

const colorOptions = ref([
    'yellow',
    'light_green',
    'dark_green'
])

const emit = defineEmits(['refetch-companies', 'set-highlight-color', 'add-new-action'])


const newActionType = ref("to-do")

function truncateNotes(notes){
    if(notes.length > 10){
        return `${notes.substring(0, 7)}...`
    } else {
        return notes
    }
}


async function setHighlightColor(color){
    emit('set-highlight-color', color)
}

const newActionData = ref({
    action_id: 19,
    action_notes: "",
    user_id: store.state.auth.user.id,
    company_id: props.company.id,
    campaign_id: props.company.campaign.id,
    status: "Open",
    highlight_color: null,
    user: null,
    action: null,
    resolved_date: null,
    due_date: null,
    id: Math.random()
})

function resetActionData() {
    newActionData.value.action_id = 19,
    newActionData.value.action_notes = "",
    newActionData.value.user_id = store.state.auth.user.id,
    newActionData.value.company_id = props.company.id,
    newActionData.value.campaign_id = props.company.campaign.id,
    newActionData.value.highlight_color = null,
    newActionData.value.user = null,
    newActionData.value.action = null,
    newActionData.value.resolved_date = null,
    newActionData.value.due_date = null,
    newActionData.value.id = Math.random()
}

function createNewAction(){
    const actionData = {
        action_id: newActionData.value.action_id,
        action_notes: newActionData.value.action_notes,
        user_id: newActionData.value.user_id,
        company_id: newActionData.value.company_id,
        campaign_id: newActionData.value.campaign_id,
        highlight_color: newActionData.value.highlight_color,
        user: newActionData.value.user,
        action: newActionData.value.action,
        resolved_date: newActionData.value.resolved_date,
        due_date: newActionData.value.due_date,
        id: newActionData.value.id
    }
    actionData.action = props.eventTypes.find((eventType) => eventType.id == actionData.action_id )
    actionData.user = props.eventUsers.find((user) => user.id == actionData.user_id )
    // console.log('action data', actionData)
    emit('add-new-action', actionData)
    resetActionData()
}

onMounted(() => {
    document.activeElement.blur()
    const notes = document.getElementById("notesInput")
    notes.focus()
})



</script>