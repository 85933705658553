export function formatDate (dateString) {
    if(dateString && dateString != ""){
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric', timeZone: 'UTC'}).format(date);
    } else {
        return 'None'
    }
}

export function formatDateMonthAndDay (dateString) {
    if(dateString && dateString != ""){
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', timeZone: 'UTC'}).format(date);
    } else {
        return 'None'
    }
}

export function formatDateTwoDigitYear (dateString) {
    if(dateString && dateString != ""){
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'2-digit', timeZone: 'UTC'}).format(date);
    } else {
        return 'None'
    }
}

export function formatDateWithWeekday (dateString) {
    if(dateString && dateString != ""){
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'2-digit', weekday:'short'}).format(date);
    } else {
        return 'None'
    }
}

export function formatDateWithMinutes (dateString) {
    if(dateString && dateString != ""){
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric', hour:'numeric', minute:'numeric'}).format(date);
    } else {
        return 'None'
    }
}

export function formatHTMLDate (dateString) {
    if(dateString && dateString != ""){
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]
    } else {
        return 'None'
    }
}

export function formatDateYearMonthDay (dateString) {
    console.log('formatDateYearMonthDay', dateString)
    if(dateString && dateString != ""){
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US',{year:'numeric', month:'2-digit',day:'2-digit', timeZone: 'UTC'}).format(date);
    } else {
        return 'None'
    }
}