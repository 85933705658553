export default {
    setCompanies(state, companies) {
        console.log('setCompanies Mutation')
        state.companies = companies;
        console.log("companies list: ", companies)
    },
    setIsLoadingTrue(state) {
        state.isLoading = true;
    },
    setIsLoadingFalse(state) {
        state.isLoading = false;
    },
    setNext(state, next) {
        state.next = next
    },
    setPrev(state, prev){
        state.prev = prev
    },
    setCount(state, count){
        state.count = count
    },
    setPage(state, page){
        state.page = page
    },
    setPageSize(state, pageSize){
        state.pageSize = pageSize
    },
    setShowCreateCompanyFormTrue(state) {
        state.showCreateCompanyForm = true;
    },
    setShowCreateCompanyFormFalse(state) {
        state.showCreateCompanyForm = false;
    },
    setActiveFilters(state, activeFilters){
        state.activeFilters = activeFilters
    },
    setShowFilterPaneTrue(state) {
        state.showFilterPane = true;
    },
    setShowFilterPaneFalse(state) {
        state.showFilterPane = false;
    },
    setShowTableFiltersTrue(state) {
        state.showTableFilters = true;
    },
    setShowTableFiltersFalse(state) {
        state.showTableFilters = false;
    },
    setShowActiveFiltersTrue(state) {
        state.activeFiltersVisible = true;
    },
    setShowActiveFiltersFalse(state) {
        state.activeFiltersVisible = false;
    },
    setShowBasicFiltersTrue(state) {
        state.showBasicFilters = true;
    },
    setShowBasicFiltersFalse(state) {
        state.showBasicFilters = false;
    },
    setShowCampaignFiltersTrue(state) {
        state.showCampaignFilters = true;
    },
    setShowCampaignFiltersFalse(state) {
        state.showCampaignFilters = false;
    },
    setShowResearchFiltersTrue(state) {
        state.showResearchFilters = true;
    },
    setShowResearchFiltersFalse(state) {
        state.showResearchFilters = false;
    },
    setShowBulkOperationsPaneTrue(state) {
        state.showBulkOperationsPane = true;
    },
    setShowBulkOperationsPaneFalse(state) {
        state.showBulkOperationsPane = false;
    },
    setShowStatusSummaryPaneTrue(state) {
        state.showStatusSummaryPane = true;
    },
    setShowStatusSummaryPaneFalse(state) {
        state.showStatusSummaryPane = false;
    },
    setShowCRMSummaryStagesTrue(state) {
        state.showCRMSummaryStages = true;
    },
    setShowCRMSummaryStagesFalse(state) {
        state.showCRMSummaryStages = false;
    },
    setShowMyOpenTasksTrue(state){
        state.showMyOpenTasks.show = true
    },
    setShowMyOpenTasksFalse(state){
        state.showMyOpenTasks.show = false
    },
    setShowMyOpenTasksUser(state, user){
        state.showMyOpenTasks.user = user
    },
    setVisibleFilters(state, payload) {
        state.visibleFilters[payload.filterName] = payload.status
    },
    setVisibleFiltersAllTrue(state){
        state.visibleFilters = { 
            companyNameFilter: true,
            companyDescriptionFilter: true,
            companyWebsiteUrlFilter: true,
            companyAddressFilter: true,
            companySourcesFilter: true,
            companySourceOwnerFilter: true,
            companyCampaignFilter: true,
            companyCampaignExcludeFilter: true,
            companyTierFilter: true,
            companyClientFeedbackFilter: true,
            companyOnboardingStatusFilter: true,
            companyDNRReasonFilter: true,
            companyResearchStatusFilter: true,
            companyResearchStatusFilterTwo: true,
            companyRevenueFilter: true,
            companyHeadcountFilter: true,
            companyRevenueZiCombinedFilter: true,
            companyClientFilter: true,
            companyBusinessTypeFilter: true,
            companySectorFilter: true,
            companySubSectorFilter: true,
            companyCRMStatusFilter: true,
            companyCRMNextActionFilter: true,
            companyDueTimeRangeFilter: true,
            companyStateFilter: true,
            companyCollectionTagsFilter: true,
            companyCollectionTagSourcesFilter: true,
            companyDrivingTimeFocusFilter: true,
            companyDrivingTimeFilter: true,
            companyNAICSFilter: true,
            companyTaskUserFilter: true,
            companyShowResearchHaltedFilter: true,
            companyParentCompanyStatusFilter: true,
            companyInvestorsStatusFilter: true,
            companyProblemStatusFilter: true,
            companyAccountManagerResearchNotesFilter: true,
            showMyTasksFilter: true,
            companyIdFilter: true,
            companyCategoryFilter: true,
            companyRelevantBusinessFilter: true,
            companyReferralSourceFilter: true,
            companyReferralStatusFilter: true,
            companyProductServiceCodeFilter: true,
            companyContractPSCKeywordFilter:true,
            companyContractNAICSKeywordFilter:true,
            companyContractAwardingSubAgencyFilter:true,
            companyEveraxisPillarFilter:true,
            companyCRMActionsFilter:true,
            companyCollectionTagExcludeFilter:true,
            companyHasCRMActionsFilter:true,
            companyCapabilitiesFilter:true,
            companyHasConflictsFilter:true,
            companyParentTypeFilter:true,
            companyCollectionReviewFilter: true,
            companyPreviousCampaignFilter: true,
            companyCageCodeFilter: true,
            companyUeiSamFilter: true,
            companyIgpCategoryFilter: true,
            companyCountryFilter: true,
            companyCampaignChangeRequestorFilter: true,
        }
    },
    setRefetchCompaniesTrue(state) {
        state.refetchCompanies = true
    },
    setRefetchCompaniesFalse(state) {
        state.refetchCompanies = false
    },
    setCRMSummaryStagesClient(state, clientId) {
        state.crmSummaryStagesClient = clientId
    }

}