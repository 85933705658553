import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './main.css'
import store from './store/index.js';
import BaseCard from "@/components/BaseCard";
import BaseCardSelect from "@/components/BaseCardSelect";
import BaseButton from "@/components/BaseButton";
import BaseFilterNoButton from "@/components/BaseFilterNoButton.vue";
import BaseButtonDisabled from "@/components/BaseButtonDisabled.vue"
import BaseMenuButton from "@/components/BaseMenuButton";
import BaseButtonAffirmative from './components/BaseButtonAffirmative.vue';
import BaseButtonNegative from './components/BaseButtonNegative.vue';
import BasePillButton from "@/components/BasePillButton";
import BasePillX from "@/components/BasePillX";
import BaseTableCell from "@/components/BaseTableCell";
import BaseTableCellSmall from './components/BaseTableCellSmall.vue';
import BaseTable from "@/components/BaseTable";
import BaseTableFull from "@/components/BaseTableFull";
import BaseTableAuto from "@/components/BaseTableAuto.vue";
import BaseTableHeader from "@/components/BaseTableHeader";
import BaseTableCondensed from "@/components/BaseTableCondensed";
import BaseTableHeaderCondensed from "@/components/BaseTableHeaderCondensed";
import BaseTableCellCondensed from "@/components/BaseTableCellCondensed";
import BaseTableRow from "@/components/BaseTableRow";
import BaseAccordion from "@/components/BaseAccordion";
import BaseModal from "@/components/BaseModal";
import BaseModalSmall from "@/components/BaseModalSmall";
import BaseDialogBox from './components/BaseDialogBox.vue';
import BaseFilter from './components/BaseFilter.vue';
import BaseFilterAddOn from "@/components/BaseFilterAddOn.vue";
import BaseFilterHorizontal from "@/components/BaseFilterHorizontal.vue";
import BaseForm from "@/components/BaseForm.vue";
import BaseFormFooter from "@/components/BaseFormFooter.vue";
import BaseListBox from "@/components/BaseListBox.vue";
import BaseListBoxHorizontal from "@/components/BaseListBoxHorizontal.vue";
import BaseRadioGroup from "@/components/BaseRadioGroup";
import BaseMenuDropdown from '@/components/BaseMenuDropdown.vue';
import BasePaginationArrows from './components/BasePaginationArrows.vue'
import BasePaginationLoader from './components/BasePaginationLoader.vue'
import BasePaginationHeader from './components/BasePaginationHeader.vue'
import BasePopoverHover from "@/components/BasePopoverHover";
import BasePopoverHoverSmall from '@/components/BasePopoverHoverSmall.vue';
import BasePopoverClick from "@/components/BasePopoverClick";
import BaseRemovablePill from "@/components/BaseRemovablePill";
import BaseTableHeaderWidthFull from '@/components/BaseTableHeaderWidthFull';
import BasePill from "@/components/BasePill.vue"
import BasePillPopoverHover from "@/components/BasePillPopoverHover.vue";
import BaseInfiniteScroll from './components/BaseInfiniteScroll.vue'
import BaseInfiniteDropdown from './components/BaseInfiniteDropdown.vue';
import BaseTabBar from './components/BaseTabBar.vue';
import BaseSideBarTab from '@/components/BaseSideBarTab.vue';
import BaseSideBarAccordion from '@/components/BaseSideBarAccordion.vue';
import ToastPlugin from 'vue-toast-notification';
import UnderConstruction from '@/components/UnderConstruction.vue';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import BaseDoubleClickEditInput from './components/BaseDoubleClickEditInput.vue';
import BaseDoubleClickEditTextAreaVue from './components/BaseDoubleClickEditTextArea.vue';
import BaseDoubleClickSelectVue from './components/BaseDoubleClickSelect.vue';
import clickOutside from "@/directives/clickOutside";
import doubleClick from "@/directives/doubleClick";
import onEscape from './directives/onEscape';
import BaseToolTip from '@/components/BaseToolTip.vue';
import TypeAheadBox from './components/base-components/TypeAheadBox.vue';
import TypeAheadMultiSelectBox from './components/base-components/TypeAheadMultiSelectBox.vue';

const app = createApp(App);

app.directive("click-outside", clickOutside);
app.directive("double-click", doubleClick);
app.directive("on-escape", onEscape);

app.use(store);
app.use(ToastPlugin);

app.use(router);
app.component('base-card', BaseCard)
app.component('base-card-select', BaseCardSelect)
app.component('base-button', BaseButton)
app.component('base-button-disabled', BaseButtonDisabled)
app.component('base-menu-button', BaseMenuButton)
app.component('base-button-affirmative', BaseButtonAffirmative)
app.component('base-button-negative', BaseButtonNegative)
app.component('base-pill-button', BasePillButton)
app.component('base-pill-x', BasePillX)
app.component('base-pagination-arrows', BasePaginationArrows)
app.component('base-pagination-loader', BasePaginationLoader)
app.component('base-pagination-header', BasePaginationHeader)
app.component('base-table-condensed', BaseTableCondensed)
app.component('base-table-cell-condensed', BaseTableCellCondensed)
app.component('base-table-cell-small', BaseTableCellSmall)
app.component('base-table-header-condensed', BaseTableHeaderCondensed)
app.component('base-table', BaseTable)
app.component('base-table-auto', BaseTableAuto)
app.component('base-table-header', BaseTableHeader)
app.component('base-table-cell', BaseTableCell)
app.component('base-table-row', BaseTableRow)
app.component('base-accordion', BaseAccordion)
app.component('base-modal', BaseModal)
app.component('base-modal-small', BaseModalSmall)
app.component('base-dialog-box', BaseDialogBox)
app.component('base-filter-add-on', BaseFilterAddOn)
app.component('base-filter', BaseFilter)
app.component('base-filter-no-button', BaseFilterNoButton)
app.component('base-table-full', BaseTableFull)
app.component('base-filter-horizontal', BaseFilterHorizontal)
app.component('base-form', BaseForm);
app.component('base-form-footer', BaseFormFooter);
app.component('base-list-box', BaseListBox)
app.component('base-list-box-horizontal', BaseListBoxHorizontal)
app.component('base-radio-group', BaseRadioGroup)
app.component('base-menu-dropdown', BaseMenuDropdown)
app.component('base-popover-hover', BasePopoverHover)
app.component('base-popover-hover-small', BasePopoverHoverSmall)
app.component('base-popover-click', BasePopoverClick)
app.component('base-removable-pill', BaseRemovablePill)
app.component('base-pill', BasePill)
app.component('base-pill-popover-hover', BasePillPopoverHover)
app.component('base-table-header-width-full', BaseTableHeaderWidthFull)
app.component('base-infinite-scroll', BaseInfiniteScroll)
app.component('base-infinite-dropdown', BaseInfiniteDropdown)
app.component('base-tab-bar', BaseTabBar)
app.component('base-side-bar-tab', BaseSideBarTab)
app.component('base-side-bar-accordion', BaseSideBarAccordion)
app.component('base-dblclick-input', BaseDoubleClickEditInput)
app.component('base-dblclick-textarea', BaseDoubleClickEditTextAreaVue)
app.component('base-dblclick-select', BaseDoubleClickSelectVue)
app.component('under-construction', UnderConstruction)
app.component('base-tool-tip', BaseToolTip)
app.component('type-ahead-box', TypeAheadBox)
app.component('type-ahead-multiselect', TypeAheadMultiSelectBox)
app.mount('#app');
