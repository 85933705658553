<template>
  <div class="w-fullh-full">
    <HeadlessDialogBox v-if="openDialogBox" @close-dialog-box="closeDialogBox">
        <template #header>
            <span>{{ responseStatus }}</span>
        </template>
        <template #body>
            <span>{{ responseMessage }}</span>
        </template>
    </HeadlessDialogBox>
    <base-card v-show="showFilterPane">
      <JobFilters @change-job-filters="setJobFilters" />
    </base-card>
    <base-card class="flex flex-col text-sm text-gray-900 ">
      <div class="flex flex-col justify-start w-full mb-2">
        <div
          class="flex flex-row items-center justify-start lg:border-none h-full"
        >
          <div class="flex flex-row pl-2 items-center py-1">
            <base-pagination-header
              @load-data="loadJobQueue(fetchFilter.value, true)"
              @get-next="getNext"
              @go-to-first="goToFirst"
              @get-prev="getPrev"
              @go-to-last="goToLast"
              @jump-to-page="jumpToPage"
              @set-page-size-filter="setPageSizeFilter"
              :page_filter="jobFilters"
              :page="page"
              :numPages="numPages"
              :isLoading="isLoading"
            >
              <template v-slot:message>
                <div class="pl-3 pt-1">
                  {{ viewingFromNbr }}-{{ viewingToNbr }} | {{ count }} Total
                  Tasks
                </div>
              </template>
            </base-pagination-header>
          </div>
          <div class="px-3 flex flex-row mt-1">
            <base-button @click="openJobQueueForm" class="whitespace-nowrap"
              >New Job</base-button
            >
          </div>
        </div>
      </div>
      <div class="mx-2">
        <JobQueueTable
          v-if="jobResults.length > 0"
          :jobs="jobResults"
          :currentUserId="currentUserId"
          @refetch-jobs="loadJobQueue(fetchFilter, true)"
          @rerun-job="createNewJobFromExisting"
        />
      </div>
      <base-modal :is-form-visible="showJobQueueForm" @close="closeJobQueueForm">
        <template #header>
          <h2 class="text-lg">Create Asynchronous Job</h2>
        </template>
        <template #body>
          <JobQueueForm
            :containerType="'modal'"
            @close="closeJobQueueFormAndOpenDialogBox"
            @refetch-jobs="loadJobQueue(fetchFilter, true)"
          />
        </template>
      </base-modal>
    </base-card>
  </div>
</template>

<script setup>
import { ref, reactive, computed, watch } from "vue";
import { useStore } from "vuex";
import JobFilters from "./JobFilters.vue";
import JobQueueTable from "./JobQueueTable.vue";
import JobQueueForm from "./JobQueueForm.vue";
import { getAsynchronousJobQueue } from "@/services/AsynchronousJobs";
import { createAsynchronousJob } from "@/services/AsynchronousJobs";
import HeadlessDialogBox from '@/components/HeadlessDialogBox.vue';
import { useToast } from 'vue-toast-notification';

const store = useStore();
const $toast = useToast();

const isLoading = ref(false);
const openDialogBox = ref(false);
const responseStatus = ref('');
const responseMessage = ref('');

const showJobQueueForm = ref(false);

const showFilterPane = computed(
  () => store.getters["asynchronousJobs/showFilterPane"]
);

const currentUserId = computed(() => {
  return store.getters['auth/showUser'] ? store.getters['auth/showUser'].id : null
})


function openJobQueueForm() {
  showJobQueueForm.value = true;
}

function closeJobQueueForm() {
  showJobQueueForm.value = false;
}

function closeJobQueueFormAndOpenDialogBox(response) {
  console.log('closeJobQueueFormAndOpenDialogBox')
  console.log(response)
  showJobQueueForm.value = false;
  responseStatus.value = response.responseStatus
  responseMessage.value = response.responseMessage
  openDialogBox.value = true;
  console.log('open dialog box value', openDialogBox.value)
}

function closeDialogBox() {
  openDialogBox.value = false
}

const next = ref();
const prev = ref();
const count = ref();
const numPages = ref();
const pageSize = ref();
const page = ref(1);

const awaitingSearch = ref(false);

const showDialogBox = ref(false);

const jobResults = ref([]);

const jobFilters = reactive({
  jobIdFilter: null,
  jobTitleFilter: null,
  jobDetailsFilter: null,
  jobTypeFilter: null,
  jobStatusFilter: null,
  pageSizeFilter: 50,
});

const pageSizeFilter = reactive({
  jobPageSizeFilter: 50,
});

watch(
  () => jobFilters,
  () => {
    if (!awaitingSearch.value) {
      setTimeout(() => {
        loadJobQueue();
        awaitingSearch.value = false;
      }, 2500); // 2.5 sec delay
    }
    awaitingSearch.value = true;
  },
  { deep: true }
);

function setJobFilters(updatedFilters) {
  jobFilters.jobIdFilter = updatedFilters.value.jobIdFilter;
  jobFilters.jobTitleFilter = updatedFilters.value.jobTitleFilter;
  jobFilters.jobDetailsFilter = updatedFilters.value.jobDetailsFilter;
  jobFilters.jobTypeFilter = updatedFilters.value.jobTypeFilter;
  jobFilters.jobStatusFilter = updatedFilters.value.jobStatusFilter;
}

function setJobPageSizeFilter(target) {
  target == "clear"
    ? (pageSizeFilter.jobPageSizeFilter = null)
    : (pageSizeFilter.jobPageSizeFilter = target);
  loadJobQueue();
}

const viewingFromNbr = computed(function () {
  return (page.value * pageSizeFilter.jobPageSizeFilter + 1) - pageSizeFilter.jobPageSizeFilter
});

const viewingToNbr = computed(function () {
  if (page.value === numPages.value) {
    return count.value;
  } else {
    return page.value * jobFilters.pageSizeFilter;
  }
});

const fetchFilter = computed(function () {
  let query = new URLSearchParams({});

  if (jobFilters.jobIdFilter) {
    query.append("id_number", jobFilters.jobIdFilter);
  }
  if (jobFilters.jobTitleFilter) {
    query.append("job_title", jobFilters.jobTitleFilter);
  }
  if (jobFilters.jobDetailsFilter) {
    query.append("job_details", jobFilters.jobDetailsFilter);
  }
  if (jobFilters.jobTypeFilter) {
    if (Array.isArray(jobFilters.jobTypeFilter)) {
      jobFilters.jobTypeFilter.forEach((jobType) => {
        query.append("job_type", jobType);
      });
    }
  }

  if (jobFilters.pageSizeFilter) {
    query.append("page_size", jobFilters.pageSizeFilter); // To append more data
  }

  if (jobFilters.jobStatusFilter) {
    if (Array.isArray(jobFilters.jobStatusFilter)) {
      jobFilters.jobStatusFilter.forEach((jobStatus) => {
        query.append("status", jobStatus);
      });
    }
  }

  let url;
  let queriesCount = 0;
  for (let value of query.values()) {
    queriesCount = queriesCount + 1;
  }
  if (queriesCount > 0) {
    url = "?" + query.toString();
  } else {
    url = "?";
  }
  return url;
});

async function loadJobQueue(endpoint = fetchFilter.value, retainSpot) {
  isLoading.value = true;
  let payload = {
    token: store.state.auth.token,
    endpoint: retainSpot ? endpoint + `&page=${page.value}` : endpoint,
  };
  const response = await getAsynchronousJobQueue(payload);
  // console.log(response)
  next.value = response.next ? `&page=${response.page + 1}` : null;
  prev.value = response.prev ? `&page=${response.page - 1}` : null;
  count.value = response.count;
  page.value = response.page;
  numPages.value = response.numPages;
  pageSize.value = response.pageSize;
  jobResults.value = response.jobs;
  isLoading.value = false;
}

function getNext() {
  if (next.value) {
    loadJobQueue(fetchFilter.value + next.value);
  } else {
    alert("No next page");
  }
}

async function setPageSizeFilter(target) {
  target == "clear"
    ? (jobFilters.pageSizeFilter = 50)
    : (jobFilters.pageSizeFilter = target);
  await loadJobQueue(fetchFilter.value);
}

function getPrev() {
  if (prev.value) {
    loadJobQueue(fetchFilter.value + prev.value);
  } else {
    alert("No previous page");
  }
}

function goToFirst() {
  if (page.value != 1) {
    loadJobQueue(fetchFilter.value + `&page=${1}`);
  } else {
    alert("At first page");
  }
}

function goToLast() {
  if (numPages.value != page.value) {
    loadJobQueue(fetchFilter.value + `&page=${numPages.value}`);
  } else {
    alert("At last page");
  }
}

function jumpToPage(targetPage) {
  loadJobQueue(fetchFilter.value + `&page=${targetPage}`);
}

async function createNewJobFromExisting(job) {
  isLoading.value = true
  const token = store.state.auth.token
  const data = {
    data: {
        job_data: {
        job_title: job.job_title,
        job_details: job.job_details,
        job_type: 'full_text_search'
      },
      parameter_data: job.parameters.map((parameter) => {
        return {
          id: parameter.parameter.id,
          parameter_value_string: parameter.parameter_value_string
        }
      })
    }
  }
  console.log('data', data)
  const response = await createAsynchronousJob(data, token)
  console.log('response')
  console.log(response)
  if(response.status === 201){
      $toast.open({
        message: response.data,
        type: "success",
        duration: 5000
      })
    } else {
      $toast.open({
        message: "Could not create job rerun",
        type: "error",
        duration: 10000
      })
    }
  isLoading.value = false
}

loadJobQueue();
</script>
