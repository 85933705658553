<template>
    <div class="w-full">
        <!-- {{ selectedOptions }} {{ selected }} -->
        <div class="flex flex-col w-full ">
            <div
                class="w-full h-8 bg-white border flex items-center border-gray-300 "
                :style="selectedOptionsStyle"
           
                >
                <div class="flex w-full h-full items-center whitespace-nowrap overflow-x-scroll"  v-if="selectedOptions.length > 0">
                    <div v-for="selectedOption in selectedOptions"
                        :key="selectedOption.value"
                        class="flex space-x-1 text-primary-extra-dark py-[2px] mt-[4px] mb-[1px] bg-indigo-200 px-2 mx-0.5 rounded-md text-dark-primary w-fit cursor-pointer hover:bg-indigo-300 hover:text-gray-800 hover:shadow"
                         @click="deselectOption(selectedOption)"
                    >
                        <div>
                            {{ selectedOption.label }}
                        </div>
                        <div>
                            <XMarkSVG 
                                :stroke-color="removeOptionStroke"
                                stroke-width="1.5"
                            />
                        </div>
                    </div>
                </div>
                <div v-else class="flex w-full h-full items-center text-gray-500 tracking-wide px-2">
                    No Campaigns Selected
                </div>
                <!-- <button v-if="visibleInput" @click="clearSelected" class="relative right-0 px-1 py-[6px] h-full bg-white whitespace-nowrap tracking-wider text-gray-800 hover:bg-gray-300 ">
                    Clear All
    
                </button> -->
                <button v-if="visibleInput" @click="permitOptionsClickOutside(); hideOptions();" class="relative right-0 px-1 py-[6px] h-full bg-white whitespace-nowrap tracking-wider flex justify-center items-center w-8  text-gray-800 hover:bg-gray-300 ">
                    <ChevronUpMiniSVG />
                </button>
                <button v-else @click="showInputAndOptions" class="relative right-0 px-1 py-[6px] h-full bg-white whitespace-nowrap tracking-wider flex justify-center items-center w-8  text-gray-800 hover:bg-gray-300 ">
                    <ChevronDownMiniSVG />
                </button>
            </div>
            <div class="flex w-full h-7" ref="inputBoundary">
                <input type="text"
                    v-model="searchText"
                    v-if="visibleInput"
                    @focus="showOptions"
                    @blur="permitOptionsClickOutside"
                    @keydown.enter.exact="selectFirstOption"
                    class="w-full px-2 py-[5px] border-l  border-gray-300"
                    :style="textInputStyle"
                    :placeholder="placeholder"
                />
                <button v-if="searchText && visibleInput" @click="clearSearchText" class="relative right-0 px-2 py-[5px] border-r  border-gray-300 bg-white hover:bg-gray-300">
                    <XMarkSVG 
                        :stroke-color="xStrokeColor"
                        stroke-width="1.5"
                    />
                </button>
            </div>
        </div>
        <div v-if="visibleOptions"
            ref="optionsBoundary"
            class="bg-white cursor-pointer z-20 fixed overflow-y-scroll  border border-gray-300"
            :style="optionsPosition"
        >
            <TypeAheadOption v-for="option in filteredOptions"
                :key="option.value"
                :option="option"
                :selectedOptions="selected"
                :include-details="includeDetails"
                @select-option="selectOption"
                @show-details="showDetails"
                @handle-null-option="handleNullOption"
            >
                <slot></slot>
            </TypeAheadOption>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue'
import { useClickOutside } from "@/composables/useClickOutside";
import TypeAheadOption from "@/components/base-components/TypeAheadOption.vue";
import XMarkSVG from '../svg-components/XMarkSVG.vue';
import ChevronDownMiniSVG from '../svg-components/ChevronDownMiniSVG.vue';
import ChevronUpMiniSVG from '../svg-components/ChevronUpMiniSVG.vue';
const props = defineProps(['defaultValue', 'options', 'selectMultiple', 'maxOptionsHeight', 'includeDetails', 'placeholder', 'clearValue', 'nullLabel'])
const emit = defineEmits(['update-value', 'show-details', 'handle-null-option', 'reset-clear-value'])


const selected = ref(props.defaultValue ? props.defaultValue : [])
const searchText = ref(getDefaultLabel(selected.value))
const visibleOptions = ref(false)
const visibleInput = ref(false)
const allowOptionsClickOutside = ref(false)
const inputBoundary = ref(null)
const optionsBoundary = ref(null)

const xStrokeColor = ref('black')

const removeOptionStroke = ref('#25274D')

// watch(() => props.clearValue, () => {
//     if(props.clearValue) {
//         selected.value = true
//         searchText.value = null
//         emit('reset-clear-value')
//     }
// })

watch(() => props.defaultValue, () => {
    // console.log('watch default value')
    selected.value = props.defaultValue
})

const filteredOptions = computed(() => {
    // console.log('filteredOptions')
    if(searchText.value && searchText.value != '') {
        let filteredResults = props.options.filter(searchTextInOptions)
        return filteredResults.length > 0 ? filteredResults : [{label: `${props.nullLabel ? props.nullLabel : '--------'}`, value: null}]
    } else {
        return props.options
    }
})

const optionsWidth = computed(() => {
    // console.log('optionsWidth')
    return inputBoundary.value.getBoundingClientRect().right - inputBoundary.value.getBoundingClientRect().left
})


const optionsHeight = computed(() => {
    // console.log('optionsHeight')
    // console.log('default height', props.maxOptionsHeight ? props.maxOptionsHeight : window.innerHeight - (inputBoundary.value.getBoundingClientRect().bottom + 10))
    // console.log('options height', filteredOptions.length * 10)
    return Math.min(
        (props.maxOptionsHeight ? props.maxOptionsHeight : window.innerHeight - (inputBoundary.value.getBoundingClientRect().bottom + 10)), 
        ((filteredOptions.value.length * 25.1))
    )
})

const selectedOptions = computed(() =>{
    // console.log('selectedOptions')
    // return ['test', 'test']
    if(selected.value && selected.value.length > 0) {
        return props.options.filter((option) => selected.value.includes(option.value))
    } else {
        return []
    }
})

const selectedOptionsStyle = computed(() => {
    // console.log('selectedOptionsStyle')
    return !visibleInput.value ? '' : ''
})

const textInputStyle = computed(() => {
    // console.log('textInputStyle')
    let style = ''
    if(selectedOptions.value && selectedOptions.value.length > 0) {
        style += 'border-top-width: 0px; '
    } else {
        style += 'border-top-width: 1px; '
    }
    if(visibleOptions.value) {
        style += 'border-bottom-width: 0px; '
    }
    else {
        style += 'border-bottom-width: 1px; '
    }
    if(!searchText.value) {
        style += 'border-right-width: 1px; '
    }
    return style
})

const optionsPosition = computed(() => {
    // console.log('optionsPosition')
    if(inputBoundary.value) {
        return inputBoundary.value ? `left: ${inputBoundary.value.getBoundingClientRect().left}px; top: ${inputBoundary.value.getBoundingClientRect().bottom}px; width: ${optionsWidth.value}px; height: ${optionsHeight.value}px` : ''
    }
    return ''

})


function getDefaultLabel(value) {
    // console.log('getDefaultLabel')
    if(value) {
        let optionObject = props.options.find(option => option.value == value)
        if(optionObject) {
            return optionObject.label
        } else {
            return ''
        }
    } else {
        return null
    }
}

function clearSelected() {
    searchText.value = null
    selected.value = []
    emit('update-value', selected.value)
}

function clearSearchText() {
    searchText.value = null
}

function showInput() {
    // console.log('showInput')
    visibleInput.value = true
}

function hideInput() {
    // console.log('hideInput')
    visibleInput.value = false
}

function showOptions() {
    // console.log('showOptions')
    visibleOptions.value = true
    allowOptionsClickOutside.value = false
}

function hideOptions() {
    // console.log('hideOptions')
    visibleOptions.value = false
    searchText.value = null
}

function handleNullOption() {
    // console.log('handleNullOption')
    emit('handle-null-option')
}

function showInputAndOptions() {
    // console.log('showInputAndOptions')
    showInput()
    showOptions()
}

function isSelected(option) {
    // console.log('isSelected', option)
    return selectedOptions.value.includes(option)
}

function selectOption(option) {
    // console.log('select option', option)
    if(option.value) {
        if(option.label != 'No Results...') {
            // if(option.value == null) {
            //     searchText.value = null
            // } else {
            //     searchText.value = option.label
            // }
            const optionIndex = selected.value.indexOf(option.value)
            if(optionIndex == -1) {
                selected.value.push(option.value)
                emit('update-value', selected.value)
            } else {
                selected.value.splice(optionIndex, 1)
            }

            // hideOptions()
        }
    } else {
        return null
    }
    permitOptionsClickOutside()
}

function deselectOption(option) {
    // console.log('deselectOption', option)
    const optionIndex = selected.value.indexOf(option.value)
    // console.log(optionIndex)
    if(optionIndex != -1) {
        selected.value.splice(optionIndex, 1)
    }
    permitOptionsClickOutside()
}

function selectFirstOption() {
    if(filteredOptions.value.length > 0) {
        selectOption(filteredOptions.value[0])
    }
}

function showDetails(option) {
    // console.log('showDetails')
    emit('show-details', option)
}

function permitOptionsClickOutside() {
    // console.log('permitOptionsClickOutside')
    allowOptionsClickOutside.value = true
}

function searchTextInOptions(option) {
    // console.log('searchTextInOptions')
    if(!option.value) {
        return true
    }
    if(option.label && option.label.toLowerCase().includes(searchText.value.toLowerCase())) {
        return true
    }
    return false
}

useClickOutside(optionsBoundary, () => {
    // console.log('useClickOutside')
    if(allowOptionsClickOutside.value) {
        if(visibleOptions.value) {
            hideOptions()
        }
        if(selectedOptions.value.length > 0) {
            hideInput()
        }
    }
  })

</script>

<style scoped>
*:focus {
    outline: none;
}

::-webkit-scrollbar {
  height: 4px;
  width: 6px;      
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #b0b0b0;
}
</style>