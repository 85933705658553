<template>
    <div ref="chartDetailsBoundary" class="w-full h-full bg-gray-100 border-x border-gray-200 p-4">
        <div class="flex w-full justify-between items-center pb-2 border-b border-gray-300">
            <div class="tracking-widest font-semibold text-gray-600 text-sm">
               {{ chartId }}: {{ header }} | {{ numCompanies }}
            </div>
            <div>
                <button class="bg-dark-secondary hover:bg-dark-primary hover:text-gray-100 hover:shadow active:bg-dark-primary active:text-gray-400 text-white tracking-wider font-medium px-3 py-1.5 rounded-sm" @click="hideChartDetails">Hide</button>
            </div>
        </div>
        <div class="relative top-0 flex w-full left-0 right-0 z-10 tracking-widest text-gray-600 border-b py-3  border-gray-300">
            <div class="bg-gray-100  px-2   sticky top-0  flex w-[30%] justify-start font-semibold ">
                ID
            </div>
            <div class="bg-gray-100 pr-2   sticky top-0 flex w-[70%] justify-between whitespace-nowrap font-semibold">
                <div>
                    Company Name
                </div>
                <div>
                    Furthest Action
                </div>
            </div>
        </div>
        <div class="relative top-0 overflow-y-scroll h-[calc(100vh-13.5rem)]">
            <CRMChartDetailsCompanyRow
                v-for="(company, index) in companies" 
                :key="company.id"
                :company="company"
                :index="index"
                @select-company="selectCompany"
            />
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import { useClickOutside } from "@/composables/useClickOutside";
import CRMChartDetailsCompanyRow from './CRMChartDetailsCompanyRow.vue';

const props = defineProps(['chartId', 'header', 'companies', 'numCompanies'])
const emit = defineEmits(['hide-chart-details'])

const chartDetailsBoundary = ref(null)


function hideChartDetails() {
    emit('hide-chart-details')
}

// useClickOutside(chartDetailsBoundary, () => {
//     console.log('useClickOutside')
//     hideChartDetails()
// })
</script>