<template>
        <div class="flex items-center w-full justify-center h-full text-xs">
            <form @submit.prevent="submitForm" action="" class="h-full w-full">
                <base-form class="h-[80%]">
                    <template #body>
                        <div class="h-full w-full">
                            <div v-for="(company, index) in companyRows" :key="company.key" class="py-2 border-b border-b-gray-400 pr-3">
                                <div class="flex justify-between mt-2">
                                        <h3 class="text-xs font-bold">Company {{ index +1 }}</h3>
                                        <base-button class="py-0" @click.prevent="removeCompanyRow(index)">-</base-button>
                                    </div>
                                <div class="grid grid-cols-4 gap-2">
                                    <div class="flex-col">
                                        <div class="mb-0.5" for="">Company Name*</div>
                                        <input type="text" required class="border border-gray-400 rounded-md p-1 w-full" v-model="company.company.company_name">
                                    </div>
                                    <div class="flex-col">
                                        <div class="mb-0.5" for="">DBA</div>
                                        <input type="text" class="border border-gray-400 rounded-md p-1 w-full" v-model="company.company.doing_business_as">
                                    </div>
                                    <div class="flex-col">
                                        <div class="mb-0.5" for="">Parent Company</div>
                                        <input type="text" class="border border-gray-400 rounded-md p-1 w-full" v-model="company.company.parent_company">
                                    </div>
                                    <div class="flex-col">
                                        <div class="mb-0.5">
                                            Investors
                                        </div>
                                        <input type="text" class="border border-gray-400 rounded-md p-1 w-full" v-model="company.company.investors">
                                    </div>
                                    <div class="flex-col col-span-2">
                                        <div class="mb-0.5">
                                            Address
                                        </div>
                                        <input type="text" class="border border-gray-400 rounded-md p-1  w-full" v-model="company.company.address">
                                    </div>
                                    <div class="flex-col">
                                        <div class="mb-0.5">
                                            City*
                                        </div>
                                        <input type="text" required class="border border-gray-400 rounded-md p-1 w-full" v-model="company.company.city">
                                        
                                    </div>
                                    
                                    <div class="flex-col">
                                        <div class="mb-0.5">
                                            State
                                        </div>
                                        <input type="text" class="border border-gray-400 rounded-md p-1 w-full" v-model="company.company.state">
                                    </div>
                                    <div class="flex-col">
                                        <div class="mb-0.5">
                                            Country*
                                        </div>
                                        <input required type="text" class="border border-gray-400 rounded-md p-1 w-full" v-model="company.company.country">
                                    </div>
                                    <div class="flex-col">
                                        <div class="flex space-x-3 items-center px-1 mb-0.5">
                                            <span>URL*</span>
                                            <button @click.prevent="addHttp(index)" class="underline hover:font-bold ">http://</button>
                                            <button @click.prevent="markNoUrl(index)" class="underline hover:font-bold ">none</button>
                                        </div>
                                        <input required type="url" class="border border-gray-400 rounded-md p-1 w-full" v-model="company.company.website_url">
                                    </div>
                                    <div class="flex-col col-span-2">
                                        <div>
                                            Description
                                        </div>
                                        <textarea name="" v-model="company.company.description" class="border border-gray-400 rounded-md p-1 w-full" id="" cols="30" rows="1"></textarea>
                                    </div>
                                    <div class="flex-col col-span-2">
                                        <div>
                                            Account Manager Research Notes
                                        </div>
                                        <textarea name="" v-model="company.company.account_manager_research_notes" class="border border-gray-400 rounded-md p-1 w-full" id="" cols="30" rows="1"></textarea>
                                    </div>
                                    <div class="flex-col col-span-2">
                                        <div>
                                            Source*
                                        </div>
                                        <select required @click.prevent="setSourceOptions"  class="border border-gray-400 rounded-md p-1 w-full" v-model="company.company.source" name="" id="">
                                            <option v-for="source in sourceOptions" :key="source.value" :value="source.value">
                                                {{ source.label }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="flex-col col-span-2">
                                        <div>
                                            Campaign
                                        </div>
                                        <select class="border border-gray-400 rounded-md p-1 w-full" v-model="company.company.campaign" name="" id="">
                                            <option v-for="campaign in campaignOptions" :key="campaign.value" :value="campaign.value">
                                                {{ campaign.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div class="flex justify-end w-full pt-3.5 pr-3">
                                <base-button class="py-0" @click.prevent="addCompany">+</base-button>
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <div class="flex flex-row justify-center my-4">
                            <base-button-affirmative type="submit">Save All</base-button-affirmative>
                        </div>
                    </template>
                </base-form>
            </form>
        </div>
</template>

<script setup>
import { getSources, createSource } from '@/services/Source';
import { getActiveCampaigns } from '@/services/Campaign';
import { computed, ref, watch, reactive, defineEmits, defineProps } from 'vue';
import { useStore } from 'vuex';
import {createMultipleCompanies} from '@/services/Company'

const store = useStore()

const props = defineProps(['containerType', 'formCleared'])

const emit = defineEmits(['submit-companies', 'refetch-companies', 'set-form-cleared-false'])

watch(() => props.formCleared, ()=> {
    if(props.formCleared){
        clearFields()
        emit('set-form-cleared-false')
    }
})

const companyRows = ref([{
    key: Math.random(),
    company: {
        company_name: null,
        doing_business_as: null,
        parent_company: null,
        investors: null,
        address: null,
        city: null,
        state: null,
        country: null,
        website_url: null,
        source_owner: "Unassigned",
        source: null,
        campaign: null,
        account_manager_research_notes: null,
        description: null
    }
}])

const campaignOptions = ref([
    {value: null, label: '------'}
])
const sourceOptions = ref([
    {value: null, label: '------'}
])


function clearFields() {
    companyRows.value = [{
        key: Math.random(),
        company: {
            company_name: null,
            doing_business_as: null,
            parent_company: null,
            investors: null,
            address: null,
            city: null,
            state: null,
            country: null,
            website_url: null,
            source_owner: "Unassigned",
            source: null,
            campaign: null,
            account_manager_research_notes: null,
            description: null
        }
    }]
}


function addCompany(){
    companyRows.value.push({
        key: Math.random(),
        company: {
            company_name: null,
            doing_business_as: null,
            parent_company: null,
            investors: null,
            address: null,
            city: null,
            state: null,
            country: null,
            website_url: null,
            source_owner: "Unassigned",
            source: companyRows.value[companyRows.value.length -1] ? companyRows.value[companyRows.value.length -1].company.source : null,
            campaign: null,
            account_manager_research_notes: null,
            description: null
        }
    })
}

async function setCampaignOptions() {
    const token = store.state.auth.token
    const results = await getActiveCampaigns(token)
    campaignOptions.value = [
        {value: "", label: '------'}, ...results.map((campaign) => {
            return {value: campaign.id, label: campaign.campaign_name}
        })
    ]
}

async function setSourceOptions() {
    const token = store.state.auth.token
    const results = await getSources(token)
    sourceOptions.value = [
        {value: "", label: '------'}, ...results.map((source) => {
            return {value: source.id, label: source.source_name}
        })
    ]
}

const pageKey = ref(0)


function addHttp(index){
    companyRows.value[index].company.website_url = `http://${companyRows.value[index].company.website_url}`
}

function markNoUrl(index){
    companyRows.value[index].company.website_url = `http://no-url-${Math.random()}.com`
}

function removeCompanyRow(index) {
        companyRows.value.splice(index, 1)
    }


setCampaignOptions()
setSourceOptions()

async function submitForm(){
    const token = store.state.auth.token
    console.log('form submitted')
    const companies = companyRows.value.map(element => element.company)
    console.log('companies', companies)
    if(companies.length == 0){
        alert("Error, must add at least one company")
        return
    }
    const data = JSON.stringify({
        "companies": companies
    })
    const response = await createMultipleCompanies(data, token)
    if(response.status == 200){
        emit('submit-companies', {
            responseStatus: "Success",
            responseMessage: response.data['status'],
        })
    } else {
        emit('submit-companies', {
            responseStatus: "Error",
            responseMessage: response.data['status'],
        })
    }
}

</script>