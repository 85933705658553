<template>
    <div>
        <base-menu-dropdown v-if="userPermissions.isDev || userPermissions.isAccountManager || userPermissions.isManagement || userPermissions.isAdvancedResearch" class="hover:text-gray-200 shrink" 
            :links="links" :companyAdditionalLinks="companyAdditionalLinks" @click="fetchPageConfigs"
            :page-configs="pageConfigs" :user-permissions="userPermissions" :linkGroups="linkGroups">
            <template #label>Companies</template>
        </base-menu-dropdown>
        <router-link v-else class="hover:text-gray-200 shrink" to="/companies">Companies</router-link>
    </div>
</template>

<script>
export default {
  name: "company-research-links",
}
</script>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  userPermissions: Object,
  links: Array,
  companyAdditionalLinks: Array,
  pageConfigs: Array,
  linkGroups: Array

})
const emit = defineEmits(['fetch-page-configs'])

function fetchPageConfigs() {
  emit('fetch-page-configs')
}

</script>
