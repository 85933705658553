<template>
    <div class="overflow-y-auto">
        <base-table-full class="">
            <template #table-header>
                <base-table-header
                    :key="header.id"
                    :header="header"
                    v-for="header in tableHeadersList"
                >
                {{ header }}
                </base-table-header>
            </template>
            <template #table-body>
                <JobQueueRow
                @refetch-jobs="refetchJobs"
                    :key="job.id"
                    :job="job"
                    :currentUserId="currentUserId"
                    @rerun-job="rerunJob"
                    v-for="job in jobs"
                />
            </template>
        </base-table-full>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import JobQueueRow from "./JobQueueRow.vue";

const props = defineProps(['jobs', 'currentUserId'])

const emit = defineEmits(['refetch-jobs', 'rerun-job'])

const tableHeadersList = [
    'ID',
    'Job Title',
    'Status',
    'Details',
    'Type',
    'Created By',
    'Created At',
    'Updated At',
    'Actions'
]

function refetchJobs() {
    emit('refetch-jobs')
}

function rerunJob(job) {
    emit('rerun-job', job)
}

</script>