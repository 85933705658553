<template>
    <div class="w-full"  ref="inputBoundary">
        <div class="flex w-full">
            <input type="text"
                v-model="searchText"
                @focus="showOptions"
                @blur="permitOptionsClickOutside"
                @keydown.enter.exact="selectFirstOption"
                class="w-full px-2 py-[5px] border-l border-t border-b border-gray-300"
                :placeholder="placeholder"
                :style="textInputStyle"
            />
            <button v-if="searchText" @click="clearSelected" class="relative right-0 px-2 py-[5px] border-t border-r border-b border-gray-300 bg-white hover:bg-gray-300">
                <XMarkSVG 
                    :stroke-color="xStrokeColor"
                    stroke-width="1.5"
                />
            </button>
        </div>
        <div v-if="visibleOptions"
            ref="optionsBoundary"
            class="bg-white cursor-pointer z-20 fixed overflow-y-scroll  border-r border-b border-l border-gray-300"
            :style="optionsPosition"
        >
            <TypeAheadOption v-for="option in filteredOptions"
                :key="option.value"
                :option="option"
                :selectedOption="selected"
                :include-details="includeDetails"
                :options-width="optionsWidth"
                @select-option="selectOption"
                @show-details="showDetails"
                @handle-null-option="handleNullOption"
            >
                <template #nullIcon>
                    <slot name="nullIcon"></slot>
                </template>
                <template #details>
                    <slot name="details"></slot>
                </template>
            </TypeAheadOption>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue'
import { useClickOutside } from "@/composables/useClickOutside";
import TypeAheadOption from "@/components/base-components/TypeAheadOption.vue";
import XMarkSVG from '../svg-components/XMarkSVG.vue';
const props = defineProps(['defaultValue', 'options', 'selectMultiple', 'maxOptionsHeight', 'includeDetails', 'placeholder', 'clearValue', 'nullLabel'])
const emit = defineEmits(['update-value', 'show-details', 'handle-null-option', 'reset-clear-value'])


const selected = ref(props.defaultValue ? props.defaultValue : null)
const searchText = ref(getDefaultLabel(selected.value))
const visibleOptions = ref(false)
const visibleInput = ref(true)
const allowOptionsClickOutside = ref(false)
const inputBoundary = ref(null)
const optionsBoundary = ref(null)

const xStrokeColor = ref('black')

watch(() => props.clearValue, () => {
    if(props.clearValue) {
        selected.value = true
        searchText.value = null
        emit('reset-clear-value')
    }
})

const filteredOptions = computed(() => {
    if(searchText.value && searchText.value != '') {
        let filteredResults = props.options.filter(searchTextInOptions)
        return filteredResults.length > 0 ? filteredResults : [{label: `${props.nullLabel ? props.nullLabel : '--------'}`, value: null}]
    } else {
        return props.options
    }
})

const optionsWidth = computed(() => {
    console.log('optionsWidth')
    return inputBoundary.value.getBoundingClientRect().right - inputBoundary.value.getBoundingClientRect().left
})


const optionsHeight = computed(() => {    
    console.log('optionsHeight')
    // console.log('default height', props.maxOptionsHeight ? props.maxOptionsHeight : window.innerHeight - (inputBoundary.value.getBoundingClientRect().bottom + 10))
    // console.log('options height', filteredOptions.length * 10)
    return Math.min(
        (props.maxOptionsHeight ? props.maxOptionsHeight : window.innerHeight - (inputBoundary.value.getBoundingClientRect().bottom + 10)), 
        ((filteredOptions.value.length * 25.1))
    )
})

const selectedOptions = computed(() =>{
    // return ['test', 'test']
    if(selected.value && selected.value.length > 0) {
        return props.options.filter((option) => selected.value.includes(option.value))
    } else {
        return []
    }
})

const selectedOptionsStyle = computed(() => {
    return !visibleInput.value ? 'border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;' : ''
})

const textInputStyle = computed(() => {
    let style = ''
    if(selectedOptions.value && selectedOptions.value.length > 0) {
        style += 'border-top-left-radius: 0px; border-top-right-radius: 0px; '
    }
    if(visibleOptions.value) {
        style += 'border-bottom-left-radius: 0px; border-bottom-right-radius: 0px; '
    }
    if(!searchText.value) {
        style += 'border-right-width: 1px;'
    }
    return style
})

const optionsPosition = computed(() => {
    return inputBoundary.value ? `left: ${inputBoundary.value.getBoundingClientRect().left}px; top: ${inputBoundary.value.getBoundingClientRect().bottom}px; width: ${optionsWidth.value}px; height: ${optionsHeight.value}px` : ''
})


function getDefaultLabel(value) {
    if(value) {
        let optionObject = props.options.find(option => option.value == value)
        if(optionObject) {
            return optionObject.label
        } else {
            return ''
        }
    } else {
        return null
    }
}

function clearSelected() {
    searchText.value = null
    selected.value = null
    emit('update-value', selected.value)
}

function showInput() {
    // console.log('showInput')
    visibleInput.value = true
}

function hideInput() {
    visibleInput.value = false
}

function showOptions() {
    // console.log('show options')
    visibleOptions.value = true
    allowOptionsClickOutside.value = false
}

function hideOptions() {
    // console.log('hide options')
    visibleOptions.value = false
}

function handleNullOption() {
    emit('handle-null-option')
}

function showInputAndOptions() {
    showInput()
    showOptions()
}

function selectOption(option) {
    // console.log('select option', option)
    if(option.value) {
        if(option.label != 'No Results...') {
            if(option.value == null) {
                searchText.value = null
            } else {
                searchText.value = option.label
            }
            selected.value = option.value
            emit('update-value', option.value)
            hideOptions()
        }
    } else {
        return null
    }
}

function selectFirstOption() {
    if(filteredOptions.value.length > 0) {
        selectOption(filteredOptions.value[0])
    }
}

function showDetails(option) {
    emit('show-details', option)
}

function permitOptionsClickOutside() {
    allowOptionsClickOutside.value = true
}

function searchTextInOptions(option) {
    if(!option.value) {
        return true
    }
    if(option.label && option.label.toLowerCase().includes(searchText.value.toLowerCase())) {
        return true
    }
    return false
}

useClickOutside(optionsBoundary, () => {
    if(allowOptionsClickOutside.value) {
        if(visibleOptions.value) {
            hideOptions()
        }
        if(selectedOptions.value.length > 0) {
            hideInput()
        }
    }
  })

</script>

<style scoped>
*:focus {
    outline: none;
}
</style>