<template>
    <div class="w-full h-full min-h-screen select-none">
    <transition
            enter-active-class="transition duration-400 ease-in"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-100 ease-out"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            class="h-full"
        >
            <div v-if="savingChanges" class="fixed top-0 bottom-0 left-0 right-0 bg-black/[0.7] flex flex-col justify-center items-center z-1000"
                    tabindex="-1" >
                <div class="flex flex-col justify-center items-center p-4 rounded-md">
                <svg aria-hidden="true" class="mx-1 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#d3d3d3"/>
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#2E9CCA"/>
                </svg>
                <div class="text-white p-4 whitespace-nowrap text-sm">
                    Please wait while we save your changes.
                </div>
                </div>
            </div>
        </transition>
        <div class="w-full px-1 pt-1 fixed bg-neutral-200">
            <div class="w-full h-10 bg-neutral-50 flex items-center justify-between shadow-md rounded m-1 space-x-4">
                <div class="flex items-center space-x-4 justify-self-start pl-2">
                    <div class="w-fit">
                        <PaginationLoader
                            class="rounded-full hover:bg-gray-300"
                            @refetch-results="loadCompanies"
                            :isLoading="isLoading"
                          ></PaginationLoader>
                    </div>
                    <div v-if="viewMode === 'cards'" class="w-fit">
                        <ListSVG @click="setViewMode('list')" class="hover:bg-gray-300 rounded hover:cursor-pointer"></ListSVG>
                    </div>
                    <div v-if="viewMode === 'list'" class="w-fit">
                        <SquaresSVG @click="setViewMode('cards')" class="hover:bg-gray-300 rounded hover:cursor-pointer"></SquaresSVG>
                    </div>
                    <div class="flex text-xs">
                        <div class="rounded-l-md bg-gray-300 flex items-center p-1">
                            <div class="flex items-center">
                                Client:
                            </div>
                        </div>
                        <select v-model="clientId" class="bg-gray-50 p-1 h-3/4 rounded-r border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" name="" id="">
                                <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.client_name }}</option>
                        </select>
                    </div>
                    <div class="flex text-xs">
                        <div class="rounded-l-md bg-gray-300 flex items-center p-1">
                            <div class="flex items-center">
                                Detail Status:
                            </div>
                        </div>
                        <select v-model="displayActionStatus" class="bg-gray-50 p-1 h-3/4 rounded-r border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" name="" id="">
                                <option value="open">Open</option>
                                <option value="done">Done</option>
                        </select>
                    </div>
                    <div class="flex text-xs">
                        <div class="flex items-center p-1">
                            <div class="flex items-center">
                                Overdue Only:
                            </div>
                        </div>
                        <div class="flex items-center">
                            <input type="checkbox" v-model="overdueOnly" />
                        </div>
                    </div>
                    <div v-if="displayActionStatus == 'open'" class="flex text-xs">
                        <div class="flex items-center p-1">
                            <div class="flex items-center">
                                Show All Open:
                            </div>
                        </div>
                        <div class="flex items-center">
                            <input type="checkbox" v-model="showAllOpen" />
                        </div>
                    </div>
                    <div  v-if="viewMode == 'cards'" class="flex text-xs">
                        <div class="flex items-center p-1">
                            <div class="flex items-center">
                                Notes on Hover:
                            </div>
                        </div>
                        <div class="flex items-center">
                            <input type="checkbox" v-model="showNotesOnHover" />
                        </div>
                    </div>
                    <div ref="helpText">
                        <HelpSVG
                            @mouseover="showHelpText" @mouseleave="hideHelpText"
                                   class="hover:cursor-help"
                                    stroke="#4B5563"
                                />
                                <base-popover-click
                                v-if="visibleHelpPopover"
                                :xPosition="xPosition"
                                :yPosition="yPosition"
                            >
                                <div class="grid grid-cols-1 tracking-normal font-normal relative z-50 pb-4">
                                    <div class="flex border-b  tracking-wider font-semibold border-gray-200 p-1">
                                        <div>
                                            Hotkeys
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                        <div class="h-full flex items-center">CTRL + `</div>
                                        <div class="">
                                            Toggle Detail Status
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                        <div class="h-full flex items-center">CTRL + a</div>
                                        <div class="">
                                            Toggle Show All Open
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                        <div class="h-full flex items-center">CTRL + s</div>
                                        <div class="">
                                            Save Changes
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                        <div class="h-full flex items-center">Shift + 1</div>
                                        <div class="flex items-center space-x-1">
                                            <div class="w-6 h-6 rounded bg-green-600 border border-gray-300">
                                            </div>
                                            <div>= High Priority</div>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                        <div class="h-full flex items-center">Shift + 2</div>
                                        <div class="flex items-center space-x-1">
                                            <div class="w-6 h-6 rounded bg-green-200 border border-gray-300">
                                            </div>
                                            <div>= Medium Priority</div>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                        <div class="h-full flex items-center">Shift + 3</div>
                                        <div class="flex items-center space-x-1">
                                            <div class="w-6 h-6 rounded bg-yellow-400 border border-gray-300">
                                            </div>
                                            <div>= Low Priority</div>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                        <div class="h-full flex items-center">Shift + 0</div>
                                        <div class="flex items-center space-x-1">
                                            <div class="w-6 h-6 rounded  border border-gray-300 flex items-center justify-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-4 w-4">
                                                    <path fill-rule="evenodd" d="m6.72 5.66 11.62 11.62A8.25 8.25 0 0 0 6.72 5.66Zm10.56 12.68L5.66 6.72a8.25 8.25 0 0 0 11.62 11.62ZM5.105 5.106c3.807-3.808 9.98-3.808 13.788 0 3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788Z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                            <div>
                                                = Uncategorized
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </base-popover-click>
                    </div>
                </div>
                <div class="justify-self-start flex items-center justify-center space-x-2">
                        <div class="text-xs" v-if="autoSaveTimeRemaining > 0 && changeCount > 0">
                        Autosave in: {{ timeLeft }}
                        </div>
                        <base-button-affirmative :disabled="true" v-if="changeCount == 0">Save Changes</base-button-affirmative>
                        <base-button-affirmative v-else @click="saveChanges">Save Changes</base-button-affirmative>
                        <div class="text-sm flex items-center pr-4">
                            Changes: &nbsp; <transition name="slide-fade" mode="out-in">
                                                <span :key="changeCount">
                                                    {{ changeCount }}
                                                </span>
                                                </transition>
                        </div>
                </div>
            </div>
        </div>
        <div v-if="viewMode === 'cards'" class="p-4 w-full h-full mt-10">
            <div v-if="clientCrmStatuses" class="max-w-full grid grid-cols-3 gap-3">
                <div v-for="(crmStatusObject, crmStatus) in sortedClientCrmStatuses" :key="crmStatus" class="h-full flex w-full justify-center px-1">
                    <div class="flex flex-col items-center w-full" >
                        <div class="w-full flex justify-center bg-dark-primary text-white rounded-md mb-1">
                            <div :id="crmStatus" :ondrop="dropHandler" :ondragover="dragoverHandler" class="w-full text-center">
                                {{ crmStatus }}
                            </div>
                        </div>
                        <div class="flex flex-col items-center max-h-96 overflow-auto w-full">
                            <div v-for="company in sortedCompanies(crmStatusObject.companies)"  :key="company.id" class="flex w-full items-start px-1">
                                <QuickActionCompanyRow :overdue-only="overdueOnly" class="draggableRow" draggable="true" :id="company.id" :data-value="crmStatus"  @close-details="closeDetails" @save-changes="saveChanges" @add-new-action="addNewAction" @mark-to-do-done="markToDoDone" @set-highlight-color="setHighlightColor" :open-details-id="openDetailsId" :show-all-open="showAllOpen" :show-notes-on-hover="showNotesOnHover" :view-mode="viewMode" :display-action-status="displayActionStatus" :company="company" :campaigns="campaigns" :crm-action-types="crmActionTypes" :event-users="eventUsers" :crm-statuses="crmStatuses" :saved-changes="savedChanges" :company-c-r-m-statuses-to-update="companyCRMStatusesToUpdate" page-filter-type="client" @refetch-companies="loadCompanies"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="viewMode === 'list'" class="p-4 mt-10">
            <div class="flex flex-col items-center max-h-1/2 overflow-auto space-y-1 w-full">
                <div v-for="company in listModeCompanies" :key="company.id" class="flex w-full items-start px-1">
                    <QuickActionCompanyRow :overdue-only="overdueOnly" @close-details="closeDetails" @save-changes="saveChanges" @add-new-action="addNewAction" @mark-to-do-done="markToDoDone" @set-highlight-color="setHighlightColor" :open-details-id="openDetailsId" :show-all-open="showAllOpen" :show-notes-on-hover="showNotesOnHover" :view-mode="viewMode" :display-action-status="displayActionStatus" :company="company" :campaigns="campaigns" :crm-action-types="crmActionTypes" :event-users="eventUsers" :crm-statuses="crmStatuses" :saved-changes="savedChanges" :company-c-r-m-statuses-to-update="companyCRMStatusesToUpdate" page-filter-type="client" @refetch-companies="loadCompanies"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getHighPriorityCompanies } from '@/services/Company';
import { useRoute } from 'vue-router';
import {ref, computed, watch, onMounted, onUnmounted, onUpdated} from 'vue'
import { useStore } from 'vuex';
import QuickActionCompanyRow from './QuickActionCompanyRow.vue';
import PaginationLoader from "../base-components/PaginationLoader.vue";
import ListSVG from '../svg-components/ListSVG.vue';
import SquaresSVG from '../svg-components/SquaresSVG.vue';
import { getActions, bulkSaveQuickActions } from '@/services/CrmAction';
import { getCampaigns } from '@/services/Campaign';
import { getCrmStatuses } from '@/services/CrmStatus';
import { getUsersByGroup } from '@/services/User';
import { useToast } from 'vue-toast-notification';
import { getClients } from '@/services/Client';
import HelpSVG from '../svg-components/HelpSVG.vue';

const $toast = useToast()
const store = useStore()
const clientCrmStatuses = ref(null)
const clients = ref([])
const campaigns= ref([])
const crmStatuses = ref([])
const crmActionTypes = ref([])
const eventUsers = ref([])
const isLoading = ref(false)
const savingChanges = ref(false)
const viewMode = ref('cards')
const route = useRoute()
const actionsToAdd = ref([])
const actionsToUpdate = ref([])
const companiesToUpdate = ref([])
const companyCRMStatusesToUpdate = ref([])
const xPosition = ref(0)
const yPosition = ref(0)
const visibleHelpPopover = ref(false)

const displayActionStatus = ref('open')
const helpText = ref(null)

const clientId = ref(route.params.clientId)
const isDev = computed(() => {
    return store.state.auth.userPermissions.isDev
})

async function loadClients() {
    const result = await getClients({ token: store.state.auth.token });
    clients.value = result.clients;
}

watch(clientId, () => {
    clientCrmStatuses.value = null
    loadCompanies()
})


function findCompany(crmStatusName, companyId){
    const company = clientCrmStatuses.value[crmStatusName].companies.find((company) => company.id == companyId)
    return company
}

function dragoverHandler(ev) {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = "copy";
  }

  function dropHandler(ev) {
    ev.preventDefault();
    // console.log('target', ev.target)
    isDragging.value = false
    const targetStatus = ev.target.id
    // Get the id of the target and add the moved element to the target's DOM
    let data = ev.dataTransfer.getData("text/plain");
    data = JSON.parse(data)
    console.log('data', data)
    const company = findCompany(data.crmStatus, data.companyId)
    clientCrmStatuses.value[data.crmStatus].companies.splice(clientCrmStatuses.value[data.crmStatus].companies.findIndex((company) => company.id == data.companyId), 1)
    clientCrmStatuses.value[targetStatus].companies.push(company)
    const crmStatusId = getCRMStatusId(targetStatus)
    addOrEditCompanyCRMStatusToUpdate(company, crmStatusId)
    console.log('crm statuses to update', companyCRMStatusesToUpdate.value)
  }

function getCRMStatusId(crmStatus){
    const status = crmStatuses.value.find((status) => status.status_name == crmStatus)
    if(status){
        return status.id
    } else {
        return null
    }
}

const isDragging = ref(false)

function dragstartHandler(ev) {
    const companyId = ev.target.id
    isDragging.value = true
    ev.dataTransfer.effectAllowed = "copyMove";
    // console.log('crm-status', ev.target.getAttribute('data-value'))
    const crmStatus = ev.target.getAttribute('data-value')
    // const company = findCompany(crmStatus, companyId)
    // console.log(company)
    ev.dataTransfer.setData("text/plain", JSON.stringify({companyId: companyId, crmStatus: crmStatus}));
}


function showHelpText(){
    xPosition.value = helpText.value.getBoundingClientRect().left
    yPosition.value = helpText.value.getBoundingClientRect().bottom
    visibleHelpPopover.value = true
}
function hideHelpText(){
    visibleHelpPopover.value = false
}


const changeCount = computed(() => {
    return actionsToAdd.value.length + actionsToUpdate.value.length + companiesToUpdate.value.length + companyCRMStatusesToUpdate.value.length
})

const countChanged = ref(false)

watch(changeCount, (newValue, oldValue) => {
    // console.log(changeCount.value)
    countChanged.value = false
    if(newValue != oldValue){
        countChanged.value = true
    }
})



const showNotesOnHover = ref(false)
const showAllOpen = ref(false)
const overdueOnly = ref(false)

async function loadCompanies(){
    const token = store.state.auth.token
    if(clientId.value){
        const queryParams = `client_drf=${clientId.value}&view_mode=client`
        isLoading.value = true
        const result = await getHighPriorityCompanies(token, queryParams)
        if(result.status == 200){
            clientCrmStatuses.value = result.data.crm_statuses
            isLoading.value = false
            actionsToAdd.value = []
            actionsToUpdate.value = []
            companiesToUpdate.value = []
        }
    }
}


function registerControlPressed(e) {
    if(e.key === 'Control') {
        e.preventDefault()
        ctrlPressed.value = true
    }
}

function registerKeyPress(e) {
    if(ctrlPressed.value){
        if(e.key === '`'){
            toggleDisplayStatus()
        }
        if(e.key === 's'){
            e.preventDefault()
            saveChanges()
        }
        if(e.key === 'a'){
            e.preventDefault()
            toggleShowAllOpen()
        }
    } else {
        return true
    }
}

function registerControlReleased(e) {
    // console.log('ctrl released')
    if(e.key === 'Control') {
        e.preventDefault()
        ctrlPressed.value = false
    } else {
        return true
    }
}



async function setViewMode(view) {
    await saveChanges()
    viewMode.value = view
}

function addOrEditActionToAdd(action){
    const actionIndex = actionsToAdd.value.findIndex((actionToAdd) => actionToAdd.id == action.id)
        if(actionIndex >= 0){
            actionsToAdd.value[actionIndex] = action
        } else {
            actionsToAdd.value.push(action)
        }
        // console.log('actions to add', actionsToAdd.value)
}

function addOrEditActionToUpdate(action){
    if(actionsToAdd.value.findIndex((actionToAdd) => actionToAdd.id == action.id) >= 0){
        addOrEditActionToAdd(action)
    } else {
        const actionIndex = actionsToUpdate.value.findIndex((actionToUpdate) => actionToUpdate.id == action.id)
        if(actionIndex >= 0){
            actionsToUpdate.value[actionIndex] = action
        } else {
            actionsToUpdate.value.push(action)
        }
        // console.log('actions to update', actionsToUpdate.value)
    }
}

function addOrEditCompanyToUpdate(company){
    const companyIndex = companiesToUpdate.value.findIndex((companyToUpdate) => companyToUpdate.id == company.id)
    if(companyIndex >= 0){
        companiesToUpdate.value[companyIndex] = company
    } else {
        companiesToUpdate.value.push(company)
    }
}

function addOrEditCompanyCRMStatusToUpdate(company, crmStatusId){
    const companyIndex = companyCRMStatusesToUpdate.value.findIndex((companyToUpdate) => companyToUpdate.company_id == company.id)
    if(companyIndex >= 0){
        if(crmStatusId != company.current_status.crm_status.id){
            companyCRMStatusesToUpdate.value[companyIndex] = {company_id: company.id, crm_status_id: crmStatusId, campaign_id: company.campaign.id}
        } else {
            companyCRMStatusesToUpdate.value.splice(companyIndex, 1)
        }
    } else {
        if(crmStatusId != company.current_status.crm_status.id){
            companyCRMStatusesToUpdate.value.push({company_id: company.id, crm_status_id: crmStatusId, campaign_id: company.campaign.id})
        }
    }
}

function setHighlightColor(crmStatusName, companyId, color){
    // console.log(clientCrmStatuses.value[crmStatusName])
    const company = clientCrmStatuses.value[crmStatusName].companies.find((company) => company.id === companyId)
    company.highlight_color = color
    addOrEditCompanyToUpdate(company)
}

function markToDoDone(crmStatusName, companyId, eventId){
    const company = clientCrmStatuses.value[crmStatusName].companies.find((company) => company.id === companyId)
    const action = company.crm_actions.find((event) => event.id == eventId)
    action.company_id = company.id
    action.campaign_id = company.campaign.id
    if(company.next_open_action && company.next_open_action.id == eventId){
        // company.next_open_action.status = "Done"
        company.next_open_action.resolved_date = new Date().toISOString().split('T')[0]
    }
    // action.status = "Done"
    action.resolved_date = new Date().toISOString().split('T')[0]
    // clients.value.changeIncrement ? clients.value.changeIncrement += 1 : clients.value.changeIncrement = 0
    // actionsToUpdate.value.push(action)
    addOrEditActionToUpdate(action)
}

function addNewAction(crmStatusName, companyId, actionData){
    const company = clientCrmStatuses.value[crmStatusName].companies.find((company) => company.id === companyId)
    company.crm_actions.push(actionData)
    addOrEditActionToAdd(actionData)
    // clients.value.changeIncrement ? clients.value.changeIncrement += 1 : clients.value.changeIncrement = 0
}


const sortedClientCrmStatuses = computed(() => {
    if(clientCrmStatuses.value) {
        let clientsObj = clientCrmStatuses.value
        // clientsObj = Object.keys(clientsObj).sort().reduce(
        //     (obj, key) => { 
        //         obj[key] = clientsObj[key]; 
        //         return obj;
        //     }, 
        //     {}
        // );
        // if("Test Client" in clientsObj){
        //     const testCompanies = clientsObj["Test Client"]
        //     delete clientsObj["Test Client"]
        //     clientsObj["Test Client"] = testCompanies
        // }
        // if("changeIncrement" in clientsObj){
        //     delete clientsObj.changeIncrement
        // }
        return clientsObj
    } else {
        return null
    }
})

function sortByResolvedDate(a, b){
    if(a.most_recent_event && a.most_recent_event.resolved_date && b.most_recent_event && b.most_recent_event.resolved_date){
        return new Date(b.most_recent_event.resolved_date) - new Date(a.most_recent_event.resolved_date)
    } else if ((a.most_recent_event && a.most_recent_event.resolved_date && !b.most_recent_event)){
        return 1
    } else if ((b.most_recent_event && b.most_recent_event.resolved_date && !a.most_recent_event)){
        return -1
    } else {
        return 0
    }
}

function sortByDueDate(a, b){
    if(a.next_open_action && a.next_open_action.due_date && b.next_open_action && b.next_open_action.due_date){
        return new Date(a.next_open_action.due_date) - new Date(b.next_open_action.due_date)
    } else if ((a.next_open_action && a.next_open_action.due_date && !b.next_open_action)){
        return -1
    } else if ((b.next_open_action && b.next_open_action.due_date && !a.next_open_action)){
        return 1
    } else {
        return 0
    }
}

function sortActionsByResolvedDate(a, b){
    if(a.resolved_date && b.resolved_date){
        return new Date(b.resolved_date) - new Date(a.resolved_date)
    } else if ((a.resolved_date && !b.next_open_action)){
        return 1
    } else if ((b.resolved_date && !a.resolved_date)){
        return -1
    } else {
        return 0
    }
}


function sortActionsByDueDate(a, b){
    if(a.due_date && b.due_date){
        return new Date(a.due_date) - new Date(b.due_date)
    } else if ((a.due_date && !b.next_open_action)){
        return -1
    } else if ((b.due_date && !a.due_date)){
        return 1
    } else {
        return 0
    }
}

function getNextOpenAction(company){
    if(company.crm_actions.length > 0){
        let openActions = company.crm_actions.filter((action) => action.status == 'Open')
        if(openActions.length > 0){
            openActions = openActions.sort(sortActionsByDueDate)
            return openActions[0]
        } else {
            return null
        }
    } else {
        return null
    }
}

function sortedCompanies(companies) {
    let darkGreen = []
    let lightGreen = []
    let yellow = []
    let noColor = []
    if(displayActionStatus.value == 'done'){
        companies.sort(sortByResolvedDate)
        companies.forEach((company) => {
            // const mostRecentEvent = getMostRecentEvent(company)
            if(company.highlight_color === 'yellow'){
                yellow.push(company)
            }
            else if(company.highlight_color === 'dark_green'){
                darkGreen.push(company)
            }
            else if(company.highlight_color === 'light_green'){
                lightGreen.push(company)
            } else {
                noColor.push(company)
            }
        })
    } else if(displayActionStatus.value == 'open'){
        companies.sort(sortByDueDate)
        companies.forEach((company) => {
            if(company.highlight_color === 'yellow'){
                yellow.push(company)
            }
            else if(company.highlight_color === 'dark_green'){
                darkGreen.push(company)
            }
            else if(company.highlight_color === 'light_green'){
                lightGreen.push(company)
            } else {
                noColor.push(company)
            }
        })
    }
    const ordered = darkGreen.concat(lightGreen, yellow, noColor)
    // if(darkGreen.length > 0){
    //     console.log('ordered', ordered)
    // }
    return ordered
}

const listModeCompanies = computed(() => {
    let allCompanies = []
    if(clientCrmStatuses.value){
        const clientKeys = Object.keys(clientCrmStatuses.value)
        clientKeys.forEach((client) => {
            allCompanies = allCompanies.concat(clientCrmStatuses.value[client].companies)
        })
    }
    return sortedCompanies(allCompanies)
})



async function loadActions() {
    const token = store.state.auth.token
    const response = await getActions(token)
    // console.log('response', response.data)
    crmActionTypes.value = response.data
}
  
async function loadCampaigns(){
    const token = store.state.auth.token
    const result = await getCampaigns(token)
    campaigns.value = result
  }

    
async function loadCrmStatuses(){
    const token = store.state.auth.token
    const result = await getCrmStatuses(token)
    crmStatuses.value = result
}

async function loadCrmUsers(){
    let payload = {
        token: store.state.auth.token,
        endpoint: 'list_users',
        group: 'account_manager_group'
    }
    const result = await getUsersByGroup(payload)
    eventUsers.value = result.users
}

const openDetailsId = ref(null)


function closeDetails(){
    openDetailsId.value = null
}

const savedChanges = ref(0)

async function saveChanges(companyId=null){
    openDetailsId.value = null
    if(changeCount.value > 0){
        savingChanges.value = true
        const token = store.state.auth.token
        const actionData = {
            actions_to_update: actionsToUpdate.value,
            actions_to_add: actionsToAdd.value,
            companies_to_update: companiesToUpdate.value,
            company_crm_statuses_to_update: companyCRMStatusesToUpdate.value
        }
        const result = await bulkSaveQuickActions(actionData, token)
        if(result.status == 200){
            $toast.open({
                message: result.data,
                type: "success",
                duration: 3000
            })
            await loadCompanies()
            savingChanges.value = false
            actionsToAdd.value = []
            actionsToUpdate.value = []
            companiesToUpdate.value = []
            companyCRMStatusesToUpdate.value = []
            if(companyId){
                openDetailsId.value = companyId
            }
            clearInterval(countDownId.value)
            clearTimeout(timeoutId.value)
            autoSaveTimeRemaining.value = 0
            savedChanges.value++
        } else {
            $toast.open({
                message: "Error, could not save",
                type: "error",
                duration: 3000
            })
            savingChanges.value = false
        }
    } else {
        openDetailsId.value = companyId
    }
}

loadCompanies()
loadActions()
loadCampaigns()
loadCrmStatuses()
loadCrmUsers()
loadClients()

const ctrlPressed = ref(false)


function toggleDisplayStatus(){
    if(displayActionStatus.value === 'open'){
        displayActionStatus.value = 'done'
    } else if(displayActionStatus.value === 'done'){
        displayActionStatus.value = 'open'
    }
}

function toggleShowAllOpen(){
    if(displayActionStatus.value == 'open'){
        showAllOpen.value = !showAllOpen.value
    }
}



onMounted(() => {
    document.addEventListener("keydown", registerControlPressed, false)
    document.addEventListener("keyup", registerControlReleased, false)
    document.addEventListener("keydown", registerKeyPress, false)
})

onUnmounted(() => {
    document.removeEventListener("keydown", registerControlPressed, false)
    document.removeEventListener("keyup", registerControlReleased, false)
    document.removeEventListener("keypress", registerKeyPress, false)
})

onUpdated(() => {
    const draggables = document.getElementsByClassName("draggableRow")
    // console.log('draggables', draggables)@
    for(let i = 0; i < draggables.length; i++){
        // console.log(draggables[i])
        draggables[i].addEventListener("dragstart", dragstartHandler)
    }
})


const autoSaveWaitTime = ref(300000)
const autoSaveTimeRemaining = ref(0)

const timeLeft = computed(() => {
    if(autoSaveTimeRemaining.value > 60000){
        return Math.ceil(autoSaveTimeRemaining.value / 60000) + ' min.'
    } else {
        return autoSaveTimeRemaining.value / 1000 + ' sec.'
    }
})



const timeoutId = ref(null)
const countDownId = ref(null)
watch(autoSaveTimeRemaining, () => {
    if(autoSaveTimeRemaining.value == 0 && countDownId.value){
        clearInterval(countDownId.value)
    }
})

watch(changeCount, () => {
    if(changeCount.value && changeCount.value > 0){
        if(timeoutId.value){
            clearTimeout(timeoutId.value)
            clearInterval(countDownId.value)
        }
        window.onbeforeunload = function(e) {
          e.returnValue = "Are you sure you want to leave? You have unsaved changes."
          return "Are you sure you want to leave? You have unsaved changes."
        };
        autoSaveTimeRemaining.value = autoSaveWaitTime.value
        timeoutId.value = setTimeout(() => {
            saveChanges()
            timeoutId.value = null
        }, autoSaveWaitTime.value)
        countDownId.value = setInterval(() => {
            autoSaveTimeRemaining.value -= 1000
        }, 1000)
    } else {
        window.onbeforeunload = null
        if(changeCount.value == 0){
            // console.log('clear the interval')
            clearInterval(countDownId.value)
        }
    }
})

</script>