<template>
    <div class="bg-white rounded-md">
        <div
            :class="filterSetClass"
        >
            <FilterCompact
                v-for="(filter, key, index) in filters"
                :key="key"
                :active="isActive(filter)"
                :index="index"
                :starting-index="0"
                :ending-index="Object.keys(filters).length - 1"
                @click="setFilterOptions(key)"
            >
                <template v-slot:label>{{ filter.label }}</template>
                <template v-slot:widget>
                    <ComboBox v-if="filter.widget == 'combo-box' && filter.multiSelect"
                        v-model="filter.value"
                        :options="filter.options"
                        @update:modelValue="(event) => setFilter(`${key}`, event)"
                        :multiple="filter.multiSelect"
                    />
                    <ComboBox v-else-if="filter.widget == 'combo-box'"
                        v-model="filter.value"
                        :options="filter.options"
                        :multiple="filter.multiSelect"
                    />
                    <ListBox v-else-if="filter.widget == 'list-box' && filter.multiSelect"
                        v-model="filter.value"
                        :options="filter.options"
                        @update:modelValue="(event) => setFilter(`${key}`, event)"
                        :multiple="filter.multiSelect"
                    />
                    <ListBox v-else-if="filter.widget == 'list-box'"
                        v-model="filter.value"
                        :options="filter.options"
                        :multiple="filter.multiSelect"
                    />
                    <input v-else-if="filter.widget == 'input'"
                        class="w-full h-full px-2"
                        v-model="filter.value"
                        :type="filter.type"
                    />
                </template>
            </FilterCompact>
        </div>
        <div v-if="activeFilters.length > 0" class="flex flex-row space-x-2 items-center p-1 rounded-b-md border-gray-300">
            <button @click="resetFilters" class="text-xs p-1 rounded-md hover:bg-accent-primary hover:text-white">
                Reset
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, reactive, defineProps, defineEmits, computed } from 'vue';
import {useStore} from 'vuex'
import FilterCompact from '../base-components/FilterCompact.vue';
import ComboBox from '../base-components/ComboBox.vue';
import ListBox from '../base-components/ListBox.vue';

const store = useStore()

const props = defineProps(['filters'])

const emit = defineEmits([
    'change-filters',
    'change-ordering',
    'change-active-filters',
    'set-filter-options',
    'hide-active-filters',
    'clear-select-all',
])

const filters = reactive(props.filters)

function emitFiltersChanged(){
    emit("change-filters", filters)
}

function emitClearSelectAll(){
    emit('clear-select-all')
}

function emitActiveFiltersChanged(){
    // console.log('emitActiveFiltersChanged')
    emit("change-active-filters", activeFilters.value)
}

function setFilterOptions(filterName) {
    emit('set-filter-options', filterName)
}

function setFilter(filterName, target) {
    // console.log('setting filter', target)
    if(target.includes("clear")){
        filters[filterName].value = []
    }
}

function setFilterDefaults() {
    for(const filter in filters){
        filters[filter].value = filters[filter].defaultValue
    }
}

function resetFilters() {
    setFilterDefaults()
    emit('hide-active-filters')
}

function isActive(filter) {
    if(!filter.value){
        return false
    } else if(Array.isArray(filter.value) && filter.value.length > 0){
        return true
    } else if(!Array.isArray(filter.value) && filter.value){
        return true
    }
}

const filterValues = computed(() => {
    let values = []
    for(const filter in filters) {
        values.push(filters[filter].value)
    }
    return values
})

const filterSetClass = computed(() => {
    const filtersLength = Object.keys(filters).length
    switch (filtersLength) {
        case 0:
            return 'grid grid-cols-1';   
        case 1:
            return 'grid grid-cols-1';
        case 2:
            return 'grid grid-cols-2 sm:grid-cols-2';
        case 3:
            return 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3';
        case 4:
            return 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4';
        case 5:
            return 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5';
        case 6:
            return 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5';
        case 7:
            return 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-5';
        case 8:
            return 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-5';
        case 9:
            return 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-5';
        default:
            return 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-5';
    }
})

const activeFilters = computed(() => {
    let activeFilterArr = []
    for(const filter in filters) {
        if(Array.isArray(filters[filter].value) && filters[filter].value.length > 0){
            console.log('found array')
            activeFilterArr.push({filter: filter, name: filters[filter].label, value: filters[filter].options.filter(option => filters[filter].value.includes(option.value)).map(option => option.label)})
        } else if (!Array.isArray(filters[filter].value) && filters[filter].value && filters[filter].options) {
            activeFilterArr.push({filter: filter, name: filters[filter].label, value: filters[filter].options.filter(option => filters[filter].value == (option.value)).map(option => option.label)[0]})
        } else if (!Array.isArray(filters[filter].value) && filters[filter].value) {
            activeFilterArr.push({filter: filter, name: filters[filter].label, value: filters[filter].value})
        }
    }
    return activeFilterArr
})

watch(filterValues, () => {
    emitFiltersChanged()
    emitClearSelectAll()
}, {deep: true})

watch(activeFilters, () => {
    emitActiveFiltersChanged()
})

setFilterDefaults()

</script>