<template>
    <div class="w-full h-full">
        <div class="w-full h-full">
            <div class="">
                <base-side-bar-tab
                    class="text-dark-primary"
                    :tabList="tabList"
                    :selectedColor="selectedColor"
                    :notSelectedColor="notSelectedColor"
                    :hoverColor="hoverColor"
                    @set-page="setTab"
                >
                </base-side-bar-tab>
            </div>
            <div class="h-full">
                <div v-show="displayAddCompanies" class="h-full">
                    <AddMultipleCompanies
                    class="text-dark-primary h-full"
                    :containerType="'rightSideBar'"
                    @refetch-companies="fetchNewCompanies"
                    />
                </div>
                <div v-show="displayAddDetailedCompany" class="h-full">
                    <CreateCompany
                        class="py-2 px-2 h-full"
                        :containerType="'rightSideBar'"
                        :campaigns="campaigns"
                        @refetch-companies="fetchNewCompanies"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import CreateCompany from '../CreateCompany.vue';
import AddMultipleCompanies from '../AddMultipleCompanies.vue';
import { getActiveCampaigns } from '@/services/Campaign';

const store = useStore()

const tabList = ref([
    {tabName: 'Add Companies', isSelected: true},
    {tabName: 'Add Detailed Company', isSelected: false},

])
const selectedColor = 'white';
const notSelectedColor = 'gray-200';
const hoverColor = 'gray-50';

const campaigns = ref([])

function setTab(tabName) {
    for(let tab of tabList.value) {
        if(tabName == tab.tabName) {
            console.log(tabName)
            tab.isSelected = true
        } else {
            tab.isSelected = false
        }
    }
}

async function loadCampaigns(){
    const token = store.state.auth.token
    const result = await getActiveCampaigns(token)
    campaigns.value = result
  }

const displayAddCompanies = computed(() => {
    let addCompaniesTab = tabList.value.find(tab => tab.tabName === 'Add Companies')
    return addCompaniesTab ? addCompaniesTab.isSelected : null
})

const displayAddDetailedCompany = computed(() => {
    let addDetailedCompanyTab = tabList.value.find(tab => tab.tabName === 'Add Detailed Company')
    return addDetailedCompanyTab ? addDetailedCompanyTab.isSelected : null

})

function fetchNewCompanies () {
    store.dispatch('company/setRefetchCompaniesTrue')
}

loadCampaigns()

</script>