<template>
    <div>
        <select name="" id="" class="p-1 border border-black w-fit rounded" v-model="crmNextAction" @change="openForm">
            <option v-for="option in nextActionOptions" :key="option.value" :value="option.value" >{{ option.label }}</option>
        </select>
        <base-modal-small v-if="isCRMEventFormVisible" @close="closeCRMEventFormWithoutSaving">
          <template #header>
            <h2 class="text-lg">{{ company.company_name }} {{ company.id }} - Change CRM Next Action</h2>
          </template>
          <template #body>
            <div class="w-full p-1 h-[90%] overflow-auto">
                <div class="w-full p-1 flex flex-col items-center mb-3">
                    <div>
                        Was the previous action "{{ previousAction }}" completed?
                    </div>
                </div>
                <div class="w-full grid grid-cols-2 ">
                    <div class="px-1 py-4 flex flex-col border-r h-full">
                            <form class="w-full flex flex-col items-start space-y-2 px-2" @submit.prevent="saveWithEvent">
                                <div class="w-full flex justify-center mb-3">
                                    If so, add a CRM Detail here documenting the action for reporting purposes.
                                </div>
                                <div class="w-full flex items-center">
                                    <label class="w-1/4 flex items-center p-1 bg-gray-100 rounded-l h-full border border-r-0 border-gray-300" for="" >
                                        Detail Name:
                                    </label>
                                    <select required v-model="eventFormData.action_id" class="  text-center p-1 border h-full w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" name="" id="">
                                        <!-- <option value="">------</option> -->
                                        <option v-for="event in crmActionTypes" :key=event.id :value="event.id">{{ event.action_name }}</option>
                                    </select>
                                </div>
                                <div class="w-full flex items-center">
                                    <label class="w-1/4 flex items-center p-1 bg-gray-100 rounded-l h-full border border-r-0 border-gray-300" for="" >
                                        Resolved Date:
                                    </label>
                                    <input required v-model="eventFormData.resolved_date" id="crmNewActionDueDate" type="date"
                                        class="  text-center p-1 border h-full w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    >
                                </div>
                                <div class="w-full flex items-center">
                                    <label class="w-1/4 flex items-center p-1 bg-gray-100 rounded-l h-full border border-r-0 border-gray-300" for="" >
                                        User:
                                    </label>
                                    <select v-model="eventFormData.user_id" id="crmNewActionUser"
                                        class=" p-1 border text-center w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        >
                                        <option value="">---User---</option>
                                        <!-- <option value=3>Scott Shedd</option> -->
                                        <option v-for="user in crmUsers" :key="user.id" :value="user.id">{{ user.first_name}} {{ user.last_name }}</option>
                                    </select>
                                </div>
                                <div class="w-full flex items-center">
                                    <textarea v-model="eventFormData.action_notes" id="notesInput" type="text" rows="3" placeholder="Notes"
                                        class=" col-span-4 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    ></textarea>
                                </div>
                                <div class="w-full flex justify-center items-center space-x-4">
                                    <base-button-affirmative>
                                        Save Next Action and Create Detail
                                    </base-button-affirmative>
                                </div>
                            </form>
                        </div>
                        <div class="px-1 py-4 flex flex-col border-l h-full">
                            <div class="flex h-full flex-col justify-between items-center">
                                <div class="flex justify-center px-2">
                                    If the previous action was not completed, or if it was selected as a mistake, save the new CRM Next Action without adding a CRM Detail here.
                                </div>
                                <div class="w-full flex justify-center align-bottom">
                                    <base-button-affirmative @click="saveWithoutEvent">
                                    Save Next Action Without Detail
                                    </base-button-affirmative>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
          </template>
        </base-modal-small>
    </div>
</template>

<script>
    export default {
        name: "crm-status-cell"
    }
</script>

<script setup>
import { defineProps, defineEmits, watch, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { updateCrmNextAction, updateCrmNextActionAndAddEvent } from '@/services/Company';
import { useToast } from 'vue-toast-notification';

const store = useStore()
const $toast = useToast()

const props = defineProps({
    company: Object,
    crmActionTypes: Array,
    crmUsers: Array
})

const isCRMEventFormVisible = ref(false)
const actionCompleted = ref(true)

function closeCRMEventForm(){
    isCRMEventFormVisible.value = false
}

function closeCRMEventFormWithoutSaving(){
    crmNextAction.value = previousAction.value
    isCRMEventFormVisible.value = false
}

function getEventIdFromNextAction(crmNextAction){
    if(crmNextAction == "Send Initial Email"){
        return 2
    }
    if(crmNextAction == "Send Another Email"){
        return 1
    }
    if(crmNextAction == "Call"){
        return 9
    }
    if(crmNextAction == "Send 2nd Email"){
        return 3
    }
    if(crmNextAction == "Send 3rd Email"){
        return 4
    }
    if(crmNextAction == "Send 4th Email"){
        return 21
    }
    if(crmNextAction == "Cold Call 1"){
        return 5
    }
    if(crmNextAction == "Cold Call 2"){
        return 5
    }
    if(crmNextAction == "Call Again"){
        return 5
    }
    if(crmNextAction == "Not Now"){
        return 25
    }
    if(crmNextAction == "Submit IOI"){
        return 15
    }
    if(crmNextAction == "Submit LOI"){
        return 16
    }
}

const previousAction = ref(props.company.crm_next_action)

const crmNextAction = ref(props.company.crm_next_action)

const eventFormData = reactive({
    action_id: null,
    user_id: store.state.auth.user.id,
    created_by_id: store.state.auth.user.id,
    status: "Done",
    resolved_date: new Date().toISOString().split('T')[0],
    action_notes: "",
    company_id: props.company.id,
    campaign_id: props.company.campaign ? props.company.campaign.id : null
})


const nextActionOptions = ref([
    {value: 'None', label: 'None'},
    {value: 'Determine Next Action', label: 'Determine Next Action'},
    {value: 'Scott to Follow-Up', label: 'Scott to Follow-Up'},
    {value: 'Send Initial Email', label:'Send Initial Email' },
    {value: 'Send Another Email', label: 'Send Another Email'},
    {value: 'Send Email on Acquisition', label: 'Send Email on Acquisition'},
    {value: 'Research Correct Email', label: 'Research Correct Email'},
    {value: 'Call', label: 'Call'},
    {value: 'Schedule Meeting', label: 'Schedule Meeting'},
    {value: 'Wait on Response', label: 'Wait on Response'},
    {value: 'Wait for Client Feedback', label: 'Wait for Client Feedback'},
    {value: 'Meet', label: 'Meet'},
    {value: 'Not Now', label: 'Not Now'},
    {value: 'Leave Alone', label: 'Leave Alone'},
    {value: 'Referral Source to Reach Out', label: 'Referral Source to Reach Out'},
    {value: "Send 2nd Email", label: "Send 2nd Email"},
    {value: "Send 3rd Email", label: "Send 3rd Email"},
    {value: "Send 4th Email", label: "Send 4th Email"},
    {value: "Cold Call 1", label: "Cold Call 1"},
    {value: "Cold Call 2", label: "Cold Call 2"},
    {value: "Call again", label: "Call again"},
    {value: "Periodic Check In", label: "Periodic Check In"},
    {value: "Submit IOI", label: "Submit IOI"},
    {value: "Submit LOI", label: "Submit LOI"},
])

watch(props, ()=>{
    previousAction.value = props.company.crm_next_action
    crmNextAction.value = props.company.crm_next_action
    actionCompleted.value = true
})

const emit = defineEmits(['refetch-companies'])

function openForm() {
    if(previousAction.value && props.company.campaign){
        isCRMEventFormVisible.value = true
        eventFormData.action_id = getEventIdFromNextAction(previousAction.value)
    } else {
        saveWithoutEvent()
    }
}

async function saveWithEvent() {
    const token = store.state.auth.token
    const data = JSON.stringify({
        crm_next_action: crmNextAction.value,
        event_data: eventFormData
    })
    const result = await updateCrmNextActionAndAddEvent(props.company.id, data, token)
    if(result.status == 200){
        $toast.open({
            message: 'Updated CRM Next Action and added event',
            type: 'success',
            duration: 5000
        })
        emit('refetch-companies')
        closeCRMEventForm()
    } else {
        $toast.open({
            message: 'Error, could not update CRM Next Action and add event',
            type: 'error',
            duration: 3000
        })
    }
}

async function saveWithoutEvent() {
    const token = store.state.auth.token
    const data = JSON.stringify({
        crm_next_action: crmNextAction.value
    })
    const result = await updateCrmNextAction(props.company.id, data, token)
    if(result.status == 200){
        emit('refetch-companies')
        closeCRMEventForm()
    } else {
        $toast.open({
            message: 'Error, could not update CRM Next Action and add event',
            type: 'error',
            duration: 3000
        })
    }
}

</script>

