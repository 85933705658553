<template>
    <div class="fixed rounded-md z-30 w-fit text-left" :style="popoverPosition">
        <div class="bg-white w-full block rounded-lg shadow-md">
            <slot name="content"></slot>
        </div>
    </div>
</template>
<script setup>
import { defineProps, computed } from "vue"; 

const props = defineProps({
    xPosition: Number,
    yPosition: Number,
    width: Number,
})

const popoverPosition = computed(function () {
    // console.log('popoverwidth', props.width)
    return `left: ${props.xPosition}px; top: ${props.yPosition}px; width: ${props.width}px`
})

</script>