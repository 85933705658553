<template>
    <div class="w-full h-full justify-between">
        <!-- <div class="flex space-x-5 w-full justify-start items-center pb-2">
            <div class="flex justify-start items-center w-fit whitespace-nowrap">
                <div class="mr-1 w-5 h-5 rounded bg-emerald-200 border border-gray-300">

                </div>
                <div>
                    Existing Event
                </div>
            </div>
            <div class="flex justify-start items-center w-fit whitespace-nowrap">
                <div class="mr-1 w-5 h-5 rounded bg-sky-200 border border-gray-300">

                </div>
                <div>
                    Valid Row
                </div>
            </div>
            <div class="flex justify-start items-center w-fit whitespace-nowrap">
                <div class="mr-1 w-5 h-5 rounded bg-amber-100 border border-gray-300">

                </div>
                <div>
                    Empty Row
                </div>
            </div>
            <div class="flex justify-start items-center w-fit whitespace-nowrap">
                <div class="mr-1 w-5 h-5 rounded bg-red-400 border border-gray-300">

                </div>
                <div>
                    Invalid Row
                </div>
            </div>
        </div> -->
        <div class='w-full flex overflow-y-scroll'>
            <!-- <div class="w-12  px-2 py-0.5 flex justify-end items-center">
            </div> -->
            <div class="w-full grid grid-cols-12 border-gray-300 rounded-t">
                <div class="w-full bg-gray-50 border-y border-r col-span-2 border-l rounded-tl border-gray-300 px-3 py-1.5 font-semibold">
                    Detail Name*
                </div>
                <div class="w-full bg-gray-50 border-y border-r  col-span-2 border-gray-300 px-3 py-1.5 font-semibold">
                    Due Date
                </div>
                <div class="w-full bg-gray-50 border-y border-r  col-span-2 border-gray-300 px-3 py-1.5 font-semibold">
                    Resolved Date
                </div>
                <div class="w-full bg-gray-50 border-y border-r  col-span-1 border-gray-300 px-3 py-1.5 font-semibold">
                    User
                </div>
                <div class="w-full bg-gray-50 border-y border-r text-center  col-span-1 border-gray-300 px-3 py-1.5 font-semibold">
                    Status
                </div>
                <div class="w-full bg-gray-50 border-y border-r  col-span-3 border-gray-300 px-3 py-1.5 font-semibold">
                    Notes
                </div>
                <div class="w-full bg-gray-50 border-y  border-r col-span-1 rounded-tr border-gray-300 px-3 py-1.5 font-semibold">
                    Corresp.
                </div>
            </div>
            <div class="w-12  px-3 py-0.5 flex justify-end items-center">
                <button class="bg-dark-secondary text-white hover:bg-dark-primary w-5 h-5 rounded-full"
                    @click="addEventRow" 
                >
                    +
                </button>
            </div>
        </div>
        <div class=" w-full h-full overflow-y-scroll  max-h-[calc(100%-16rem)]">
                <TransitionGroup name="events">
                    <CRMEventRow 
                        v-for="crmEvent in newDetails"
                        :key="crmEvent.id"
                        :crmEvent="crmEvent"
                        :crmEventTypeOptions="crmEventTypeOptions"
                        :userOptions="userOptions"
                        @update-event-row="updateEventRow"
                        @remove-event-row="removeEventRow"
                    />
                    <CRMEventRow 
                        v-for="crmEvent in orderedOpenDetails"
                        :key="crmEvent.id"
                        :crmEvent="crmEvent"
                        :crmEventTypeOptions="crmEventTypeOptions"
                        :userOptions="userOptions"
                        @update-event-row="updateEventRow"
                        @remove-event-row="removeEventRow"
                    />
                    <CRMEventRow 
                        v-for="crmEvent in orderedEvents"
                        :key="crmEvent.id"
                        :crmEvent="crmEvent"
                        :crmEventTypeOptions="crmEventTypeOptions"
                        :userOptions="userOptions"
                        @update-event-row="updateEventRow"
                        @remove-event-row="removeEventRow"
                    />
                </TransitionGroup>
            <div v-if="showAllStatuses" class="h-72 w-full">

            </div>
        </div>
        <div class="flex w-full justify-center items-center absolute bottom-0 space-x-2 bg-white border-t border-gray-300 h-12">
            <button class="bg-accent-primary text-white hover:bg-accent-secondary px-2 py-1 rounded" @click="updateCRMEvents">Save</button>
            <button class="bg-dark-secondary text-white hover:bg-dark-primary px-2 py-1 rounded" @click="closeCRMEditForm">Cancel</button>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, watch } from 'vue';
import CRMEventRow from './CRMEventRow.vue';



const props = defineProps(['company', 'emptyCRMActions', 'crmEventTypeOptions', 'userOptions', 'showAllStatuses', 'companyCampaignId'])

const emit = defineEmits(['update-crm-events', 'close-crm-edit-form'])


const crmEvents = ref(props.company ? props.company.crm_actions.filter(action => action.campaign.id == props.companyCampaignId).map(action => ({...action, type: 1, action_order: action.action.ordinal})).sort((a, b) => { return a.type - b.type || new Date(b.resolved_date) - new Date(a.resolved_date) || a.action_order - b.action_order }) : [])

watch(() => crmEvents.value, () => {
    
}, {deep: true})

function sortByDueDate(a, b){
    if(a.due_date && b.due_date){
        return new Date(a.due_date) - new Date(b.due_date)
    } else if ((a.due_date && !b.due_date)){
        return -1
    } else if ((b.due_date && !a.due_date)){
        return 1
    } else if(a.action && b.action){
        return a.action.action_name < b.action.action_name ? -1 : 0
    } else {
        return 0
    }
}


const orderedEvents = ref([])
const orderedOpenDetails = ref([])
const newDetails = ref([])

function orderEvents(){
    orderedEvents.value = crmEvents.value.filter((detail) => detail.resolved_date && detail.type == 1).sort((a, b) => {
        return a.type - b.type
        // return a.type - b.type || new Date(b.resolved_date) - new Date(a.resolved_date) || a.action_order - b.action_order
    })
    orderedOpenDetails.value = crmEvents.value.filter((detail) => !detail.resolved_date && detail.type == 1).sort((a, b) => {
        return a.type - b.type || sortByDueDate(a, b)
    })
    newDetails.value = crmEvents.value.filter((detail) => detail.type != 1).sort((a, b) => {
        return a.type - b.type || sortByDueDate(a, b)
    })
    // console.log('ordered open details', orderedOpenDetails.value)
}

orderEvents()

// const orderedEvents = computed(() => {
//     // return props.company.crm_actions.map(action => ({...action, type: 1, action_order: action.action.ordinal}))
//     // .concat(props.emptyCRMActions.toSorted((a, b) => {
//     //     return a.type - b.type || a.action_order - b.action_order
//     // }))
//     return crmEvents.value.toSorted((a, b) => {
//         return a.type - b.type
//         // return a.type - b.type || new Date(b.resolved_date) - new Date(a.resolved_date) || a.action_order - b.action_order
//     })
// })

const campaignId = computed(() => {
    return props.company.campaign ? props.company.campaign.id : null
})


function updateCRMEvents() {
    emit('update-crm-events', crmEvents.value)
    // emit('close-crm-edit-form')
}

function closeCRMEditForm() {
    emit('close-crm-edit-form')
}


function addEventRow() {
    const newEventRow = {
        id: Math.floor(Math.random() * -100000),
        // action_name: null,
        action_id: null,
        resolved_date: null,
        due_date: null,
        status: null,
        user_id: null,
        action_notes: null,
        action_details: null,
        campaign_id: campaignId.value,
        type: 0,
        action_order: 22,
    }
    crmEvents.value.splice(0, 0, newEventRow)
    orderEvents()
    return newEventRow
}

function removeEventRow(eventId) {
    // console.log('removeRow', eventId)
    let rowIndex = crmEvents.value.findIndex((row) => row.id == eventId)
    crmEvents.value.splice(rowIndex, 1)
    orderEvents()
}

function updateEventRow(eventRow) {
    // console.log('CRMViewPort: updateRow')
    // console.log(eventRow)
    let existingRow = crmEvents.value.find((row) => row.id == eventRow.id)
    // existingRow.action_name = eventRow.action_name
    // console.log('eventRow')
    // console.log(eventRow)
    existingRow.action_id = eventRow.action_id
    existingRow.resolved_date = eventRow.resolved_date 
    existingRow.due_date = eventRow.due_date 
    // existingRow.status = eventRow.status 
    existingRow.user_id = eventRow.user_id
    existingRow.user = null
    existingRow.action_notes = eventRow.action_notes 
    existingRow.action_details = eventRow.action_details 
    existingRow.action_order = eventRow.action_order
    existingRow.type = eventRow.type
    existingRow.campaign_id = campaignId.value
}

</script>

<style scoped>
.events-move,
.events-enter-active {
    transition: all 0.1s ease;
}
.events-leave-active {
  transition: all 0.08s ease;
}
.events-enter-from,
.events-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}

.events-leave-active {
    position: relative;
    /* position: relative; */
}
</style>