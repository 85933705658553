<template>
    <div class="h-full w-full flex flex-col  print:hidden bg-gray-200">
        <div class="flex w-full h-14 border-b border-x justify-start items-center border-gray-300 sticky z-20 top-0 bg-gray-50 px-4">
            <!-- <div @click="hideReport" class="hover:bg-gray-200 rounded-sm h-full px-0.5 md:pr-3 flex justify-center items-center cursor-pointer print:hidden"> -->
            <div @click="hideReport" @mouseover="highlightBackButton" @mouseout="unHighlightBackButton" class="h-full flex justify-center items-center cursor-pointer print:hidden">
                <div class="h-full flex flex-col justify-end">
                    <div class="h-full flex items-center pt-1">
                        <ChevronLeftSVG 
                            :fill-color="backButtonFill"
                        />
                    </div>
                    <div v-if="backButtonHighlighted" class="h-1 bg-gray-300"></div>
                    <div v-else class="h-1 bg-gray-50"></div>
                </div>
            </div>
            <!-- <div @click="showPrintPreview" class="hover:bg-gray-200 rounded-sm h-full flex px-0.5 md:px-3 justify-center items-center cursor-pointer print:hidden">
                <PrintSVG
                    fill-color="#25274D"
                />
            </div> -->
            <div class="flex items-center w-fit space-x-3 px-0.5 md:px-3">
                <div  class="text-lg flex items-center font-semibold tracking-widest whitespace-nowrap text-gray-800">
                    {{ clientName }}
                </div>
            </div>
            <div class="flex justify-start items-end h-full w-full overflow-x-scroll">
                <TabBar 
                :tab-list="tabList"
                :first-tab-selected="firstTabSelected"
                :last-tab-selected="lastTabSelected"
                @set-page="setPage"
            />
            </div>
            <div class="flex items-center w-fit text-sm tracking-wide pl-3 whitespace-nowrap pt-0.5 space-x-3 text-gray-600">
                <div @click="showPrintPreview" class="hover:underline underline-offset-4 hover:text-dark-secondary cursor-pointer">
                    Print Preview
                </div>
                <!-- <div class="hover:underline underline-offset-4 hover:text-dark-secondary cursor-pointer">
                    Download CSV
                </div> -->
            </div>
            <div class="flex items-center w-fit justify-end pl-0.5 xl:pl-3  space-x-3 print:hidden">
                <div class="text-base w-fit hidden 2xl:flex items-center  tracking-wider text-gray-600 pt-0.5">
                    Campaigns:
                </div>
                <div class="flex items-center w-[200px] md:w-[300px]  xl:w-[400px] pt-[30px] z-50">
                    <type-ahead-multiselect
                        placeholder="Campaign(s)"
                        nullLabel="No Results"
                        :default-value="selectedCampaigns"
                        :options="campaignOptions"
                        @update-value="updateCampaigns"
                    ></type-ahead-multiselect>
                </div>
            </div>
        </div>
        <div class="w-full h-[calc(100vh-3.5rem)]">
            <CRMTargetList
                v-show="tabList.targetList.isSelected" 
                :selectedCompanies="selectedCompanies"  
                :campaigns="campaigns"
                :campaignOptions="campaignOptions"
                :allCRMActions="allCRMActions"
                :allCRMStatuses="allCRMStatuses"
                :companyIdsToUpdate="companyIdsToUpdate"
                @set-reported-event="setCompanyReportedEvent"
                @save-target-list="saveTargetList"
                @refetch="refetch"
                @rehydrate="rehydrateCompanies"
                @update-order-by="orderSelectedCompanies"
            />
            <CRMProgressUpdate
                v-show="tabList.progressUpdate.isSelected" 
                :selectedCompanies="selectedCompanies"  
                :campaigns="campaigns"
            />
            <CRMOverview 
                v-show="tabList.overview.isSelected"
                :clientName="clientName"
                :campaigns="campaigns"
                :selectedCompanies="selectedCompanies"
                :contactedCompanies="contactedCompanies"
                :notContactedCompanies="notContactedCompanies"
                :respondedCompanies="respondedCompanies"
                :noResponseCompanies="noResponseCompanies"
            />
            <!-- <CRMActivities 
                v-else-if="tabList.crmActivities.isSelected"
                :selectedCRMActions="selectedCRMActions"
                :numCRMActions="numCRMActions"
            /> -->
            <!-- <CRMCompanyHistory v-else-if="tabList.companyHistory.isSelected" /> -->

        </div>
    </div>
    <Teleport to="body">
        <div v-show="printPreviewIsVisible" class="modal h-full fixed overflow-y-scroll bg-gray-200 flex justify-center w-[1632px]">
            <div class="flex fixed print:hidden bg-gray-50 shadow-md h-14 border-b justify-center items-center border-gray-300 z-10 w-full">
                <div @click="hidePrintPreview" class="hover:bg-gray-200 rounded-sm h-full px-0.5 md:px-3 flex justify-center items-center cursor-pointer print:hidden">
                    <ChevronLeftSVG 
                        fill-color="#25274D"
                    />
                </div>
                <div @click="handlePrint" class="hover:bg-gray-200 rounded-sm h-full flex px-0.5 md:px-3 justify-center items-center cursor-pointer print:hidden">
                    <PrintSVG
                        fill-color="#25274D"
                    />
                </div>
            </div>
            <div class=" bg-gray-200 relative flex justify-center w-[1632px] top-14">
                <div ref="componentRef" class="bg-white w-full flex flex-col h-full">
                    <div class="flex items-center justify-center h-[2100px] break-after-page p-24 w-full bg-white">
                        <div class="flex flex-col items-center justify-center h-full w-full px-12 bg-dark-secondary">
                            <div class="text-5xl flex items-center justify-center font-extralight tracking-widest whitespace-nowrap text-white py-4 w-1/2  border-b border-gray-50">
                                {{ clientName }}
                            </div>
                            <div class="text-2xl flex justify-center items-center w-full tracking-wider whitespace-nowrap font-medium text-gray-50 py-4">
                                Outreach Overview {{ today }}
                            </div>
                            <div class="w-[300px] h-[360px] absolute bottom-20 flex justify-center">
                                <SheddCapitalSVG 
                                    fill-color="white"
                                />
                            </div>
                        </div>
       
                    </div>
                    <div class="w-full flex flex-col h-[2100px] break-after-page p-24 bg-white">
                        <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                            <div class='w-full flex space-x-2 justify-start'>
                                <div>All Campaigns</div>
                                <div class="text-base 2xl:flex items-center justify-center tracking-wider whitespace-nowrap text-gray-600 pt-0.5 pb-6">
                                    {{ numCompanies }} {{ companyLabel }} in {{ numCampaigns }} {{ campaignLabel }}
                                </div>
                            </div>
                            <div class="w-[200px] flex justify-end">
                                <SheddCapitalSVG 
                                    fill-color="#1F2937"
                                />
                            </div>
                        </div>
                        <CRMOverview
                            class="h-[1800px]"
                            :printView="true"
                            :clientName="clientName"
                            :campaigns="campaigns"
                            :selectedCompanies="selectedCompanies"
                            :contactedCompanies="contactedCompanies"
                            :notContactedCompanies="notContactedCompanies"
                            :respondedCompanies="respondedCompanies"
                            :noResponseCompanies="noResponseCompanies"
                        />
                    </div>
                    <div 
                        v-for="(companyBatch, index) in selectedCompaniesBatches"
                        :key="index"
                        class="w-full flex flex-col h-[2100px] break-after-page p-24 bg-white">
                        <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                            <div class='w-full flex space-x-2 justify-start'>
                                <div>All Campaigns</div>
                                <div class="text-base 2xl:flex items-center justify-center tracking-wider whitespace-nowrap text-gray-600 pt-0.5 pb-6">
                                    {{ numCompanies }} {{ companyLabel }} in {{ numCampaigns }} {{ campaignLabel }}
                                </div>
                            </div>
                            <div class="w-[200px] flex justify-end">
                                <SheddCapitalSVG 
                                    fill-color="#1F2937"
                                />
                            </div>
                        </div>
                        <div class="w-full overflow-clip h-[1800px]">
                            <CRMTargetList
                                :print-view="true"
                                :selectedCompanies="companyBatch"  
                                :campaigns="campaigns"
                                :campaignOptions="campaignOptions"
                                :allCRMActions="allCRMActions"
                                :allCRMStatuses="allCRMStatuses"
                                :companyIdsToUpdate="companyIdsToUpdate"
                                @set-reported-event="setCompanyReportedEvent"
                                @save-target-list="saveTargetList"
                                @refetch="refetch"
                            />
                        </div>
                    </div>
                    <!-- <div
                        v-for="campaign in campaigns"
                        :key="campaign"
                        class="w-full"
                    >
                        <div class="w-full bg-white flex flex-col h-[2100px] break-after-page p-24">
                            <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                                <div class='w-full flex justify-start'>
                                    Campaign: {{ getCampaignName(campaign) }}
                                </div>
                                <div class="w-[200px] flex justify-end">
                                    <SheddCapitalSVG 
                                        fill-color="#1F2937"
                                    />
                                </div>
                            </div>  
                            <CRMOverview
                                class="h-[1800px]"
                                :printView="true"
                                :clientName="clientName"
                                :campaigns="campaigns"
                                :selectedCompanies="companies.filter((company) => company.campaign.id == campaign)"
                                :contactedCompanies="contactedCompanies.filter((company) => company.campaign.id == campaign)"
                                :notContactedCompanies="notContactedCompanies.filter((company) => company.campaign.id == campaign)"
                                :respondedCompanies="respondedCompanies.filter((company) => company.campaign.id == campaign)"
                                :noResponseCompanies="noResponseCompanies.filter((company) => company.campaign.id == campaign)"
                            />
                        </div>
                        <div class="w-full bg-white flex flex-col h-[2100px] break-after-page p-24">
                            <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                                <div class='w-full flex justify-start'>
                                    Campaign: {{ getCampaignName(campaign) }}
                                </div>
                                <div class="w-[200px] flex justify-end">
                                    <SheddCapitalSVG 
                                        fill-color="#1F2937"
                                    />
                                </div>
                            </div>
                        </div>
                        <div 
                            v-for="(companyBatch, index) in selectedCompaniesBatches"
                            :key="index"
                            class=" bg-white flex flex-col h-[2100px] break-after-page p-24 items-center w-full"
                            >
                            <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                                <div class='w-full flex justify-start'>
                                    Campaign: {{ getCampaignName(campaign) }}
                                </div>
                                <div class="w-[200px] flex justify-end">
                                    <SheddCapitalSVG 
                                        fill-color="#1F2937"
                                    />
                                </div>
                            </div >
                            <div class="w-full overflow-clip h-[1800px]">
                                <CRMTargetList
                                :print-view="true"
                                :selectedCompanies="companyBatch"  
                                :campaigns="campaigns"
                            />
                            </div>

                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, computed, watch, hydrate } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';
import PrintSVG from '../svg-components/PrintSVG.vue';
import TabBar from '../base-components/TabBar.vue';
import CRMOverview from './CRMOverview.vue';
import ChevronLeftSVG from '../svg-components/ChevronLeftSVG.vue';
import SheddCapitalSVG from '../svg-components/SheddCapitalSVG.vue';
import CRMTargetList from './CRMTargetList.vue';
import CRMProgressUpdate from './CRMProgressUpdate.vue';
import { useVueToPrint } from "vue-to-print";
import { getComputedCRMStatusByEventId } from '@/composables/CompanyCRMTools';
import { bulkSaveCRMReportedEvents } from '@/services/Company';

const store = useStore()
const $toast = useToast()

const props = defineProps([
    'client', 
    'campaigns',
    'campaignOptions', 
    'companies', 
    'crmActions',
    'allCRMActions',
    'allCRMStatuses',
    'clientName', 
    'contactedCompanies', 
    'notContactedCompanies', 
    'respondedCompanies', 
    'noResponseCompanies'
])

const emit = defineEmits([
    'hide-report',
    'refetch'
])

const componentRef = ref();
const printPreviewIsVisible = ref(false)
const backButtonHighlighted = ref(false)
const firstTabSelected = ref(true)
const lastTabSelected = ref(false)
const selectedCampaigns = ref(props.campaigns ? props.campaigns : [])
const selectedCompanies = ref(props.companies ? props.companies : [])
const companyIdsToUpdate = ref([])
const hydrateCompanies = ref(false)

const tabList = reactive({
    targetList: {label: 'Target List', isSelected: true, isLeading: false, isFollowing: false},
    progressUpdate: {label: 'Progress Update', isSelected: false, isLeading: false, isFollowing: true},
    overview: {label: 'CRM Overview', isSelected: false, isLeading: false, isFollowing: false}, 
})

// const selectedCRMActions = ref(props.crmActions ? props.crmActions : [])

const selectedCompaniesBatches = computed(() => {
    let companyBatches = []
    for (let i=0; i< selectedCompanies.value.length; i+=80) {
        companyBatches.push(selectedCompanies.value.slice(i,i+80));
    }
    return companyBatches
})

const backButtonFill = computed(() => {
    return backButtonHighlighted.value ? '#6B7280' : '#25274D'
})

const today = computed(() => {
    const date = new Date()
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
})

const numCompanies = computed(() => {
    return selectedCompanies.value ? selectedCompanies.value.length : 0
})

const companyLabel = computed(() => {
    if(numCompanies.value == 1) {
        return 'Target'
    }
    return 'Targets'
})

const numCampaigns = computed(() => {
    return props.campaigns ? props.campaigns.length : 0
})

const campaignLabel = computed(() => {
    if(numCampaigns.value == 1) {
        return 'Campaign'
    }
    return 'Campaigns'
})

const { handlePrint } = useVueToPrint({
  content: componentRef,
  documentTitle: `${props.clientName} Outreach Overview ${today.value}`,
  removeAfterPrint: true
});

watch(() => selectedCampaigns.value, () => {
    selectedCompanies.value = props.companies.filter((company) => selectedCampaigns.value.includes(company.campaign.id))
    // selectedCRMActions.value = props.crmActions.filter((crmAction) => selectedCampaigns.value.includes(crmAction.campaign.id))
}, {deep: true})

watch(() => props.companies.value, () => {
    if(hydrateCompanies.value) {
        selectedCompanies.value = props.companies.filter((company) => selectedCampaigns.value.includes(company.campaign.id))
        hydrateCompanies.value = false
    }
}, {deep: true})



function hideReport() {
    emit('hide-report')
}

function refetch() {
    emit('refetch')
}

function rehydrateCompanies() {
    hydrateCompanies.value = true
}

function orderSelectedCompanies(orderByFieldList) {
    orderByFieldList.toReversed().forEach(fieldName => {
        let ascending = true
        if(fieldName.startsWith('-')) {
           fieldName = fieldName.substring(1)
           ascending = false
        }
        sortSelectedCompaniesByField(fieldName, ascending)
    });
}

function sortSelectedCompaniesByField(fieldName, ascending) {
    // console.log('fieldName', fieldName, ascending)
    if(fieldName == 'company_name') {
        if(ascending) {
            selectedCompanies.value.sort((a, b) => {
                return a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase())
            })
        } else {
            selectedCompanies.value.sort((a, b) => {
                return b.company_name.toLowerCase().localeCompare(a.company_name.toLowerCase())
            })
        }
    } else if(fieldName == 'campaign') {
        if(ascending) {
            selectedCompanies.value.sort((a, b) => {
                return a.campaign.campaign_name.toLowerCase().localeCompare(b.campaign.campaign_name.toLowerCase())
            })
        } else {
            selectedCompanies.value.sort((a, b) => {
                return b.campaign.campaign_name.toLowerCase().localeCompare(a.campaign.campaign_name.toLowerCase())
            })
        }
    } else if(fieldName == 'reported_crm_event') {
        if(ascending) {
            selectedCompanies.value.sort((a, b) => {
                if(a.reported_crm_event === null) {
                    return 1;
                } else if(b.reported_crm_event === null) {
                    return -1;
                } else if (a.reported_crm_event.resolved_date === null){
                    return 1;
                } else if(b.reported_crm_event.resolved_date === null) {
                    return -1;
                } else {
                    return new Date(a.reported_crm_event.resolved_date) - new Date(b.reported_crm_event.resolved_date)
                }
            })
        } else {
            selectedCompanies.value.sort((a, b) => {
                if(a.reported_crm_event === null) {
                    return 1;
                } else if(b.reported_crm_event === null) {
                    return -1;
                } else if (a.reported_crm_event.resolved_date === null){
                    return 1;
                } else if(b.reported_crm_event.resolved_date === null) {
                    return -1;
                } else {
                    return new Date(b.reported_crm_event.resolved_date) - new Date(a.reported_crm_event.resolved_date)
                }
            })
        }
    } else if(fieldName == 'computed_crm_status') {
        if(ascending) {
            selectedCompanies.value.sort((a, b) => {
                return a.computed_crm_status.value - b.computed_crm_status.value
            })
        } else {
            selectedCompanies.value.sort((a, b) => {
                return b.computed_crm_status.value - a.computed_crm_status.value
            })
        }
    }
    // console.log(selectedCompanies.value[0])
}

    // companies.value = response.data.companies.map(assignComputedEventAndStatus).sort((a, b) => {
    //     return a.computed_crm_status.value - b.computed_crm_status.value
    // });




function setPage(tabKey) {
    clearAllTabs()
    switch (tabKey) {
        case 'targetList':
            firstTabSelected.value = true;
            tabList.overview.isFollowing = true;
            break
        case 'progressUpdate':
            tabList.targetList.isLeading = true;
            tabList.overview.isFollowing = true;
            break
        case 'overview':
            tabList.progressUpdate.isLeading = true;
            lastTabSelected.value = true;
            break
    }
    tabList[tabKey].isSelected = true;
}

function clearAllTabs() {
    for(const tab of Object.values(tabList)) {
        tab.isSelected = false
        tab.isLeading = false
        tab.isFollowing = false
    }
}

function highlightBackButton () {
    backButtonHighlighted.value = true
}

function unHighlightBackButton () {
    backButtonHighlighted.value = false
}

function updateCampaigns(campaignIds) {
    selectedCampaigns.value = campaignIds
}

function showPrintPreview() {
    printPreviewIsVisible.value = true
}

function hidePrintPreview() {
    printPreviewIsVisible.value = false
}

function getCampaignName(campaignId) {
    let thisCampaign = props.campaignOptions.find((campaign) => campaign.value == campaignId)
    return thisCampaign.label
}

function setCompanyReportedEvent(company, event, autoCompute) {
    console.log('setCompanyReportedEvent', company, event, autoCompute)
    if(event) {
        company.computed_crm_status = getComputedCRMStatusByEventId(event.action.id)
    } else {
        company.computed_crm_status = getComputedCRMStatusByEventId(null)
    }

    company.reported_crm_event = event
    company.auto_compute_reported_crm_event = autoCompute

    const reported_event_not_default = 
    (!company.reported_crm_event && !company.default_crm_event) ? false : 
    (!company.reported_crm_event && company.default_crm_event) ? true : 
    (company.reported_crm_event && !company.default_crm_event) ? true : 
    (company.reported_crm_event && company.default_crm_event && company.reported_crm_event.id != company.default_crm_event.id) ? true : false

    const auto_computed_event_not_default = (company.auto_compute_reported_crm_event != company.default_auto_compute) ? true : false

    if(reported_event_not_default || auto_computed_event_not_default) {
        // console.log('company reported crm event is not default crm event', company.reported_crm_event, company.default_crm_event)
        if(!companyIdsToUpdate.value.includes(company.id)) {
            companyIdsToUpdate.value.push(company.id)
        }
    } else {
        if(companyIdsToUpdate.value.includes(company.id)){
            let company_index = companyIdsToUpdate.value.findIndex((companyId) => companyId == company.id)
            companyIdsToUpdate.value.splice(company_index, 1)
        }
    }
    
    // if(company.reported_crm_event != company.default_crm_event) {
    //     console.log('company reported crm event is not default crm event', company.reported_crm_event, company.default_crm_event)
    //     if(!companyIdsToUpdate.value.includes(company.id)) {
    //         companyIdsToUpdate.value.push(company.id)
    //     }
    // } else if(company.auto_compute_reported_crm_event != company.default_auto_compute) {
    //     console.log('company auto computed event is not default auto compute', company.reported_crm_event, company.default_crm_event)
    //     if(!companyIdsToUpdate.value.includes(company.id)) {
    //         companyIdsToUpdate.value.push(company.id)
    //     }
    // } else {
    //     if(companyIdsToUpdate.value.includes(company.id)){
    //         let company_index = companyIdsToUpdate.value.findIndex((companyId) => companyId == company.id)
    //         companyIdsToUpdate.value.splice(company_index, 1)
    //     }
    // }
}

async function saveTargetList() {
    // console.log('Reporting Dashboard: Save Target List', selectedCompanies.value.length)
    let companyEventData = selectedCompanies.value.filter((company) => companyIdsToUpdate.value.includes(company.id)).map((company) => {
            return {
                company_id: company.id,
                reported_crm_event_id: company.reported_crm_event ? company.reported_crm_event.id : null,
                auto_compute_reported_crm_event: company.auto_compute_reported_crm_event
            }
        })
    // console.log('companyEventData')
    // console.log(companyEventData)
    const token = store.state.auth.token
    const response = await bulkSaveCRMReportedEvents(token, companyEventData)
    if(response.status == 200) {
        refetch()
        companyIdsToUpdate.value = []
        // resetDefaultCRMEvents()
        $toast.open({
            message: `Successfully updated`,
            type: 'success',
            duration: 5000
        })
    } else {
        $toast.open({
            message: `Unable to update reported events`,
            type: 'error',
            duration: 20000
        })
    }
}

</script>

<style scoped>
/* General print styles here  */
.modal {
  position: fixed;
  z-index: 999;
  top: 0%;
  left: 0%;
  width: 100%;
  /* margin-left: -150px; */
}

#printContainer {
  table {
    width: 100%;
    border-collapse: collapse;
    break-inside: auto;
    page-break-inside: auto;
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  table td,
  table th {
    padding: 1.5mm;
    border: 2px solid #ccc;
    border: 2px solid #ccc;
    vertical-align: top;
    font-size: inherit;
  }
  table td.invoice-header {
    border: none;
  }

  table th {
    text-align: left;
    vertical-align: bottom;
    color: rgb(0, 0, 30);
    background-color: #04aa6d;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  table td,
  table th,
  table tr {
    /* Prevent elements from being split across pages in paginated media (like print) */
    break-inside: avoid;
    /* Automatically insert a page break after the element, if needed */
    break-after: auto;
  }
}
/* Apply styles only when the document is being printed */
@media print {
  /* Apply styles to the printed page */
  @page {
    size: auto;
    /* Set the page margins, hide default header and footer */
    margin: 1in 1in 1in 1in !important;
  }

  .page-break {
    .page-break-after: always;
  }

  body {
    /* Ensure that colors are printed exactly as they appear on the screen */
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}
</style>