<template>
    <base-table-row :isSelected="isSelected">
      <base-table-cell>
        <input type="checkbox" id="checkbox" :checked="isSelected" @change="toggleSelected"/>
      </base-table-cell>
      <base-table-cell>
        <div @click="showForm" class="text-accent-primary hover:text-dark-primary hover:cursor-pointer hover:underline">
          {{ company.id }}
          {{ company.status }}
        </div>
        <base-modal :is-form-visible="isFormVisible" @close="closeForm">
          <template #header>
            <h2 class="text-lg">{{ company.company_name }} {{ company.id }}</h2>
          </template>
          <template #body>
            <EditCompany
              :company="company"
              :campaigns="campaigns"
              @close="closeForm"
              @refetch-companies="fetchNewCompanies"
            />
          </template>
        </base-modal>
        <div>
          <base-button v-if="company.campaign" @click="showDetails('crmDetails')">CRM Details</base-button>
        </div>
        <div>
          <base-button @click="showDetails('activityDetails')">Email History</base-button>
        </div>
        <base-modal :is-form-visible="showCRMDetails" @close="closeDetails">
          <template #header>
            <h2 class="text-lg">
              {{ company.company_name }} CRM Details
            </h2>
          </template>
          <template #body>
            <CRMDetailsPage :company="company" :campaigns="campaigns" :crm-statuses="crmStatuses" :crm-action-types="crmActionTypes" @refetch-companies="fetchNewCompanies" />
          </template>
        </base-modal>
        <base-modal :is-form-visible="showActivityDetails" @close="closeDetails">
          <template #header>
            <h2 class="text-lg">
              {{ company.company_name }} Activities
            </h2>
          </template>
          <template #body>
            <CompanyActivitiesPage :company="company" />
          </template>
        </base-modal>
      </base-table-cell>
      <base-table-cell > 
        <CompanyNameDBATierPCINVCityStateURLCell :company="company"  @refetch-companies="fetchNewCompanies"   />
      </base-table-cell>
      <base-table-cell>
        <AccountManagerNotesAccountManagerResearchNotesCell :company="company" @refetch-companies="fetchNewCompanies"/>
      </base-table-cell>
      <base-table-cell>
        <ClientFeedbackClientMeetingNotesCell :company="company" @refetch-companies="fetchNewCompanies" />
      </base-table-cell>
      <base-table-cell>
        <RevenueHeadcountSqFtCell :company="company" @refetch-companies="fetchNewCompanies"/>
      </base-table-cell>
      <base-table-cell>
        <PrimaryContactAllContactsCell :company="company" @refetch-companies="fetchNewCompanies" />
      </base-table-cell>
      <base-table-cell>
        <CRMStatusCRMSummaryCell :crm-statuses="crmStatuses" :company="company" @refetch-companies="fetchNewCompanies" /> 
      </base-table-cell>
      <base-table-cell>
        <CRMActivityOutcomeCell :company="company" />
      </base-table-cell>
      <base-table-cell>
        <DaysSinceLastActivityCell :company="company" />
      </base-table-cell>
      <base-table-cell>
        <CRMNextActionCell :crm-action-types="crmActionTypes" :crm-users="crmUsers" :company="company" @refetch-companies="fetchNewCompanies"/>
      </base-table-cell>
      <base-table-cell>
        <CRMNextActionDueDateMarkNextActionButtonsVue :company="company"  @refetch-companies="fetchNewCompanies" />
      </base-table-cell>
      <base-table-cell>
        <DaysUntilNextActionCell :company="company"/>
      </base-table-cell>
    </base-table-row>
  </template>
  
  <script>
    import EditCompany from './EditCompany.vue';
    import CompanyNameDBATierPCINVCityStateURLCell from './company-compound-cells/CompanyName+DBA+Tier+PC+INV+City+State+URLCell.vue';
    import AccountManagerNotesAccountManagerResearchNotesCell from './company-compound-cells/AccountManagerNotes+AccountManagerResearchNotesCell.vue';
    import ClientFeedbackClientMeetingNotesCell from './company-compound-cells/ClientFeedback+ClientMeetingNotesCell.vue';
    import RevenueHeadcountSqFtCell from './company-cell-components/RevenueHeadcountSqFtCell.vue';
    import PrimaryContactAllContactsCell from './company-compound-cells/PrimaryContact+AllContactsCell.vue';
    import CRMStatusCRMSummaryCell from './company-compound-cells/CRMStatus+CRMSummaryCell.vue';
    import CRMActivityOutcomeCell from './company-cell-components/CRMActivityOutcomeCell.vue';
    import DaysSinceLastActivityCell from './company-cell-components/DaysSinceLastActivityCell.vue';
    import CRMNextActionCell from './company-cell-components/CRMNextActionCell.vue';
    import DaysUntilNextActionCell from './company-cell-components/DaysUntilNextActionCell.vue';
    import CRMNextActionDueDateMarkNextActionButtonsVue from './company-compound-cells/CRMNextActionDueDate+MarkNextActionButtons.vue';
    import CompanyActivitiesPage from './CompanyActivitiesPage.vue';
    import CRMDetailsPage from './CRMDetailsPage.vue';
    import { vetURL, promoteOrDemoteResearch, getZoomInfoId, clearZoomInfo, getZoomInfoBasic, transferZoomInfoBasic } from '@/services/Company';
    import { exportDefaultSpecifier } from '@babel/types';
  
      export default {
          name: 'campaign-activities-row',
          props: ['company', 'campaigns', 'changedCompanyFilters', 'clearedSelectedCompanies', 'selectAll', 'selectAllOnPage', 'permissions', 'selectedCompaniesOnPageMap', 'crmStatuses', 'crmActionTypes', 'crmUsers'],
          components: {
              EditCompany,
              AccountManagerNotesAccountManagerResearchNotesCell,
              PrimaryContactAllContactsCell,
              RevenueHeadcountSqFtCell,
              ClientFeedbackClientMeetingNotesCell,
              CRMStatusCRMSummaryCell,
              CRMActivityOutcomeCell,
              DaysSinceLastActivityCell,
              CRMNextActionCell,
              DaysUntilNextActionCell,
              CompanyNameDBATierPCINVCityStateURLCell,
              CRMNextActionDueDateMarkNextActionButtonsVue,
              CompanyActivitiesPage,
              CRMDetailsPage,
          },
          data() {
              return {
                  isEditable: false,
                  isModalVisible: false,
                  isPopoverVisible: false,
                  isFormVisible: false,
                  isDetailsVisible: false,
                  showCRMDetails: false,
                  showActivityDetails: false,
                  reLoadFormData: true,
                  isSelected: this.selectAll,
                  boxChecked: false,
                  showFullNotes: this.company.account_manager_research_notes && this.company.account_manager_research_notes.length < 50,
                  xPosition: 0,
                  yPosition: 0,
              }
          },
          mounted() {
            if(this.onPageAndSelected){
                this.isSelected = true
              }
          },
          methods: {
              toggleEditable() {
                  return this.isEditable = !this.isEditable
              },
              fetchNewCompanies() {
                  this.$emit('refetch-companies')
              },
              showModal() {
                  this.isModalVisible = true
              },
              closeModal() {
                  this.isModalVisible = false
              },
              showPopover() {
                this.isPopoverVisible = true;
                this.xPosition = this.$refs.companyName.getBoundingClientRect().right
                this.yPosition = this.$refs.companyName.getBoundingClientRect().top
              },
              hidePopover() {
                this.isPopoverVisible = false;
                this.xPosition = 0;
                this.yPosition = 0;
              },
              showForm() {
                  this.$emit('refetch-form-data')
                  this.isFormVisible = true
              },
              closeForm() {
                  this.isFormVisible = false
              },
              toggleShowNotes(){
                  this.showFullNotes = !this.showFullNotes
              },
              toggleSelected() {
                if(this.isSelected) {
                  this.isSelected = false
                  return this.$emit('remove-selected-company', this.company)
                } else if(!this.isSelected) {
                  this.isSelected = true
                  return this.$emit('add-selected-company', this.company)
                }
              },
              resetSelected() {
                  this.isSelected = false
                  this.$emit('reset-cleared-selected-companies')
              },
              showDetails(detailsType) {
                this.isDetailsVisible = true
                if(detailsType === 'crmDetails'){
                    this.showCRMDetails = true
                    this.showActivityDetails = false
                } else if (detailsType === 'activityDetails'){
                    this.showActivityDetails = true
                    this.showCRMDetails = false
                }
              }, 
              closeDetails(){
                this.isDetailsVisible = false
                this.showActivityDetails = false
                this.showCRMDetails = false
              },
              closeActivities(){
                this.isActivitiesVisible = false
              },
              async vetURLAction(action) {
                  const token = this.$store.getters['auth/showToken']
                  await vetURL(this.company.id, action, token)
                  this.fetchNewCompanies()
              },
              async promoteOrDemote(action) {
                  const token = this.$store.getters['auth/showToken']
                  await promoteOrDemoteResearch(this.company.id, action, token)
                  this.fetchNewCompanies()
              },
              async getZiId() {
                  const token = this.$store.getters['auth/showToken']
                  await getZoomInfoId(this.company.id, token)
                  this.fetchNewCompanies()
              },
              async clearZi() {
                  const token = this.$store.getters['auth/showToken']
                  await clearZoomInfo(this.company.id, token)
                  this.fetchNewCompanies()
              },
              async getZiBasic() {
                  const token = this.$store.getters['auth/showToken']
                  await getZoomInfoBasic(this.company.id, token)
                  this.fetchNewCompanies()
              },
              async transferZiBasic() {
                  const token = this.$store.getters['auth/showToken']
                  await transferZoomInfoBasic(this.company.id, token)
                  this.fetchNewCompanies() 
              }
          },
          watch: {
            changedCompanyFilters() {
                if(this.changedCompanyFilters){
                  this.isSelected = false
                  this.$emit('reset-changed-company-filters')
                }
                this.resetSelected()
            },
            clearedSelectedCompanies: {
                handler() {
                    if(this.clearedSelectedCompanies){
                        this.isSelected = false
                        this.$emit('reset-cleared-selected-companies')
                    }
                    this.resetSelected()
                },
                immediate: true
            },
            clearedSelectedCompaniesOnPage() {
              if(this.clearedSelectedCompaniesOnPage){
                this.isSelected = false
                this.$emit('reset-cleared-selected-companies-on-page')
              }
              this.resetSelected()
            },
            selectAll() {
              if(this.selectAll){
                this.isSelected = true;
                console.log('company row - emitted add-selected-company')
                return this.$emit('add-selected-company', this.company)
              }else if(!this.selectAll){
                this.isSelected = false;
                console.log('company row - emitted remove-selected-company')
                return this.$emit('remove-selected-company', this.company)
              }
            },
            selectAllOnPage() {
              if(this.selectAllOnPage){
                this.isSelected = true;
                // console.log('company row - emitted add-selected-company')
                return this.$emit('add-selected-company', this.company)
              }else if(!this.selectAllOnPage){
                this.isSelected = false;
                // console.log('company row - emitted remove-selected-company')
                return this.$emit('remove-selected-company', this.company)
              }
            }
          },
          computed: {
              fullAddress(){
                  return `${this.company.address ? this.company.address : "None"} ${this.company.city ? this.company.city : "None"}, ${this.company.state ? this.company.state : "None"} ${this.company.country ? this.company.country : "None"}`
              },
              fullZiAddress() {
                  return `${this.company.zi_address ? this.company.zi_address : "None"} ${this.company.zi_city ? this.company.zi_city : "None"}, ${this.company.zi_state ? this.company.zi_state : "None"} ${this.company.zi_country ? this.company.zi_country : "None"}`
              },
              truncatedNotes(){
                  if(this.company.account_manager_research_notes && this.company.account_manager_research_notes.length > 50){
                      return this.company.account_manager_research_notes.substring(0,51)
                  } else if (this.company.account_manager_research_notes){
                      return this.company.account_manager_research_notes
                  } else {
                      return "None"
                  }
              },
              httpCompanyUrl(){
                  if(this.company.website_url.startsWith('http://') || this.company.website_url.startsWith('https://')){
                      return this.company.website_url
                  } else {
                      return `http://${this.company.website_url}`
                  }
              },
              ziRevenueDisplay(){
                if(this.company.zi_revenue){
                  let ziRevenue = this.company.zi_revenue / 1000
                  return ziRevenue.toFixed(2)
                } else {
                  return null
                }
              },
              onPageAndSelected(){
                if(this.selectedCompaniesOnPageMap.size > 0){
                  return this.selectedCompaniesOnPageMap.has(this.company.id)
                } else {
                  return false
                }
              }
          },
      }
  </script>