<template>
  <div class="w-full">
    <!-- Filter Component -->

    <base-card v-show="showFilterPane">
      <ClientsFilters
        @change-referral-filters="setClientsFilters"
        @set-changed-referral-filters="setChangedClientsFilters"
      ></ClientsFilters>
    </base-card>

    <!-- Delete Warning -->

    <DeleteClient
      v-if="isDeleteModalVisible"
      :client="deletedClient"
      :id="deletedID"
      @close-delete-modal="closeDeleteModal"
    ></DeleteClient>

    <!-- Add Modal Component -->

    <base-modal :is-form-visible="addModalVisible" @close="closeAddModal">
      <template #header> Add Client </template>
      <template v-slot:body>
        <ClientForm
          @submit-add-form="submitAddForm"
          @close-add-form="closeAddModal"
          addOrEdit="add"
        ></ClientForm>
      </template>
    </base-modal>

    <!-- Edit Modal Component -->
<!-- 
    <base-modal :is-form-visible="editModalVisible" @close="closeEditModal">
      <template v-slot:header> Edit Client </template>
      <template v-slot:body>
        <ClientForm
          @submit-edit-form="submitEditForm"
          @close-edit-form="closeEditModal"
          :client="editClientObject"
          @refetch-clients="loadClients(fetchFilter, true)"
          addOrEdit="edit"
        ></ClientForm>
      </template>
    </base-modal> -->

    <!-- Table Component -->

    <base-card>
      <base-pagination-header
        @load-data="loadClients(fetchFilter.value, true)"
        @get-next="getNext"
        @go-to-first="goToFirst"
        @get-prev="getPrev"
        @go-to-last="goToLast"
        @jump-to-page="jumpToPage"
        @set-page-size-filter="setPageSizeFilter"
        :page_filter="clientsFilters"
        :page="page"
        :numPages="num_pages"
        :isLoading="isLoading"
        :to="viewingToNbr"
        :from="viewingFromNbr"
      >
        <template v-slot:message>
          <div class="pl-3 pt-1">
            {{ viewingFromNbr }}-{{ viewingToNbr }} | {{ count }} Total Clients
            <base-button class="ml-5" @click="openAddModal">Add Client</base-button>
          </div>
        </template>
      </base-pagination-header>

      <div class="h-full overflow-y-auto border">
        <ClientsTable lass="table-auto">
          <template #table-header>
            <base-table-header
              :key="header.id"
              :header="header"
              class="pt-3 pb-3"
              v-for="header in tableHeadersList"
            >
              {{ header }}
            </base-table-header>
          </template>

          <template #table-body>
            <ClientsRow
              v-for="client in clients"
              @open-edit="openEditModal"
              @delete-client="showDeleteModal"
              @load-clients="loadClients(fetchFilter, true)"
              @submit-edit-form="submitEditForm"
              :key="client.id"
              :client="client"
            ></ClientsRow>
          </template>
        </ClientsTable>
      </div>
    </base-card>
  </div>
</template>

<script>
export default {
  name: "aerocle-clients",
};
</script>

<script setup>
// imports

import { ref, computed, onMounted, reactive } from "vue";
import { getClients, editClient, createClient } from "../services/Client.js";
import { useStore } from "vuex";

import ClientsRow from "./clients_page/table/ClientsRow.vue";
import ClientsFilters from "./clients_page/ClientsFilters.vue";
import DeleteClient from "./clients_page/DeleteClientModal.vue";
import ClientForm from "./clients_page/ClientForm.vue";
import ClientsTable from "./clients_page/ClientsTable.vue"

// constants/variables

const store = useStore();

const tableHeadersList = ref([
  "ID",
  "Client Name",
  "Account Manager",
  "Engagement",
  // "Engagement End",
  "Retainer Frequency",
  "Invoice Reminder Config",
  "Contact Notes",
  "Client Posts",
  "Fee Structure",
  "Target Email Address",
  "PDF Link",
  "Edit",
  "Delete",
]);
const changedClientsFilters = ref(false);
const next = ref();
const prev = ref();
const count = ref();
const page = ref(1);
const num_pages = ref();
const page_size = ref();
const editClientObject = ref();
const clients = ref([]);
const addModalVisible = ref(false);
const editModalVisible = ref(false);
const isLoading = ref(false);
const isDeleteModalVisible = ref(false);
const deletedClient = ref();
const deletedID = ref();
const clientsFilters = reactive({
  statusFilter: "",
  accountManagerFilter: [],
  pageSizeFilter: 50,
});

const showFilterPane = computed(() => store.getters["client/showFilterPane"]);

// computed values

const viewingFromNbr = computed(function () {
  return (page.value * clientsFilters.pageSizeFilter + 1) - clientsFilters.pageSizeFilter;
});

const viewingToNbr = computed(function () {
  if (page.value === num_pages.value) {
    return count.value;
  } else {
    return page.value * clientsFilters.pageSizeFilter;
  }
});

const fetchFilter = computed(function () {
  let query = new URLSearchParams({});
  if (clientsFilters.statusFilter) {
    query.append("status", clientsFilters.statusFilter);
  }
  if (clientsFilters.accountManagerFilter) {
    clientsFilters.accountManagerFilter.forEach((manager) => {
      query.append("account_manager", manager);
    });
  }
  if (clientsFilters.pageSizeFilter) {
    query.append("page_size", clientsFilters.pageSizeFilter); // To append more data
  }

  let url;
  let queriesCount = 0;
  for (let value of query.values()) {
    queriesCount = queriesCount + 1;
  }
  if (queriesCount > 0) {
    url = "?" + query.toString();
  } else {
    url = "?";
  }
  return url;
});

// async functions

async function loadClients(endpoint = fetchFilter.value, retainSpot) {
  console.log('loadin')
  isLoading.value = true;
  let payload = {
    token: store.state.auth.token,
    endpoint: retainSpot ? endpoint + `&page=${page.value}` : endpoint,
  };
  const response = await getClients(payload);
  next.value = response.next ? `&page=${response.page + 1}` : null;
  prev.value = response.prev ? `&page=${response.page - 1}` : null;
  count.value = response.count;
  page.value = response.page;
  num_pages.value = response.num_pages;
  clients.value = response.clients;
  page_size.value = response.page_size;
  console.log(page_size.value);
  isLoading.value = false;
}

async function submitAddForm(formData) {
  const token = store.state.auth.token;
  const result = await createClient(formData, token);
  if (result.status == 200) {
    alert("Client created successfully.");
  } else {
    alert("Error, client not created.");
  }
  loadClients();
  closeAddModal();
}

async function submitEditForm(id, formData) {
  const token = store.state.auth.token;
  const result = await editClient(id, formData, token);
  if (result.status == 200) {
    alert("Client updated successfully.");
  } else {
    alert("Error, client not updated.");
  }
  loadClients();
  closeEditModal();
}

async function setClientsFilters(updatedFilters) {
  clientsFilters.statusFilter = updatedFilters.value.statusFilter;
  clientsFilters.accountManagerFilter =
    updatedFilters.value.accountManagerFilter;
  await loadClients(fetchFilter.value);
}

async function setPageSizeFilter(target) {
  target == "clear"
    ? (clientsFilters.pageSizeFilter = 50)
    : (clientsFilters.pageSizeFilter = target);
  await loadClients(fetchFilter.value);
}

// functions

function closeDeleteModal() {
  isDeleteModalVisible.value = false;
  loadClients();
}

function showDeleteModal(id, client) {
  console.log("showing delete modal")
  deletedClient.value = client;
  isDeleteModalVisible.value = true;
}

function openAddModal() {
  addModalVisible.value = true;
}

function closeAddModal() {
  addModalVisible.value = false;
}

function openEditModal(client) {
  editClientObject.value = client;
  editModalVisible.value = true;
}

function closeEditModal() {
  editModalVisible.value = false;
}

function setChangedClientsFilters() {
  changedClientsFilters.value = true;
}

function getNext() {
  if (next.value) {
    loadClients(fetchFilter.value + next.value);
  } else {
    alert("No next page");
  }
}

function getPrev() {
  if (prev.value) {
    loadClients(fetchFilter.value + prev.value);
  } else {
    alert("No previous page");
  }
}

function goToFirst() {
  if (page.value != 1) {
    loadClients(fetchFilter.value + `&page=${1}`);
  } else {
    alert("At first page");
  }
}

function goToLast() {
  if (num_pages.value != page.value) {
    loadClients(fetchFilter.value + `&page=${num_pages.value}`);
  } else {
    alert("At last page");
  }
}

function jumpToPage(targetPage) {
  loadClients(fetchFilter.value + `&page=${targetPage}`);
}

// life-cycle

onMounted(() => {
  loadClients();
});
</script>
