<template>
    <base-table-row>
        <base-table-cell class="w-1/5">{{ campaign.id }} {{ campaign.status }}</base-table-cell>
        <base-table-cell class="w-1/5">{{ campaign.campaign_name }}</base-table-cell>
        <base-table-cell class="w-1/5">
            {{ campaign.client ? campaign.client.client_name : '' }}
        </base-table-cell>
        <base-table-cell class="w-1/5">
            {{ campaign.workflow_status ? campaign.workflow_status : 'None' }}
        </base-table-cell>
        <base-table-cell>
            <div v-if="campaign.owners.length > 0">
                <div class="whitespace-nowrap" v-for="owner in campaign.owners" :key="owner.id">
                    {{ owner.first_name }} {{ owner.last_name }}
                </div>
            </div>
            <div v-else>
                --
            </div>
        </base-table-cell>
        <base-table-cell class="w-1/5">
            <div class="flex justify-items-center">
                <base-button class="mr-2" @click="editCampaign">Edit</base-button>
                <base-button v-if="campaign.status == 'active'"
                    @click="checkArchiveCampaign(campaign)">Archive</base-button>
            </div>
        </base-table-cell>
    </base-table-row>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex'
import { archiveCampaign } from '@/services/Campaigns'

const store = useStore()

const props = defineProps({
    campaign: Object
})

const emit = defineEmits(['edit-campaign', 'refetch-campaigns'])

function editCampaign() {
    emit('edit-campaign', props.campaign)
}

function checkArchiveCampaign(campaign) {
    const campaignNameNoHtml = removeHtmlTags(campaign.campaign_name)
    if (!confirm("You are about to archive the ''" +
        campaignNameNoHtml +
        "'' campaign. Are you sure you want to proceed?")) {
        return;
    } else {
        archiveCurrentCampaign(campaign)
    }
}

async function archiveCurrentCampaign(campaign) {
    let token = store.state.auth.token
    const campaignData = {
        status: 'inactive'
    }
    await archiveCampaign(campaign.id, campaignData, token)
    emit('refetch-campaigns')
}

function removeHtmlTags(str) {
    return str.replace(/(<([^>]+)>)/gi, "")
}
</script>