<template>
    <svg
        fill="none" 
        viewBox="0 0 24 24" 
        stroke-width="1.5" 
        :stroke="strokeColor" 
        class="w-5 h-5">
    >
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z" />
    </svg>
</template>

<script setup>
import { ref, defineProps } from 'vue';

const props = defineProps(['stroke'])

const strokeColor = ref(props.stroke ? props.stroke : 'black')

</script>