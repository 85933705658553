<template>
    <div class="h-full flex flex-col justify-center items-center w-1/3">
        <div class="text-dark-primary text-wider text-2xl font-light border-b border-gray-300 w-full flex justify-center pb-2">
            CRM Reporting Dashboard
        </div>
        <div class="text-sm flex items-center w-full pl-2 pb-1 pt-4 font-semibold tracking-widest text-gray-600">
            Client
        </div>
        <type-ahead-box
            placeholder="Choose Client"
            nullLabel="No Results"
            :default-value="client"
            :options="clientOptions"
            @update-value="updateClient"
        ></type-ahead-box>
        <div class="text-sm flex items-center w-full pl-2 pb-1 pt-4 font-semibold tracking-widest text-gray-600">
            Campaigns
        </div>
        <type-ahead-multiselect
            v-if="client"
            :placeholder="campaignPlaceholder"
            nullLabel="No Results"
            :default-value="campaigns"
            :options="campaignOptions"
            @update-value="updateCampaigns"
        ></type-ahead-multiselect>
        <div v-else
            class="border text-gray-600 bg-gray-200 border-gray-300 p-1.5 mb-[30px]  w-full"
        >
            Select Client To Filter Campaigns
        </div>
        <div class="flex justify-center items-center pt-4">
            <button v-if="campaigns.length > 0" class="bg-dark-secondary hover:bg-dark-primary hover:text-gray-100 hover:shadow active:bg-dark-primary active:text-gray-400 text-white tracking-wider font-medium px-3 py-1.5 rounded-sm" @click="viewReport">View Client Report</button>
            <button v-else class="bg-gray-300  text-gray-600 tracking-wider font-medium px-3 py-1.5 rounded-sm" disabled>View Client Report</button>
        </div>
    </div>
</template>

<script setup> 
import { ref, defineProps, defineEmits, watch, computed } from 'vue';

const props = defineProps(['clientOptions', 'campaignOptions', 'selectedClient', 'selectedCampaigns'])
const emit = defineEmits(['select-client', 'select-campaigns', 'view-report'])

const client = ref(props.selectedClient)

const campaigns = ref(props.selectedCampaigns)


watch(() => client.value, () => {
    if(client.value) {
        emit('select-client', client.value)
    }
})

watch(() => campaigns.value, () => {
    if(campaigns.value) {
        emit('select-campaigns', campaigns.value)
    }
})

watch(() => props.selectedCampaigns, () => {
    campaigns.value = props.selectedCampaigns
})

function updateClient(clientId) {
    client.value = clientId
    if(clientId == null) {
        campaigns.value = []
    }
}

function updateCampaigns(campaignIds) {
    campaigns.value = campaignIds
}

const campaignPlaceholder = computed(() => {
    let label
    if(props.campaignOptions.length == 1) {
        label = 'Campaign'
    } else {
        label = 'Campaigns'
    }
    if(props.selectedCampaigns.length == props.campaignOptions.length) {
        return `All ${props.selectedCampaigns.length} Campaigns Selected`
    } else if(props.selectedCampaigns.length > 0) {
        return `${props.selectedCampaigns.length} of ${props.campaignOptions.length} ${label} Selected`
    } else {
        return 'No Campaigns Selected'
    }
})

function viewReport() {
    emit('view-report')
}
// function handleInputChange(e) {
//     emit('select-client', e.target.value)
// }

</script>