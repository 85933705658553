<template>
    <div class="p-2 w-full h-full">
        <!-- <h1 class="underline text-center">Add Multiple Companies</h1> -->
        <div v-if="containerType==='rightSideBar'" class="h-full">
            <div v-if="openDialogBox" class="break-normal text-left align-middle py-6 border-b border-gray-400 w-full">
                <div class=" break-normal text-sm leading-4 text-gray-900">{{ responseStatus }}</div>
                <div class="mt-2 break-normal text-xs text-gray-500">{{ responseMessage }}</div>
                <div class="mt-4 flex justiyf-center">
                    <base-button-affirmative type="button" @click="clearSideBar">Okay</base-button-affirmative>
                </div>
            </div>
            <div v-else class="h-full">
                <div class="flex justify-start w-full">
                    <CreateSource
                    />
                </div>
                <AddMultipleCompaniesForm
                    class="h-full"
                    :formCleared="formCleared"
                    @submit-companies="submitCompanies"
                    @set-form-cleared-false="setFormClearedFalse"
                />
            </div>
        </div>
        <div v-else>
            <div class="flex justify-start w-full">
                <CreateSource />
            </div>
            <HeadlessDialogBox v-if="openDialogBox" @close-dialog-box="closeDialogBox" >
                <template #header>
                    <span>{{ responseStatus }}</span>
                </template>
                <template #body>
                    <span>{{ responseMessage }}</span>
                </template>
            </HeadlessDialogBox>
            <AddMultipleCompaniesForm
                :formCleared="formCleared"
                @submit-companies="submitCompanies"
                @set-form-cleared-false="setFormClearedFalse"
            />
        </div>

    </div>
</template>

<script>
export default {
    name: "add-multiple-companies"
}
</script>

<script setup>
import { computed, ref, watch, reactive, defineEmits, defineProps } from 'vue';
import CreateSource from './CreateSource.vue';
import { useStore } from 'vuex';
import HeadlessDialogBox from './HeadlessDialogBox.vue';
import AddMultipleCompaniesForm from '@/components/AddMultipleCompaniesForm.vue';

const store = useStore()

const openDialogBox = ref(false)

const responseStatus = ref("")

const responseMessage = ref("")

const formCleared = ref(false)

const props = defineProps(['containerType'])

const emit = defineEmits(['close', 'refetch-companies'])


const pageKey = ref(0)

function closeDialogBox(){
    openDialogBox.value = false
    emit('refetch-companies')
    emit('close')
}

function clearSideBar() {
    emit('refetch-companies')
    openDialogBox.value = false
    formCleared.value = true
}

function setFormClearedFalse(){
    formCleared.value = false
}

function submitCompanies(response) {
    responseStatus.value = response.responseStatus
    responseMessage.value = response.responseMessage
    openDialogBox.value = true
}

</script>