<template>
    <div class="w-full h-full">
        <div class="h-[70%] border-b border-gray-400 ">
            <JobQueueForm
                class="pr-3 h-full"
                containerType="rightSideBar"
                jobType="full_text_search"
                @refetch-jobs="setRecentQueries"
            />
        </div>
        <div class="h-[20%] overflow-y-scroll">
            <FullTextSearchHistory
            :userId="userId"
            :isLoading="isLoading"
            :queuedQueries="queuedQueries"
            :runningQueries="runningQueries"
            :haltedQueries="haltedQueries"
            :recentCompleteQueries="recentCompleteQueries"
            @refetch-recent-queries="setRecentQueries" class="pr-3" />
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, watch } from 'vue';
import JobQueueForm from '@/components/job-queue/JobQueueForm.vue';
import FullTextSearchHistory from './FullTextSearchHistory.vue';
import { useStore } from 'vuex';
import { getAsynchronousJobQueue } from '@/services/AsynchronousJobs';

const props = defineProps(['userId'])

const store = useStore()

const isLoading = ref(false)

watch(() => props.userId, ()=> {
    if(props.userId) {
        setRecentQueries()
    }
})

const queuedQueries = ref([])
const runningQueries = ref([])
const haltedQueries = ref([])
const recentCompleteQueries = ref([])

async function setQueuedQueries () {
    if(props.userId){
        let payload = {
        token: store.state.auth.token,
        endpoint: `?user=${props.userId}&status=queued&created_within=3`
    };
    const response = await getAsynchronousJobQueue(payload);
    queuedQueries.value = response.jobs
    }
}

async function setRunningQueries () {
    if(props.userId){
        let payload = {
        token: store.state.auth.token,
        endpoint: `?user=${props.userId}&status=running&created_within=3`
    };
    const response = await getAsynchronousJobQueue(payload);
    runningQueries.value = response.jobs
    }
}

async function setHaltedQueries () {
    if(props.userId){
        let payload = {
        token: store.state.auth.token,
        endpoint: `?user=${props.userId}&status=halted&created_within=3`
    };
    const response = await getAsynchronousJobQueue(payload);
    haltedQueries.value = response.jobs
    }
}

async function setRecentCompleteQueries () {
    if(props.userId){
        let payload = {
        token: store.state.auth.token,
        endpoint: `?user=${props.userId}&status=complete&created_within=1`
    };
    const response = await getAsynchronousJobQueue(payload);
    recentCompleteQueries.value = response.jobs
    }
}

async function setRecentQueries () {
    // console.log('set recent queries')
    isLoading.value = true
    await setQueuedQueries()
    await setRunningQueries()
    await setHaltedQueries()
    await setRecentCompleteQueries()
    isLoading.value = false
}

setRecentQueries()





</script>