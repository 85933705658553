// class CSVExporter {
//     constructor(fields, companies) {
//         this.fields = fields
//         this.companies = companies
//     }
// }


function getPrimaryContact(company) {
    if(company.contacts){
        let primaryContacts = []
        company.contacts.forEach((contact) => {
            for(let i = 0; i < contact.company_relationships.length; i++){
                if(contact.company_relationships[i].company_id == company.id && contact.company_relationships[i].is_primary_contact){
                    primaryContacts.push(contact)
                }
            }
        })
        return primaryContacts.length > 0 ? primaryContacts[0] : null
    } else {
        return null
    }
}


function getPrimaryContactFullName(company) {
    let fullName = ''
    const primaryContact = getPrimaryContact(company)
    if(primaryContact){
        if(primaryContact.middle_name){
            fullName = `${primaryContact.first_name} ${primaryContact.middle_name} ${primaryContact.last_name}`
        } else {
            fullName = `${primaryContact.first_name} ${primaryContact.last_name}`
        }
    }
    return fullName
}

function getPrimaryContactPreferredName(company) {
    let preferredName = ''
    const primaryContact = getPrimaryContact(company)
    if(primaryContact){
        preferredName = primaryContact.preferred_name
    }
    return preferredName
}

function getPrimaryContactLinkedin(company) {
    let linkedin = ''
    const primaryContact = getPrimaryContact(company)
    if(primaryContact){
        linkedin = primaryContact.linkedin_profile
    }
    return linkedin
}

function getPrimaryContactTitle(company) {
    let title = ''
    const primaryContact = getPrimaryContact(company)
    if(primaryContact){
        title = primaryContact.title
    }
    return title
}

function getPrimaryContactEmail(company) {
    let email = ''
    const primaryContact = getPrimaryContact(company)
    if(primaryContact){
        email = primaryContact.company_relationships.filter((relationship) => {
            return relationship.company_id === company.id
        })[0].email_address
    }
    return email
}

function getPrimaryContactPhone(company) {
    let phone = ''
    const primaryContact = getPrimaryContact(company)
    if(primaryContact){
        phone = primaryContact.company_relationships.filter((relationship) => {
            return relationship.company_id === company.id
        })[0].phone_number
    }
    return phone
}

function getMostRecentCRMStatus(company) {
    let crmStatus = ''
    if(company.crm_statuses.length > 0){
        console.log('crm statuses', company.crm_statuses)
        const returnStatus = company.crm_statuses.filter((status) => {
            return status.campaign.id === company.campaign.id 
        })
        if(returnStatus.length > 0){
            return returnStatus[0].crm_status.status_name
        }
    }
    return crmStatus
}

function getCampaignName(company) {
    return company.campaign ? company.campaign.campaign_name : ''
}

function getClientName(company) {
    return company.campaign ? company.campaign.client ? company.campaign.client.client_name : '' : ''
}

function getTier(company) {
    return company.tier_object ? company.tier_object.tier_name : ''
}

function getURLVettedBy(company) {
    return company.url_vetted_by ? company.url_vetted_by.first_name + " " + company.url_vetted_by.last_name : ''
}

export function createCSV(fields, companies) {
    // console.log('companies', companies)
    let csvContent = "";
    csvContent += fields.join(",") + "\r\n"
    let counter = 0
    companies.forEach((company) => {
        counter++
        let companyRow = []
        fields.forEach((field) => {
            if(field == 'primary_contact_full_name'){
                companyRow.push('"'+getPrimaryContactFullName(company)+'"')
            }
            else if(field == 'primary_contact_preferred_name'){
                companyRow.push('"'+getPrimaryContactPreferredName(company)+'"')
            }
            else if(field == 'primary_contact_title'){
                companyRow.push('"'+getPrimaryContactTitle(company)+'"')
            }
            else if(field == 'primary_contact_email'){
                companyRow.push('"'+getPrimaryContactEmail(company)+'"')
            }
            else if(field == 'primary_contact_linkedin'){
                companyRow.push('"'+getPrimaryContactLinkedin(company)+'"')
            }
            else if(field == 'primary_contact_phone'){
                companyRow.push('"'+getPrimaryContactPhone(company)+'"')
            }
            else if(field == 'crm_status'){
                companyRow.push('"'+getMostRecentCRMStatus(company)+'"')
            } else if(field == 'campaign'){
                companyRow.push('"'+getCampaignName(company)+'"')
            } else if(field == 'client'){
                companyRow.push('"'+getClientName(company)+'"')
            } else if(field == 'tier_object'){
                companyRow.push('"'+getTier(company)+'"')
            } else if(field == 'url_vetted_by'){
                companyRow.push('"'+getURLVettedBy(company)+'"')
            } else {
                let companyField = company[field]
                if(typeof companyField == 'string') {
                    companyField = companyField.replaceAll('"', "'")
                }
                if(!companyField && companyField !== 0){
                    companyField = ''
                }
                companyRow.push('"'+companyField+'"')
            }
        })
        csvContent += companyRow.join(",") + "\r\n"
    })
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "company_export.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    // console.log('counter', counter)
    // const encodedUri = encodeURI(csvContent);
    // const link = document.createElement("a");
    // link.setAttribute("href", encodedUri);
    // link.setAttribute("download", "company_export.csv");
    // document.body.appendChild(link); // Required for FF
    // link.click();
}

