<template>
    <div>
        <input type="date" :value="crmNextActionDueDate" @change="updateCRMNextActionDueDate">
    </div>
</template>

<script>
    export default {
        name: "crm-next-action-due-date-cell"
    }
</script>

<script setup>
import { defineProps, computed, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { updateCompany } from '@/services/Company';

    const store = useStore()

    const props = defineProps({
        company: Object
    })

    const crmNextActionDueDate = computed(()=>{
        if(props.company.crm_next_action_due_date){
           return new Date(props.company.crm_next_action_due_date).toISOString().split('T')[0]
        } else {
            return null
        }
    })

    const emit = defineEmits(['refetch-companies'])

    async function updateCRMNextActionDueDate(e){
        console.log('value', e.target.value)
        let newDate = e.target.value
        // console.log(new Date(e.target.value))
        const token = store.state.auth.token
        const data = JSON.stringify({
            crm_next_action_due_date: newDate
        })
        await updateCompany(props.company.id, data, token)
        emit('refetch-companies')
    }

</script>