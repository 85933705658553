<template>
    <div v-if="(overdueOnly && isOverDue) || !overdueOnly" @click.right.prevent="showEditPopover" @scroll.passive="hidePopover, closeEditPopover" ref="highPriorityCompanyRow" class="w-full">
        <div v-if="displayAction && viewMode === 'cards'" @mouseover="showPopover" @mouseleave="hidePopover" class="flex bg-neutral-50 items-center justify-between w-full py-0.5 px-1 border-b">
            <div class="mr-1.5" v-if="displayAction.resolved_date">
                <svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    class="h-5">
                    <path d="M5.75 7.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM5 10.25a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0ZM10.25 7.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM7.25 8.25a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0ZM8 9.5A.75.75 0 1 0 8 11a.75.75 0 0 0 0-1.5Z" />
                    <path fill-rule="evenodd" d="M4.75 1a.75.75 0 0 0-.75.75V3a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2V1.75a.75.75 0 0 0-1.5 0V3h-5V1.75A.75.75 0 0 0 4.75 1ZM3.5 7a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4.5a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1V7Z" clip-rule="evenodd" />
                </svg>  
            </div>
            <!-- <div class="mr-1.5" v-if="displayAction.action.resolved_type == 'action' && displayAction.status == 'Done'">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-5">
                <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                </svg>

            </div> -->
            <div class="mr-1.5 font-semibold text-2xl" @click="markToDoDone" v-if="!displayAction.resolved_date">
                ☐
            </div>
            <div @click.shift.stop="showCompanyForm"   class="w-full flex justify-between items-center">
                <div class="flex items-center">
                    <span :class="colorStyling" class="border text-base border-dark-primary whitespace-nowrap rounded-l-full rounded-r-full h-fit w-fit px-1.5 mr-1.5 text-center">{{ truncatedCompanyName }}</span> <span :class="{'bg-red-300 py-1 px-1.5' : isOverDue}" class="text-base">{{ actionOrAllAction }}</span> <span class="text-3xl ml-2.5 text-yellow-600" v-if="hasUnsavedChanges">•</span>
                </div>
                <div @click.exact="showDetails"  class="hover:cursor-pointer"><QueueListSVG /></div>
            </div>
        </div>
        <div v-else-if="displayAction && viewMode === 'list'" @mouseover="showPopover" @mouseleave="hidePopover" class="flex items-center bg-neutral-50 justify-between w-full py-0.5 px-1  border-b">
            <div :class="colorStyling" class="h-5 w-5 border border-neutral-50 mr-1 rounded">

            </div>
            <div class="mr-1.5" v-if="displayAction.resolved_date">
                <svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    class="h-5">
                    <path d="M5.75 7.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM5 10.25a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0ZM10.25 7.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM7.25 8.25a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0ZM8 9.5A.75.75 0 1 0 8 11a.75.75 0 0 0 0-1.5Z" />
                    <path fill-rule="evenodd" d="M4.75 1a.75.75 0 0 0-.75.75V3a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2V1.75a.75.75 0 0 0-1.5 0V3h-5V1.75A.75.75 0 0 0 4.75 1ZM3.5 7a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4.5a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1V7Z" clip-rule="evenodd" />
                </svg>  
            </div>
            <!-- <div class="mr-1.5" v-if="displayAction.action.resolved_type == 'action' && displayAction.status == 'Done'">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-5">
                <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                </svg>

            </div> -->
            <div class="mr-1.5 font-semibold text-lg" @click="markToDoDone" v-if="!displayAction.resolved_date">
                ☐
            </div>
            <div class="w-full flex items-center justify-between" @click.shift="showCompanyForm"  >
                <div class="flex items-center">
                    <span class="border border-dark-primary whitespace-nowrap rounded-l-full rounded-r-full h-fit w-fit px-1.5 mr-1 text-center  text-base">{{ truncatedCompanyName }}</span> <span :class="{'bg-red-300 py-1 px-1.5' : isOverDue}" class="text-base">{{ actionOrAllAction }}</span> <span class="text-3xl ml-2.5 text-yellow-600" v-if="hasUnsavedChanges">•</span>
                </div>
            </div>
            <div class="ml-2 border bg-gray-500 border-gray-500 rounded-full">
                <span class="border bg-gray-500 border-gray-500 font-semibold text-white whitespace-nowrap rounded-l-full rounded-r-full h-fit w-fit px-1.5 mr-0.5 text-center text-sm">
                    {{ clientOrStatusName }}
                </span>
            </div>
            <div @click.exact="showDetails"  class="hover:cursor-pointer ml-1"><QueueListSVG /></div>
        </div>
        <div v-else-if="viewMode === 'list'"  @mouseover="showPopover" @mouseleave="hidePopover" class="flex items-center justify-between w-full  py-0.5 px-1  border-b bg-neutral-50">
            <div :class="colorStyling" class="h-5 w-5 border border-neutral-50 mr-1 rounded">

            </div>
            <div class="mr-1.5 w-[1.35rem] font-semibold">
            </div>
            <div class="w-full flex items-center" @click.shift="showCompanyForm"  >
                <span class="border border-dark-primary whitespace-nowrap rounded-l-full rounded-r-full h-fit w-fit px-1.5 mr-0.5 text-center  text-base">{{ truncatedCompanyName }}</span> <span class="text-3xl ml-1 text-yellow-600" v-if="hasUnsavedChanges">•</span>
            </div>
            <div class="ml-2 border bg-gray-500 border-gray-500 rounded-full">
                <span class="border bg-gray-500 border-gray-500 font-semibold text-white whitespace-nowrap rounded-l-full rounded-r-full h-fit w-fit px-1.5 mr-0.5 text-center text-sm">
                    {{ clientOrStatusName }}
                </span>
            </div>
            <div @click.exact="showDetails"  class="hover:cursor-pointer ml-1"><QueueListSVG /></div>
        </div>
        <div v-else   @mouseover="showPopover" @mouseleave="hidePopover" class="flex bg-neutral-50 items-center justify-between w-full  py-0.5 px-1  border-b" @click.shift="showCompanyForm"  >
            <div class="mr-1.5 w-[1.35rem] h-1 font-semibold">
            </div>
            <div class="w-full flex items-center justify-between">
                <div class="flex items-center">
                    <span :class="colorStyling" class="border border-dark-primary whitespace-nowrap rounded-l-full rounded-r-full w-fit px-1.5 mr-1.5 text-center  text-base">{{ truncatedCompanyName }}</span> <span class="text-3xl ml-1 text-yellow-600" v-if="hasUnsavedChanges">•</span>
                </div>
                <div @click.exact="showDetails"  class="hover:cursor-pointer"><QueueListSVG /></div>
            </div>
        </div>
        <base-modal :is-form-visible="isCompanyFormVisible" @close="closeCompanyForm">
          <template #header>
            <h2 class="text-lg">{{ company.company_name }} {{ company.id }}</h2>
          </template>
          <template #body>
            <EditCompany
              :company="company"
              :campaigns="campaigns"
              @close="closeCompanyForm"
              @refetch-companies="fetchNewCompanies"
              
            />
          </template>
        </base-modal>
        <base-popover-click @close-popover="closeEditPopover" v-if="editPopoverVisible" :xPosition="xPosition" :yPosition="yPosition">
            <!-- <template #body> -->
                <EditPopover :company="company" :new-actions="newActions" :event="displayAction" @add-new-action="addNewAction" @set-highlight-color="setHighlightColor" :event-users="eventUsers" :event-types="crmActionTypes" @refetch-companies="fetchNewCompanies"/>
            <!-- </template> -->
        </base-popover-click>
        <base-popover-hover-dark
            class="max-w-fit"
            v-if="isPopoverVisible"
            :xPosition="xPosition"
            :yPosition="yPosition"
        >
            <template #title>
            <span class="text-base">Event Note:</span>
            </template>
            <template #body>
                <div class="p-1 border-gray-300 bg-neutral-50 w-fit">
                    {{ displayAction.action_notes }}
                </div>
            </template>
        </base-popover-hover-dark>
        <base-modal :is-form-visible="crmDetailsVisible" @close="closeDetails">
            <template #header>
            <h2 class="text-lg">
                {{ company.company_name }} CRM Details
            </h2>
            </template>
            <template #body>
            <CRMDetailsPage class="text-[0.8rem]" :company="company" :campaigns="campaigns" :crm-statuses="crmStatuses" :crm-action-types="crmActionTypes" @refetch-companies="fetchNewCompanies" />
            </template>
        </base-modal>
    </div>
</template>

<script setup>
import { defineProps, computed, ref, defineEmits, watch } from 'vue';
import { formatDateTwoDigitYear } from '@/composables/formatDate';
import CRMDetailsPage from '../CRMDetailsPage.vue';
import BasePopoverHoverDark from '../BasePopoverHoverDark.vue'
import EditPopover from './EditPopover.vue';
import EditCompany from '../EditCompany.vue';
import { useClickOutside, useRightClickOutside } from '@/composables/useClickOutside';
import { markHighlightColor, markStatus } from '@/services/CrmAction';
import { useStore } from 'vuex';
import QueueListSVG from '../svg-components/QueueListSVG.vue';
import { useToast } from 'vue-toast-notification';

const $toast = useToast()
const props = defineProps([
    'company',
    'campaigns',
    'crmStatuses',
    'crmActionTypes',
    'eventUsers',
    'viewMode',
    'displayActionStatus',
    'showNotesOnHover',
    'showAllOpen',
    'openDetailsId',
    'savedChanges',
    'pageFilterType',
    'companyCRMStatusesToUpdate',
    'overdueOnly'
])

const store = useStore()
const emit = defineEmits(['refetch-companies', 'set-highlight-color', 'add-new-action', 'mark-to-do-done', 'save-changes', 'close-details', 'open-company-form', 'close-company-form', 'open-quick-edit', 'close-quick-edit'])

const isPopoverVisible = ref(false)
const isMouseover = ref(false)
const editPopoverVisible = ref(false)
const highPriorityCompanyRow = ref(null)
const isCompanyFormVisible = ref(false)
const xPosition = ref(0)
const yPosition = ref(0)
const colorChanged = ref(false)
const newActions = ref([])
const actionsMarkedDone = ref([])

function closeCompanyForm(){
    isCompanyFormVisible.value = false
}

function showCompanyForm(){
    closeEditPopover()
    emit('save-changes')
    isCompanyFormVisible.value = true
}

watch(() => props.openDetailsId, () => {
    if(props.openDetailsId === props.company.id){
        crmDetailsVisible.value = true
    } else {
        crmDetailsVisible.value = false
    }
})


watch(() => props.savedChanges, ()=>{
    newActions.value = []
    colorChanged.value = false
    actionsMarkedDone.value = []
})

const hasUnsavedChanges = computed(() => {
    if(props.companyCRMStatusesToUpdate){
        return colorChanged.value || newActions.value.length > 0 || actionsMarkedDone.value.length > 0 || props.companyCRMStatusesToUpdate.find((company) => company.company_id == props.company.id)
    } else {
        return colorChanged.value || newActions.value.length > 0 || actionsMarkedDone.value.length > 0
    }
})

const clientName = computed(() => {
    return props.company.campaign.client.client_name
})

const clientOrStatusName = computed(() => {
    if(props.pageFilterType === 'status'){
        return clientName.value
    } else if(props.pageFilterType === 'client') {
        return crmStatusName.value 
    } else {
        return null
    }
})

const crmStatusName = computed(() => {
    return props.company.current_status.crm_status.status_name
})

useClickOutside(highPriorityCompanyRow, () => {
  closeEditPopover()
})

useRightClickOutside(highPriorityCompanyRow, () => {
  closeEditPopover()
})

function setHighlightColor(color){
    if(color != props.company.highlight_color){
        colorChanged.value = true
        if(props.pageFilterType === 'status'){
            emit('set-highlight-color', clientName.value, props.company.id, color)
    
        } else if(props.pageFilterType === 'client'){
            emit('set-highlight-color', crmStatusName.value, props.company.id, color)
        }
    }
}

function addNewAction(actionData){
    newActions.value.push(actionData)
    if(props.pageFilterType === 'status'){
        emit('add-new-action', clientName.value, props.company.id, actionData)
    } else if (props.pageFilterType === 'client'){
        emit('add-new-action', crmStatusName.value, props.company.id, actionData)
    }
}

async function markToDoDone(){
    if(props.pageFilterType === 'status'){
        emit('mark-to-do-done', clientName.value, props.company.id, displayAction.value.id)
    } else if (props.pageFilterType === 'client'){
        emit('mark-to-do-done', crmStatusName.value, props.company.id, displayAction.value.id)
    }
    actionsMarkedDone.value.push(displayAction.value)
}

const colorStyling = computed(() => {
    let style = []
    if(props.company){
        if(props.company.highlight_color === 'yellow'){
            style.push('bg-yellow-400')
        }
        else if(props.company.highlight_color === 'light_green'){
            style.push('bg-green-200')
        }
        else if(props.company.highlight_color === 'dark_green'){
            style.push('bg-green-600')
        } else {
            if(props.viewMode === 'cards'){
                style.push('bg-gray-100')
            } else if(props.viewMode === 'list'){
                style.push('bg-neutral-50')
            }
        }
    } else {
        style.push('bg-neutral-50')
    }
    return style.join(' ')
})

const shiftPressed = ref(false)

const truncatedCompanyName = computed(() => {
    if(props.company.company_name.length >= 30){
        return `${props.company.company_name.substring(0, 30)}...`
    } else {
        return props.company.company_name
    }
})

function registershiftPressed(e) {
    // console.log('ctrl pressed')
    if(e.key === 'Shift') {
        // e.preventDefault()
        shiftPressed.value = true
    }
}

function registerShiftReleased(e) {
    // console.log('ctrl released')
    if(e.key === 'Shift') {
        // e.preventDefault()
        shiftPressed.value = false
    }
}

function registerKeyPress(e) {
    if(shiftPressed.value && isMouseover.value){
        if(e.key === '!'){
            e.preventDefault()
            setHighlightColor('dark_green')
        }
        else if (e.key === '@') {
            e.preventDefault()
            setHighlightColor('light_green')
        }
        else if (e.key === '#') {
            e.preventDefault()
            setHighlightColor('yellow')
        } else if (e.key === ')') {
            e.preventDefault()
            setHighlightColor(null)
        }
    }
}

function handleScroll(){
    closeEditPopover()
}

function showPopover() {
    // console.log('mouse enter')
    if(props.showNotesOnHover){
        if(!editPopoverVisible.value && props.viewMode == 'cards'){
            isPopoverVisible.value = true;
            xPosition.value = highPriorityCompanyRow.value.getBoundingClientRect().left
            yPosition.value = highPriorityCompanyRow.value.getBoundingClientRect().bottom
        }
    }
    isMouseover.value = true
    document.addEventListener("keydown", registershiftPressed, false)
    document.addEventListener("keyup", registerShiftReleased, false)
    document.addEventListener("keydown", registerKeyPress, false)
}

function openQuickEdit(){
    emit('open-edit-popover', props.company.id)
}
function closeQuickEdit(){
    emit('close-edit-popover')
}

function showEditPopover() {
    hidePopover()
    if(!isCompanyFormVisible.value && !crmDetailsVisible.value){
        emit('open-quick-edit', props.company.id)
        editPopoverVisible.value = true;
        const rowBottom = highPriorityCompanyRow.value.getBoundingClientRect().bottom
        const rowLeft = highPriorityCompanyRow.value.getBoundingClientRect().left
        const windowBottom = window.innerHeight
        const windowRight = window.innerWidth
        xPosition.value = highPriorityCompanyRow.value.getBoundingClientRect().left 
        yPosition.value = highPriorityCompanyRow.value.getBoundingClientRect().bottom
        if(windowBottom - rowBottom <= 150){
            yPosition.value -= 180
        }
        if(props.viewMode == 'list'){
            xPosition.value += 10
        }
        if(windowRight - rowLeft <= 600){
            xPosition.value -= 200
        }
        document.addEventListener("wheel", handleScroll, true)
    }
}

function closeEditPopover() {
    editPopoverVisible.value = false;
    // if(editPopoverVisible.value){
    //     console.log('closing da edit popover')
    // }
    // emit('close-edit-popover')
    xPosition.value = 0
    yPosition.value = 0
    document.removeEventListener("wheel", handleScroll, true)
    emit('close-quick-edit', props.company.id)
}

function hidePopover() {
    // console.log('mouse leave')
    if(isPopoverVisible.value){
        isPopoverVisible.value = false;
        xPosition.value = 0;
        yPosition.value = 0;
    }
    isMouseover.value = false  
    document.removeEventListener("keydown", registershiftPressed, false)
    document.removeEventListener("keyup", registerShiftReleased, false)
    document.removeEventListener("keypress", registerKeyPress, false)
}

const crmDetailsVisible = ref(false)

function closeDetails(){
    // crmDetailsVisible.value = false
    emit('close-details')
}

function showDetails(){
    emit('save-changes', props.company.id)
    // crmDetailsVisible.value = true
}


const events = computed(() => {
    if(props.company.crm_actions.length > 0){
        return props.company.crm_actions.filter(action => action.campaign.id === props.company.campaign.id).sort(function(a,b) {
            return new Date(b.resolved_date) - new Date(a.resolved_date)
        }).map((crm_action) => {
            return `${crm_action.action.action_name}, ${formatDateTwoDigitYear(crm_action.resolved_date)}`
        })
    } else {
        return null
    }
})

const mostRecentEvent = computed(() => {
    if(props.company.crm_actions.length > 0){
        let doneActions = props.company.crm_actions.filter((action) => action.resolved_date)
        if(doneActions.length > 0){
            doneActions = doneActions.sort(sortByResolvedDate)
            return doneActions[0]
        } else {
            return null
        }
    } else {
        return null
    }
})
// const mostRecentEvent = computed(() => {
//     if(props.company.most_recent_event){
//         return props.company.most_recent_event
//     } else {
//         return null
//     }
// })



const nextActionDue = computed(() => {
    if(props.company.crm_actions.length > 0){
        let openActions = props.company.crm_actions.filter((action) => !action.resolved_date)
        if(openActions.length > 0){
            openActions = openActions.sort(sortByDueDate)
            return openActions[0]
        } else {
            return null
        }
    } else {
        return null
    }
})
// const nextActionDue = computed(() => {
//     if(props.company.next_open_action){
//         return props.company.next_open_action
//     } else {
//         return null
//     }
// })

const displayAction = computed(() => {
    if(props.displayActionStatus === 'open') {
        return nextActionDue.value
    } else if(props.displayActionStatus === 'done') {
        return mostRecentEvent.value
    } else {
        return null
    }
})

const displayLabel = computed(() => {
    if(displayAction.value){
        return displayAction.value.resolved_date ? displayAction.value.action.resolved_label : displayAction.value.action.unresolved_label
    } else {
        return null
    }
})

const displayDate = computed(() => {
    if(props.displayActionStatus === 'open') {
        return nextActionDue.value.due_date
    } else if(props.displayActionStatus === 'done') {
        return mostRecentEvent.value.resolved_date
    } else {
        return null
    }
})

const isOverDue = computed(() => {
    if(props.displayActionStatus === 'open') {
        if(nextActionDue.value){
            return new Date() > new Date(nextActionDue.value.due_date)
        }
    }
    return false
})

function sortByDueDate(a, b){
    if(a.due_date && b.due_date){
        return new Date(a.due_date) - new Date(b.due_date)
    } else if ((a.due_date && !b.due_date)){
        return -1
    } else if ((b.due_date && !a.due_date)){
        return 1
    } else {
        return 0
    }
}

function sortByResolvedDate(a, b){
    if(a.resolved_date && b.resolved_date){
        return new Date(b.resolved_date) - new Date(a.resolved_date)
    } else if ((a.resolved_date && !b.next_open_action)){
        return 1
    } else if ((b.resolved_date && !a.resolved_date)){
        return -1
    } else {
        return 0
    }
}

const actionOrAllAction = computed(() => {
    if(props.showAllOpen && props.displayActionStatus == 'open'){
        return props.company.crm_actions.filter(crmAction => !crmAction.resolved_date).sort(sortByDueDate).map((crmAction) => {
            return `${crmAction.action.unresolved_label} - ${formatDateTwoDigitYear(crmAction.due_date)}`
        }).join(', ')
    } else {
        if(props.viewMode == 'cards'){
            return `${displayLabel.value} - ${formatDateTwoDigitYear(displayDate.value)}`
        } else if(props.viewMode == 'list'){
            return  `${displayLabel.value} - ${displayAction.value.action_notes}, ${formatDateTwoDigitYear(displayDate.value)}`
        } else {
            return null
        }
    }
})


function fetchNewCompanies(){
    emit('refetch-companies')
}

</script>

