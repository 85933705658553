import companyMutations from './mutations.js';
import companyActions from './actions.js';
import companyGetters from './getters.js';
// import { mapMutations, mapActions, mapGetters } from 'vuex';


export default {
    namespaced: true,
    state() {
        return {
            companies: [],
            next: null,
            prev: null,
            count: null,
            isLoading: false,
            page: null,
            pageSize: null,
            showCreateCompanyForm: false,
            activeFilters: [],
            showFilterPane: true,
            showBasicFilters: true,
            showCampaignFilters: true,
            showResearchFilters: true,
            showBulkOperationsPane: true,
            showStatusSummaryPane: false,
            showCRMSummaryStages: false,
            crmSummaryStagesClient: null,
            showTableFilters: true,
            activeFiltersVisible: true,
            showMyOpenTasks: {show: false, user: null},
            refetchCompanies: false,
            visibleFilters: { 
                companyNameFilter: true,
                companyDescriptionFilter: true,
                companyWebsiteUrlFilter: true,
                companyAddressFilter: true,
                companySourcesFilter: true,
                companySourceOwnerFilter: true,
                companyCampaignFilter: true,
                companyCampaignExcludeFilter: true,
                companyTierFilter: true,
                companyClientFeedbackFilter: true,
                companyOnboardingStatusFilter: true,
                companyDNRReasonFilter: true,
                companyResearchStatusFilter: true,
                companyResearchStatusFilterTwo: true,
                companyRevenueFilter: true,
                companyHeadcountFilter: true,
                companyRevenueZiCombinedFilter: true,
                companyClientFilter: true,
                companyBusinessTypeFilter: true,
                companySectorFilter: true,
                companySubSectorFilter: true,
                companyCRMStatusFilter: true,
                companyCollectionTagsFilter: true,
                companyCollectionTagSourcesFilter: true,
                companyCRMNextActionFilter: true,
                companyDueTimeRangeFilter: true,
                companyStateFilter: true,
                companyDrivingTimeFocusFilter: true,
                companyDrivingTimeFilter: true,
                companyNAICSFilter: true,
                companyTaskUserFilter: true,
                companyShowResearchHaltedFilter: true,
                companyParentCompanyStatusFilter: true,
                companyInvestorsStatusFilter: true,
                companyProblemStatusFilter: true,
                companyAccountManagerResearchNotesFilter: true,
                showMyTasksFilter: true,
                companyIdFilter: true,
                companyCategoryFilter:true,
                companyRelevantBusinessFilter:true,
                companyReferralSourceFilter:true,
                companyReferralStatusFilter:true,
                companyProductServiceCodeFilter:true,
                companyContractPSCKeywordFilter:true,
                companyContractNAICSKeywordFilter:true,
                companyContractAwardingSubAgencyFilter:true,
                companyEveraxisPillarFilter:true,
                companyCRMActionsFilter:true,
                companyCollectionTagExcludeFilter:true,
                companyHasCRMActionsFilter:true,
                companyCapabilitiesFilter:true,
                companyHasConflictsFilter:true,
                companyParentTypeFilter:true,
                companyCollectionReviewFilter: true,
                companyPreviousCampaignFilter: true,
                companyCageCodeFilter: true,
                companyUeiSamFilter: true,
                companyIgpCategoryFilter: true,
                companyCountryFilter: true,
                companyCampaignChangeREquestorFilter: true,
            }
        };
    },
    mutations: companyMutations,
    actions: companyActions,
    getters: companyGetters
    // ...mapMutations['companyMutations'],
    // ...mapActions['companyActions'],
    // ...mapGetters['companyGetters'],
};