<template>
    <tr class="odd:bg-gray-50 even:bg-white cursor-pointer hover:bg-[#EEF2FF]" @click="toggleViewCompanyEvents" :style="rowStyle">
        <td
            class="px-2 py-1 border-r border-b border-gray-200 max-w-[280px] align-top text-left bg-inherit" 
            v-for="(field, index) in orderedCompanyFields" 
            :key="index"
            :style="getCellStyleByIndex(index)"
        >
            <div v-if="index==0" class="flex w-full justify-center items-start">
                <div class="">
                    <div v-if="visibleCompanyEvents">
                        <ChevronUpMiniSVG />
                    </div>
                    <div v-else>
                        <ChevronDownMiniSVG />
                    </div>
                </div>
            </div>
            <div v-else-if="index==1" class="flex justify-between items-center space-x-2 truncate">
                <div class="" @click.prevent="openCRMDetails">
                    <QueueListSVG class="w-4 h-4" />
                </div>
                <div class="w-full flex justify-start truncate">{{ handleFieldDisplay(field) }}</div>
            </div>
            <div v-else-if="index==3" class="flex justify-between items-center space-x-2 truncate">
                <div class="truncate">{{ handleFieldDisplay(field) }}</div>
                <div v-if="changedReportedEvent" class="w-[10px] flex h-full items-center justify-end">
                    <div class="w-2 h-2 bg-yellow-600 rounded-full "></div>
                </div>
            </div>
            <div v-else class="truncate flex justify-start items-start">
                {{ handleFieldDisplay(field) }}
            </div>
        </td>
    </tr>
    <tr v-show="visibleCompanyEvents" class="bg-gray-100 text-xs">
        <td colspan="5" v-if="companyEvents.length > 0" class="sticky z-10 left-0 bg-inherit w-full px-12 pt-3 pb-12  border border-r-2 border-gray-300">
            <div class="flex flex-col items-start space-y-2">
                <div class="px-2 pb-2 py-4 flex space-x-2 w-full cursor-pointer border-b border-gray-300" @click="setAutoComputeEvent">
                    <input 
                        type="radio" 
                        class="cursor-pointer"
                        :style="radioStyle" 
                        :value="true"
                        v-model="autoComputeEvent"
                      />
                    <div class="">Use Auto-Computed Event *</div>
                </div>
                <div class="px-2 pb-2 mb-2 flex space-x-2 w-full cursor-pointer border-b border-gray-300" @click="setReportedEventID(null)">
                    <input type="radio" class="cursor-pointer" :style="radioStyle"  :value="null" v-model="reportedEventID"/>
                    <div class="">Do Not Use Event</div>
                </div>
            </div>
            <!-- <div  v-if="autoComputeEvent" class="w-full flex text-gray-500 justify-start border-b items-center font-semibold  tracking-wider border-gray-200">
                <div class="w-[20%] py-1 pl-2">Event Override</div>
                <div class="w-[50%] py-1">Event</div>
                <div class="w-[30%] py-1">Resolved Date</div>
            </div> -->
            <div class="w-full flex text-gray-700 justify-start border-b items-center font-semibold  tracking-wider border-gray-300">
                <div class="w-full flex justify-start items-center">
                    <div class="w-[25%] py-1 px-[30px]">Event</div>
                    <div class="w-[15%] py-1">Resolved Date</div>
                    <div class="w-[20%] py-1">User</div>
                    <div class="w-[30%] py-1">Notes</div>
                </div>

            </div>
            <div class="w-full">
                <div 
                    v-for="crmEvent in companyEvents" 
                    :key="crmEvent.id" 
                    @click="setReportedEventID(crmEvent.id)"
                    class="even:bg-white cursor-pointer odd:bg-gray-50 border-b border-x border-gray-200  hover:bg-[#EEF2FF]"
                    :style="getStyleByEvent(crmEvent)"
                >
                    <div class="w-full flex h-full items-center justify-start">
                        <div class="w-full flex justify-start items-center">
                            <div class="w-[25%] py-1 flex justify-start items-center space-x-2">
                                <div class="pt-0.5 pl-2">
                                    <input 
                                    type="radio" 
                                    class="cursor-pointer" 
                                    :style="radioStyle"
                                    :id="crmEvent.id" 
                                    :value="crmEvent.id" 
                                    v-model="reportedEventID"
                                    />
                                </div>
                                <div>
                                    {{ getEventName(crmEvent) }}
                                </div>
                            </div>
                            <div class="w-[15%] py-1">{{ formatDate(crmEvent.resolved_date)  }}</div>
                            <div class="w-[20%] py-1">{{ crmEvent.user ? `${crmEvent.user.first_name} ${crmEvent.user.last_name}` : '--'  }}</div>
                            <div class="w-[30%] py-1 line-clamp-1">{{ crmEvent.action_notes }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </td>
        <td colspan="5" v-else class="sticky z-10 left-0 bg-inherit w-full p-6 pb-10  border border-r-2   border-gray-300">
            <div class="w-full flex text-gray-700 justify-startr font-semibold  tracking-wider px-14 py-1">
                No CRM Events
            </div>
        </td>  
        <td colspan="23" class="border-y border-r  border-gray-300">

        </td>
    </tr>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, watch } from 'vue';
import ChevronUpMiniSVG from '@/components/svg-components/ChevronUpMiniSVG.vue';
import ChevronDownMiniSVG from '@/components/svg-components/ChevronDownMiniSVG.vue';
import QueueListSVG from '../svg-components/QueueListSVG.vue';
import { formatDate } from '@/composables/formatDate';

const props = defineProps([
    'company', 
    'headers', 
    'expandAll',
    'toggleRowClick',
    'changedReportedEvent',
])

const emit = defineEmits([
    'set-reported-event', 
    'set-expand-all-neutral',
    'open-crm-details'
])

const visibleCompanyEvents = ref(false)
const blockVisibleEvents = ref(false)
const visibleCRMDetails = ref(false)
const autoComputeEvent = ref(props.company.auto_compute_reported_crm_event ? true : false)
const autoComputedEvent = ref(props.company.computed_crm_event ? props.company.computed_crm_event : null)
const reportedEventID = ref(props.company.auto_compute_reported_crm_event ? -1 : props.company.reported_crm_event ? props.company.reported_crm_event.id : null)


const reportedEvent = computed(() => {
    if(autoComputeEvent.value) {
        return autoComputedEvent.value
    }else if(reportedEventID.value) {
        return props.company.crm_actions.find((action) => action.id == reportedEventID.value)
    }
    return null
})

watch(() => props.toggleRowClick, () => {
    blockVisibleEvents.value = false
})

watch(() => props.expandAll, () => {
    if(props.expandAll == 'expanded') {
        visibleCompanyEvents.value = true
    } else if(props.expandAll == 'collapsed') {
        visibleCompanyEvents.value = false
    }
    emit('set-expand-all-neutral', 'neutral')   
})

watch(() => props.company, () => {
    // console.log('CRMTargetListRow: props.company changed')
    if(props.company.computed_crm_event) {
        autoComputedEvent.value = props.company.computed_crm_event
    }
    // if(props.company.default_crm_event) {
    //     reportedEventID.value = props.company.default_crm_event.id
    // }
}, {deep: true})

watch(() => props.company.auto_compute_reported_crm_event, () => {
    if(props.company.auto_compute_reported_crm_event) {
        autoComputeEvent.value = true
    }
})

watch(() => reportedEventID.value, () => {
    emit('set-reported-event', props.company, reportedEvent.value, autoComputeEvent.value)
})

const rowStyle = computed(() => {
    return visibleCompanyEvents.value ? 'background-color: #E0E7FF;' : ''  // #D1D5DB #E0E7FF
})

const orderedCompanyFields = computed(() => {
    let orderedFields = []
    props.headers.forEach(header => {
        orderedFields.push({fieldName: header.fieldName, fieldValue: props.company[header.fieldName]})
    });
    return orderedFields
})

const radioStyle = computed(() => {
    // if(autoComputeEvent.value) {
    //     return 'accent-color: #6B7280'
    // }
    return 'accent-color: #25274D'
})

const companyEvents = computed(() => {
    if(props.company) {
        // console.log('companyEvents')
        // console.log(props.company.crm_actions.filter((crmEvent) => crmEvent.campaign_id == props.company.campaign.id))
        return props.company.crm_actions.filter((crmEvent) => crmEvent.campaign_id == props.company.campaign.id)
    }
    return []
})

function toggleViewCompanyEvents() {
    if(!blockVisibleEvents.value) {
        visibleCompanyEvents.value = !visibleCompanyEvents.value
    }
}

function openCRMDetails() {
    blockVisibleEvents.value = true
    emit('open-crm-details', props.company)
}

function setReportedEventID(eventID) {
    // console.log('set Reported Event', eventID)
    reportedEventID.value = eventID
    autoComputeEvent.value = false
}

function setAutoComputeEvent() {
    // reportedEventID.value = autoComputeEvent.value.id
    reportedEventID.value = -1
    autoComputeEvent.value = true
}

function getEventName(crmEvent) {
    if(autoComputedEvent.value && crmEvent.id == autoComputedEvent.value.id) {
        return `${crmEvent.action.action_name} *`
    }
    return crmEvent.action.action_name
}

function handleFieldDisplay(field) {
    if(field.fieldValue) {
        if(field.fieldName == 'id') {
            return null;
        }
        if(field.fieldName == 'campaign') {
            return field.fieldValue.campaign_name
        }
        else if(field.fieldName == 'tier_object') {
            return field.fieldValue.tier_name
        }
        else if(field.fieldName == 'crm_actions') {
            if(field.fieldValue.length == 1) {
                return `${field.fieldValue.length} CRM Action`
            }
            return `${field.fieldValue.length} CRM Actions`
        }   
        else if(field.fieldName == 'reported_crm_event') {
            // console.log(reportedEvent.value)
            let eventString = '';
            if(reportedEvent.value) {
                if(reportedEvent.value.resolved_date) {
                    eventString += `${formatDate(reportedEvent.value.resolved_date)}: `
                }
                if(reportedEvent.value.action.action_name) {
                    eventString += `${reportedEvent.value.action.action_name}`
                }
                if(eventString.length == 0) {
                    eventString += '--'
                }
            } else {
                eventString += '--'
            }
            if(props.company.auto_compute_reported_crm_event) {
                 eventString += `*`
            }
            return eventString
            // if(field.fieldValue) {
            //     return `${formatDate(field.fieldValue.resolved_date)}: ${field.fieldValue.action.action_name}`
            // } else {
            //     return '--'
            // }
        }
        else if (field.fieldName == 'computed_crm_status') {
            return field.fieldValue.label
        }
        return field.fieldValue
    } else if(field.fieldName == 'reported_crm_event') {
        let eventString = '';
            if(reportedEvent.value) {
                if(reportedEvent.value.resolved_date) {
                    eventString += `${formatDate(reportedEvent.value.resolved_date)}: `
                }
                if(reportedEvent.value.action.action_name) {
                    eventString += `${reportedEvent.value.action.action_name}`
                }
                if(eventString.length == 0) {
                    eventString += '--'
                }
            } else {
                if(props.company.auto_compute_reported_crm_event) {
                     eventString += 'No Events*'
                } else {
                    eventString += 'Do Not Use Event'
                }
            }
            return eventString
    }
    return ''
}

function getCellStyleByIndex(index) {
    if(index == 0) {
        return `position: sticky; max-width: 46px; width: 46px; min-width: 46px; left: 0px; z-index: 10;`
    } else if(index == 1) {
     return `position: sticky; max-width: 300px; width: 300px; min-width: 300px; left: 46px; z-index: 10;`
    } else if(index == 2) {
        return `position: sticky; max-width: 150px; width: 150px; min-width: 150px; left: 346px; z-index: 10;`
    } else if (index == 3) {
      return `position: sticky; max-width: 280px; width: 280px; min-width: 280px; left: 496px; z-index: 10;`
    } else if (index == 4) {
      return `position: sticky; max-width: 200px; width: 200px; min-width: 200px; left: 776px; z-index: 10; border-right: 2px solid #D1D5DB;`
    } else {
        return 'z-index: 5'
    }
}

function getStyleByEvent(crmEvent) {
    if(autoComputeEvent.value) {
        if(reportedEvent.value && crmEvent && crmEvent.id == reportedEvent.value.id) {
            return 'background-color: #E5E7EB;' 
        } else {
            return 'background-color: #F3F4F6;' 
        }
    } else {
        if(reportedEvent.value && crmEvent && crmEvent.id == reportedEvent.value.id) {
            return 'background-color: #C7D2FE;'
        }
        return ''
    }
}

function getAutoComputeFlagByEvent(crmEvent) {
    if(crmEvent && crmEvent.id == autoComputedEvent.value.id) {
        return 'background-color: #FDE68A;'
    } else if(reportedEvent.value && crmEvent && crmEvent.id == reportedEvent.value.id) {
        return 'background-color: #A5B4FC'
    }
    return 'background-color: inherit'
}


// function isFurthestAction(crmAction) {
//     if(crmAction && props.company.computed_crm_event) {
//         if(crmAction.id == props.company.computed_crm_event.id) {
//             return true
//         } 
//     }
//     return false
// }



</script>