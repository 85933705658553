<template>
    <div
        @click="toggleViewCompanyActions"
        class="cursor-pointer flex justify-start w-full bg-white hover:bg-[#EEF2FF]"
    >
        <div class="border-b border-l border-gray-300 px-2 py-1.5 w-[15%]"
        >
            {{  companyId }}
        </div>
        <div class="border-b  border-gray-300 pr-2 py-1.5 w-[60%] whitespace-nowrap truncate"

        >
            {{  companyName }}
        </div>
        <div class="border-b border-r border-gray-300 pr-2 py-1.5 w-[25%] whitespace-nowrap truncate"

        >
            {{  computedEvent }}
        </div>
    </div>
    <div v-if="visibleCompanyActions" class="bg-gray-50 w-full px-2 py-1.5 border-x border-b border-gray-300">
        <div class="w-full flex justify-start pb-1 border-b border-gray-200">
            <div class="w-[10%]">Show</div>
            <div class="w-[60%]">Event</div>
            <div class="w-[30%]">Date</div>
        </div>
        <div v-for="crmAction in companyEvents" :key="crmAction.id" class="py-2 border-b border-gray-200">
            <div class="w-full flex justify-start">
            <div class="w-[10%]"><input type="checkbox" id="checkbox" :checked="crmAction.show_on_crm_report" @change="toggleSelected"/></div>
            <div class="w-[60%]">{{ crmAction.action.action_name }}</div>
            <div class="w-[30%]">{{ crmAction.resolved_date  }}</div>
        </div>
            
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, watch } from 'vue';

const props = defineProps(['company', 'index',])
const emit = defineEmits(['select-company'])

const visibleCompanyActions = ref(false)

const rowStyle = computed(() => {
    if(props.company.isSelected) {
        return 'background-color: #EEF2FF;'
    } else {
        return 'background-color: #FFF;'
    }
})

const companyId = computed(() => {
    return props.company ? props.company.id : null
})

const companyName = computed(() => {
    return props.company ? props.company.company_name : null
})

const computedEvent = computed(() => {
    return props.company ? props.company.computed_crm_event ? props.company.computed_crm_event.action.action_name : '--' : '--'
})

const companyEvents = computed(() => {
    if(props.company) {
        return props.company.crm_actions
    }
    return []
})


function toggleViewCompanyActions() {
    visibleCompanyActions.value = !visibleCompanyActions.value
}
</script>